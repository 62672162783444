import * as React from 'react';
import t from '../../Language/Language'
import { NFT } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
import * as api from '../../Global/API'
import img_noimage from '../../../Assets/noimage.png';
import img_loading from '../../../Assets/ipfsloading.png';
import { TxStatus } from '../_Interfaces/iSmartTx';
interface Props {
    nft: NFT
    close_callback: any;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    close_on_close_info: boolean

    // confirmation

    encoding_result: encoding_result

    send_result: SendResult;
    send_complete: boolean;

    loading: boolean
}

export class CancelSalePopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,
            close_on_close_info: false,
            encoding_result: blank_encoding_result,
            send_result: blank_send_result,
            loading: true,
            send_complete: false,
        };

        const body = JSON.stringify({
            nft_id: this.props.nft.txid,
        })

        api.GetCancelSaleTx(body, (data: any) => {
            this.setState({ encoding_result: data, loading: false });
        })
    }

    close_info() {
        this.setState({ show_info: false })
        if (this.state.close_on_close_info) {
            this.props.close_callback();
        }
    }

    validate() {
        api.send_raw(this.state.encoding_result.hex, (data: SendResult) => {
            this.setState({ send_result: data, send_complete: true })
            if (data.success == false)
                this.setState({ info_title: "error", info_body: data.message, show_info: true })
            else {
                this.setState({ info_title: "success", info_body: "Cancel Sale transaction has been sent! TXID: " + data.message, show_info: true, close_on_close_info: true })
            }
        })
    }

    render() {
        if (this.state.loading) {
            return <InfoPopup title={"Loading"} info={"Running safety checks on selected offer"} close_callback={() => { this.props.close_callback() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={this.close_info.bind(this)} />
        }

        var image;

        if (this.props.nft.status != null && this.props.nft.status.status == TxStatus.loading)
            image = img_loading;
        else if (this.props.nft.immage == null)
            image = img_noimage;
        else
            image = "https://smartchain.validitytech.com/api/public/ipfsthumb/" + this.props.nft.immage;

        var rarity: string = 'normal';

        if (this.props.nft.custom_fields != null) {
            this.props.nft.custom_fields.map(custom => {
                if (custom.key == "rarity") {
                    switch (custom.value) {
                        case "rare":

                            rarity = 'rare';

                            break;
                        case "uncommon":

                            rarity = 'uncommon';

                            break;
                        default:
                    }
                }
            })
        }

        return (<Modal size="lg" backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >

                <h2>Cancel NFT Sale</h2>

            </ModalHeader>
            <ModalBody>

                <div className='row  text-center'>

                    <div className=' p-1  col-md-5'>

                        <img src={image} alt={""} height="100%" width="100%" className=" nfttilecontent img-responsive img-thumbnail" />
                        <div className='row  text-center'>
                            <div className='col-sm-7 text-left'>
                                <div className=' text-ellipsis' ><strong>{this.props.nft.collection.collection_name}</strong></div>
                            </div>
                            <div className='col-sm-5'>
                                <div className='text-right' id="rarityColor" >{rarity}</div>
                            </div>
                        </div>
                        <div className='row  text-center'>
                            <div className='col-md-12 text-left'>
                                <div className=' text-ellipsis' ><em>{this.props.nft.name}</em></div>
                            </div>
                        </div>

                    </div>
                    <div className=' p-1  col-md-7'>

                    </div>
                </div>

                <p />

                <h5>You are about to cancel this offer to tell "{this.props.nft.name}"</h5>
                <h5> You can always list it for sale again at any time!  </h5>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Go Back</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" disabled={this.state.send_complete} onClick={this.validate.bind(this)}>Cancel Offer</button>
                </div>
            </ModalFooter>

        </Modal>
        )
    }
}