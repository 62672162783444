import * as React from 'react';
import ReactTable from 'react-table';

import { CreateNFTPopup } from './CreateNFTPopup'
import { UnixToDate } from '../../Global/UnixToDate'
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { blank_NFT, NFT } from '../_Interfaces/NFT'
import { NFTInfoPopup } from './NFTInfoPopup'
import { NFTTile } from './nftTile'
import * as Settings from '../../Global/settings'
import * as api from '../../Global/API'
import * as statics from '../../Global/statics'
import * as settings from '../../Global/settings'
import { NavLink } from 'react-router-dom';
import { match } from 'assert/strict';
interface State {
    NFTs: NFT[];
    rangestart: number;
    loading: boolean;
    SelectedNFTTxid: string;
    ShowNFT: boolean;

    verified: string;
    name: string;
    creator: string;
    id: string;
    CollectionId: string;
    intervaltick: any;
    sc_top: string;
    show_all: boolean;
}
export interface Props {
    defaultPageSize: number;
    showPagination: boolean;
    title: string;
    mobile: boolean;
    address?: string;
}

export class NFTTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            NFTs: [], rangestart: 0, loading: true, ShowNFT: false,
            SelectedNFTTxid: "", verified: "", name: "", creator: "",
            id: "", CollectionId: "", intervaltick: 5000, sc_top: "",
            show_all: false,
        };

        this.load_data()
    }

    load_data() {
        const body = JSON.stringify({
            owner: this.props.address,
        })

        api.FilterNft(body, (data: any) => { this.setState({ NFTs: data, loading: false }); })
    }
    tick() {
        if (settings.top_block.sc_top != this.state.sc_top) {
            this.load_data()
            this.setState({ sc_top: settings.top_block.sc_top })
        }
    }

    componentDidMount() {
        var inttick = setInterval(() => this.tick(), 5000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervaltick: inttick });
    }
    componentWillUnmount() {
        clearInterval(this.state.intervaltick);
    }

    public render() {
        let contents = this.state.loading
            ? <p><em>{settings.language.Loading}</em></p>
            : this.RenderAssetTable();

        return contents
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value } as any, () => {
            this.filter()
        });
    }
    handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value } as any, () => {
            //this.filter()
        });
    }

    filter() {
        var owner: string = this.state.show_all ? "" : Settings.current_identity.address;
        const body = JSON.stringify({
            name: this.state.name,
            creator: this.state.creator,
            id: this.state.id,
            groupid: this.state.CollectionId,
            owner: owner,
        })
        api.FilterNft(body, (data: any) => { this.setState({ NFTs: data, loading: false }); })
    }
    //need to get the filter values into an interface to post

    public RenderAssetTable() {
        // program to split array into smaller chunks

        let tempArray = [];

        for (var i: number = 0; i < this.state.NFTs.length; i += 8) {
            console.log(i);
            tempArray.push(this.state.NFTs.slice(i, i + 8));
        }
        var count = tempArray.length - 1;
        console.log(count);

        if (count > 0) {
            while (tempArray[count].length != 8) {
                tempArray[count].push("");
            }
        }

        const data = tempArray;
        const columns = [
            {
                Cell: (row: any) => (

                    < NFTTile nft={row.original[0]} />

                )
            },
            {
                Cell: (row: any) => (

                    < NFTTile nft={row.original[1]} />
                )
            },
            {
                Cell: (row: any) => (
                    < NFTTile nft={row.original[2]} />
                )
            },
            {
                Cell: (row: any) => (
                    < NFTTile nft={row.original[3]} />
                )
            },
            {
                Cell: (row: any) => (
                    < NFTTile nft={row.original[4]} />
                )
            },
            {
                Cell: (row: any) => (
                    < NFTTile nft={row.original[5]} />
                )
            },
            {
                Cell: (row: any) => (
                    < NFTTile nft={row.original[6]} />
                )
            },
            {
                Cell: (row: any) => (
                    < NFTTile nft={row.original[7]} />
                )
            }
        ]

        return <div className="main-page-body table table-responsive">
            <div className="body-header">
                <h1>{this.props.title}</h1>
            </div>
            <div className="body-content">

                {this.props.mobile ?

                    <div className="row">
                        <div className="form-group col-sm-6">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Name</span>
                                <input type="text" className="form-control" placeholder="Username" aria-describedby="basic-addon1" name="name" value={this.state.name} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Creator</span>
                                <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" name="Creator" value={this.state.creator} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>

                    </div>

                    :
                    <div className="row">
                        <div className="form-group col-sm-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Name</span>
                                <input type="text" className="form-control" placeholder="Name" aria-describedby="basic-addon1" name="name" value={this.state.name} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Creator</span>
                                <input type="text" className="form-control" placeholder="Creator" aria-describedby="basic-addon1" name="creator" value={this.state.creator} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">ID</span>
                                <input type="text" className="form-control" placeholder="ID" aria-describedby="basic-addon1" name="id" value={this.state.id} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Collection ID</span>
                                <input type="text" className="form-control" placeholder="CollectionID" aria-describedby="basic-addon1" name="groupid" value={this.state.CollectionId} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                    </div>
                }

                {this.props.mobile ?
                    < ReactTable
                        data={data} columns={columns} showPagination={true} minRows={10} loading={this.state.loading}

                    />
                    :
                    < ReactTable
                        data={data} columns={columns} showPagination={true} minRows={10} loading={this.state.loading}

                    />
                }

            </div>

        </div>
    }
}