import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Topright } from '../../Global/toprightmenue/toprightmenue'
import { DashboardMenue } from './DashboardMenu';
import * as Settings from '../../Global/settings'
import img_NFT from '../../../Assets/NFT.jpeg';
import img_vote from '../../../Assets/voting.jpg';
import img_sign from '../../../Assets/signature.jpg';
import img_collection from '../../../Assets/collection.jpg';
import img_profile from '../../../Assets/profile.png';
import img_wallet from '../../../Assets/wallet.jpg';
import img_IPFS from '../../../Assets/IPFS.png';
import { CreateIpfsObjectPopup } from '../IPFS/CreateIpfsObjectPopup'
interface LoadingState {
    mobile: boolean;
    show_create_ipfs: boolean;
}

export interface LoadingProps {
}
export class Dashboard extends React.Component<LoadingProps, LoadingState> {
    constructor(props: LoadingProps) {
        super(props);
        this.state = {
            mobile: false,
            show_create_ipfs: false,
        };
        //used to bypas loading screen for gui dev
        //this.props.callback(true)
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    close_create_ipfs() {
        this.setState({ show_create_ipfs: false })
    }

    public render() {
        return (<span>
            <DashboardMenue />
            <div className='main-page-body'>
                <p />
                <div className=' text-center'><h1>I want to use:</h1></div>

                <br />
                <div className='row  text-center'>

                    <div className=' p-5  col-md-3'>
                        <div className=' text-center'><h3>Signed Files</h3></div>
                        <div className='tile'>

                            <NavLink className='tilecontent button btn btn-success' to={'/signedfiles'} style={{ backgroundImage: `url(${img_sign})`, backgroundSize: '100% 100%' }}>

                            </NavLink>
                        </div>
                        <br />
                        <div className=' text-center'><h5>Blockchain Secured digital file signatures</h5></div>
                    </div>
                    <div className=' p-5  col-md-3'>
                        <div className=' text-center'><h3>Elections</h3></div>
                        <div className='tile'>
                            <div className='tilecontent button btn btn-success'>
                                <NavLink className='tilecontent button btn btn-success' to={'/elections'} style={{ backgroundImage: `url(${img_vote})`, backgroundSize: '100% 100%' }}>

                                </NavLink>

                            </div>
                        </div>
                        <br />
                        <div className=' text-center'><h5>Decentralized Elections and Voting</h5></div>
                    </div>
                    <div className=' p-5  col-md-3'>
                        <div className=' text-center'><h3>My NFT's</h3></div>
                        <div className='tile'>
                            <div className='tilecontent button btn btn-success'  >

                                <NavLink className='tilecontent button btn btn-success' to={'/nft'} style={{ backgroundImage: `url(${img_NFT})`, backgroundSize: '100% 100%' }}>

                                </NavLink>
                            </div>
                        </div>
                        <br />
                        <div className=' text-center'><h5>Explore, Create, and Trade NFT's</h5></div>
                    </div>
                    <div className=' p-5  col-md-3'>
                        <div className=' text-center'><h3>My NFT Collections</h3></div>
                        <div className='tile'>

                            <NavLink className='tilecontent button btn btn-success' to={'/nftcollections'} style={{ backgroundImage: `url(${img_collection})`, backgroundSize: '100% 100%' }}>

                            </NavLink>
                        </div>
                        <br />
                        <div className=' text-center'><h5>Create and manage NFT Collections</h5></div>
                    </div>

                </div>

                <div className='row  text-center'>

                    <div className=' p-5  col-md-3'>
                        <div className=' text-center'><h3>My Profile</h3></div>
                        <div className='tile'>
                            <div className='tilecontent button btn btn-success'>
                                <NavLink className='tilecontent button btn btn-success' to={'/myprofile'} style={{ backgroundImage: `url(${img_profile})`, backgroundSize: '100% 100%' }}>

                                </NavLink>

                            </div>
                        </div>
                        <br />
                        <div className=' text-center'><h5>View and edit your profile</h5></div>
                    </div>
                    <div className=' p-5  col-md-3'>
                        <div className=' text-center'><h3>Wallet</h3></div>
                        <div className='tile'>
                            <div className='tilecontent button btn btn-success'  >

                                <NavLink className='tilecontent button btn btn-success' to={'/wallet'} style={{ backgroundImage: `url(${img_wallet})`, backgroundSize: '100% 100%' }}>

                                </NavLink>
                            </div>
                        </div>
                        <br />
                        <div className=' text-center'><h5>Deposit and withdraw VAL</h5></div>
                    </div>
                    <div className=' p-5  col-md-3'>
                        <div className=' text-center'><h3>Create</h3></div>
                        <div className='tile'>
                            <div className='tilecontent button btn btn-success'  >

                                <div className='tilecontent button btn btn-success' onClick={() => { this.setState({ show_create_ipfs: true }) }} style={{ backgroundImage: `url(${img_IPFS})`, backgroundSize: '100% 100%' }}>

                                </div>
                            </div>
                        </div>
                        <br />
                        <div className=' text-center'><h5>Create IPFS linked smart objects</h5></div>
                    </div>
                    <div className=' col-md-2' />

                </div>

                <br />

            </div>

            <div className="main-page-foot" >

            </div>
            {this.state.show_create_ipfs ? <CreateIpfsObjectPopup address={Settings.current_identity.address} close_callback={this.close_create_ipfs.bind(this)} /> : null}

        </span>
        );
    }
}

interface setup_data {
    message: string;
    progress: number;
}