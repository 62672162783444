import * as React from 'react';
import { ElectionTable } from './ElectionsTable';
import * as Settings from '../../Global/settings'
import { CreateElectionPopup } from './CreateElectionPopup'
import { ElectionsMenu } from './ElectionMenu';
interface UsersState {
    loading: boolean;

    ShowSignFile: boolean;
    show_create: boolean;
    SmartChain_Synced: boolean;
    mobile: boolean;
}

export class Elections extends React.Component<undefined, UsersState> {
    constructor(props: undefined) {
        super(props);
        this.state = { loading: false, ShowSignFile: false, show_create: false, SmartChain_Synced: false, mobile: false };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }
    ShowCreateElection() {
        this.setState({ show_create: true })
    }
    CloseCreateElection() {
        this.setState({ show_create: false })
    }

    synced_callback() {
        this.setState({ SmartChain_Synced: true })
    }

    get_content() {
        return <span>
            <ElectionsMenu />

            <ElectionTable mobile={this.state.mobile} title={"Elections"} address={Settings.current_identity.address} defaultPageSize={-1} showPagination={true} />

        </span>
    }

    public render() {
        let content = this.get_content()
        return content;
    }
}