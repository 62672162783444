import * as React from 'react';

export interface utxo {
    txid: string;
    index: number;
    value: number;
    full_id: string;
}

export const blank_utxo: utxo = {
    txid: "",
    index: -1,
    value: -1,
    full_id: ""
}

export interface get_utxo_result {
    success: boolean;
    message: string;
    data: utxo;
    pending: number;
}

export const blank_utxo_result: get_utxo_result = {
    success: false,
    message: "",
    data: blank_utxo,
    pending: 0,
}