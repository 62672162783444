import * as React from 'react';
import ReactTable from 'react-table';
import { Offer, blank_offer } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as settings from "../../Global/settings"
import { CancelOfferPopup } from "./CancelOfferPopup"

import { utxo, blank_utxo, get_utxo_result, blank_utxo_result } from '../_Interfaces/iUTXO'

import { encoding_result, blank_encoding_result } from '../_Interfaces/iResult'
import * as api from '../../Global/API'
interface Props {
    close_callback: any;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    offers: Offer[]

    show_cancel_offer: boolean
    offer_to_cancel: Offer

    loading: boolean

    // stage 2 - confirm info
}

export class MyOffersPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            offers: [],
            show_cancel_offer: false,
            offer_to_cancel: blank_offer,
            loading: true,
        };

        this.load_data();
    }

    load_data() {
        const body = JSON.stringify({
            buyer: settings.current_identity.address,
        })

        api.GetFilteredOffers(body, (data: Offer[]) => {
            var pending: boolean = false;
            data.forEach((item, index) => {
                // if there is a pending offer set pending to true!
                if (item.pending_cancel) {
                    pending = true;
                    // we know there is a pending, no need to continue the loop
                    return;
                }
            })

            if (!pending) {
                this.time_loop_count++;
                if (this.time_loop_count > 10) {
                    clearInterval(this.timerid);
                    this.timerid = 0;
                    this.time_loop_count = 0
                }
            }

            if (data.length == 0) {
                clearInterval(this.timerid);
                this.timerid = 0;
            }

            this.setState({ offers: data, loading: false });
        })
    }

    time_loop_count: number = 0;
    timerid: any = 0;

    close() {
        this.props.close_callback(true)
    }

    show_cancel_offer(offer: Offer) {
        this.setState({ show_cancel_offer: true, offer_to_cancel: offer })
        this.load_data();
    }

    close_cancel_offer() {
        this.setState({ show_cancel_offer: false })

        // we anticipate a pending cancel
        this.time_loop_count = 0;
        //start the load data timer.
        if (this.timerid == 0) {
            this.timerid = setInterval(this.load_data.bind(this), 1000);
        }
    }

    public render() {
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        const data = this.state.offers;
        const columns = [
            {
                Header: "NFT",
                accessor: 'NFT.name',
            },
            {
                Header: "Buyer",
                accessor: 'Buyer.username',
            },
            {
                Header: "Amount",
                accessor: 'amount'
            },

            {
                Cell: (row: any) => (

                    <button type="button" className="btn  btn-success mr-3 " onClick={() => { this.show_cancel_offer(row.original) }}>Cancel Offer</button>
                )
            },
            {
                accessor: "pending_cancel",
                Cell: (row: any) => (
                    <span>
                        {row.value == true ? "Canceling" : <button type="button" className="btn  btn-success mr-3 " onClick={() => { this.show_cancel_offer(row.original) }}>Cancel Offer</button>}
                    </span>

                )
            }

        ]

        return <Modal size="lg" backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                <h1>Your Current Offers to Buy</h1>
            </ModalHeader>
            <ModalBody>
                < ReactTable
                    data={data}
                    columns={columns}
                    className="-highlight"
                    defaultPageSize={5} minRows={5}
                    defaultSorted={[{ id: "unix_time", desc: true }]}
                />
                {this.state.show_cancel_offer ?
                    <CancelOfferPopup offer={this.state.offer_to_cancel} close_callback={this.close_cancel_offer.bind(this)} />
                    : null
                }

            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    }
}