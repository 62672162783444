import * as React from 'react';
import { Redirect } from 'react-router';
interface TrueFalseIconState {
}
export interface Props {
}

export class RedirectExplore extends React.Component<Props, TrueFalseIconState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        return <Redirect to='/explore/users' />
    }
}