import * as React from 'react';
import { BuildTransactionComponent } from './BuildTxComponent'

interface SendState {
    popup: any
    show_popup: boolean
}
interface Props {
}

export class Tools extends React.Component<{}, SendState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            popup: "",
            show_popup: false,
        };
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value } as any);
    }

    public render() {
        if (this.state.show_popup) {
            return this.state.popup
        }

        return <span>
            <div className="main-page-head">
                <h1>Tools</h1>
            </div>

            <div className='main-page-body'>
                <div className='row'>
                    <div className=' col-md-4'>
                        <button type="button" className="btn btn-success mr-3" onClick={() => { this.setState({ popup: <BuildTransactionComponent close_callback={() => { this.setState({ show_popup: false }) }} />, show_popup: true }) }} >Build Transaction</button>
                    </div>
                    <div className=' col-md-4'>
                        <button type="button" className="btn btn-success mr-3"> unused </button>
                    </div>
                    <div className=' col-md-4'>
                        <button type="button" className="btn btn-success mr-3"> unused</button>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className=' col-md-4'>
                        <button type="button" className="btn btn-success mr-3"> unused</button>
                    </div>
                    <div className=' col-md-4'>
                        <button type="button" className="btn btn-success mr-3"> unused</button>
                    </div>
                    <div className=' col-md-4'>
                        <button type="button" className="btn btn-success mr-3"> unused</button>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-md-4'>
                        <button type="button" className="btn btn-success mr-3"> unused</button>
                    </div>
                    <div className='col-md-4'>
                        <button type="button" className="btn btn-success mr-3"> unused</button>
                    </div>
                    <div className='col-md-4'>
                        <button type="button" className="btn btn-success mr-3"> unused</button>
                    </div>
                </div>
            </div>

            <div className="main-page-foot" >

            </div>
        </span>
    }
}