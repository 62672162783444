import * as React from 'react';
import { NFTTable } from './NFTTable';
import * as Settings from '../../Global/settings'
import { NFTMenu } from './NFTMenu';
interface NFTState {
    mobile: boolean;
}

export interface LoadingProps {
}
export class NFT extends React.Component<LoadingProps, NFTState> {
    constructor(props: LoadingProps) {
        super(props);
        this.state = {
            mobile: false,
        };
        //used to bypas loading screen for gui dev
        //this.props.callback(true)
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    public render() {
        return (<span>
            <NFTMenu />

            <NFTTable mobile={this.state.mobile} title={"Your NFT's"} address={Settings.current_identity.address} defaultPageSize={-1} showPagination={true} />

        </span>
        );
    }
}

interface setup_data {
    message: string;
    progress: number;
}