import * as React from 'react';
import { Progress } from 'reactstrap';
import * as api from '../../Global/API'
import logo from '../../../Assets/ValLogo305x300.png';
interface LoadingState {
    intervaltick: any;

    primary_text: string;
    secondary_text: string;
    loading_bar_text: string;
    loading_bar_pos: number;
    loading_bar_vis: boolean;
    data: setup_data;
    wallet_connected: boolean;
    interval: number;

    setsync: number;

    //control
    setup_complete: boolean;
}

export interface LoadingProps {
    callback: any;
}
export class Loading extends React.Component<LoadingProps, LoadingState> {
    constructor(props: LoadingProps) {
        super(props);
        this.state = {
            intervaltick: [], primary_text: " ", secondary_text: " ", loading_bar_text: "", loading_bar_vis: false, loading_bar_pos: 0, data: {
                message: "Awaiting API connection",
                progress: 0,
            },
            wallet_connected: false,
            interval: 2000,
            setsync: 0,

            setup_complete: false,
        };
        //used to bypas loading screen for gui dev
        //this.props.callback(true)
        this.tick();
    }

    tick() {
        api.GetSetupInfo((value: setup_data) => {
            this.setState({ data: value });
        })

        if (this.state.data.message === "SmartChain Ready") {
            this.props.callback(true);
            clearInterval(this.state.intervaltick);
        }
    }

    set_interval(int: number) {
        this.setState({ interval: int })
    }

    componentDidMount() {
        var inttick = setInterval(() => this.tick(), 1000);

        // store intervalId in the state so it can be accessed later:
        this.setState({ intervaltick: inttick });
    }
    componentWillUnmount() {
        clearInterval(this.state.intervaltick);
    }

    public render() {
        return <div className="loading-page" >

            <div className="loading-page-head">
            </div>
            <div className="loading-page-body container" >
                <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <div className="row ">
                            <img src={logo} alt="error" className="img-fluid mx-auto " />
                        </div>
                        <div className="row ">
                            <h1 className="mx-auto">Validity Web Client</h1>

                        </div>
                        <div className="row ">
                            <h3 className="mx-auto">{this.state.data.message}</h3>
                            <div className="text-center progress">
                                <Progress color="success" max="100" value={ this.state.data.progress}><div>{this.state.data.progress + "%"}</div></Progress>
                            </div>

                        </div>

                    </div>

                    <div className="col-sm-3" />
                </div>
            </div>
            <div className="loading-page-foot">
                {/* <button type="button" className="btn btn-success mr-3" onClick={() => {this.props.callback(true)}}>skip</button>*/}
            </div>

        </div>;
    }
}

interface setup_data {
    message: string;
    progress: number;
}