import * as React from 'react';
import ReactTable from 'react-table';

import { NFT, blank_NFT, blank_NFTCollection, NFTCollection } from '../_Interfaces/NFT'
import { NFTCollectionInfoPopup } from './NFTCollectionInfoPopup'
import * as Settings from '../../Global/settings'
import { UserInfoPopup } from '../Users/UserInfoPopup'
import { CreateNFTinCollectionPopup } from "./CreateNFTinCollectionPopup"
import * as statics from '../../Global/statics'
import * as api from '../../Global/API'

import * as settings from '../../Global/settings'
import { NFTInfoPopup } from '../NFT/NFTInfoPopup'
interface State {
    collections: NFTCollection[];
    collection: NFTCollection;
    NFTs: NFT[];
    rangestart: number;
    loading: boolean;
    SelectedNFTCollection: NFTCollection;
    SelectedNFT: NFT;
    ShowNFTCollection: boolean;
    ShowNFT: boolean;
    verified: string;
    name: string;
    creator: string;
    id: string;
    description: string;
    intervaltick: any;
    sc_top: string;
    show_all: boolean;
    selected_collection: string;

    show_user: boolean;
    selected_user: string;

    show_create_NFT: boolean;
}
export interface Props {
    defaultPageSize: number;
    showPagination: boolean;
    title: string;
    mobile: boolean;
    address?: string;
}

export class NFTClassTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            collections: [],
            collection: blank_NFTCollection,
            NFTs: [],
            rangestart: 0,
            loading: true,
            ShowNFTCollection: false,
            ShowNFT: false,
            SelectedNFTCollection: blank_NFTCollection,
            SelectedNFT: blank_NFT,
            verified: "", name: "", creator: "",
            id: "", description: "", intervaltick: 5000, sc_top: "",
            show_all: false, selected_collection: "",
            show_user: false,
            selected_user: "",
            show_create_NFT: false,
        };

        this.load_data()
    }

    load_data() {
        const body = JSON.stringify({
            owner: Settings.current_identity.address,
        })

        api.FilterNftcollection(body, (data: any) => { this.setState({ collections: data, loading: false }); })
    }
    tick() {
        if (settings.top_block.sc_top != this.state.sc_top) {
            this.load_data()
            this.setState({ sc_top: settings.top_block.sc_top })
        }
    }

    componentDidMount() {
        var inttick = setInterval(() => this.tick(), 5000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervaltick: inttick });
    }
    componentWillUnmount() {
        clearInterval(this.state.intervaltick);
    }

    OpenViewCollection(asset: NFTCollection) {
        this.setState({ SelectedNFTCollection: asset, ShowNFTCollection: true })
    }

    OpenViewNFT(nft: NFT) {
        this.setState({ SelectedNFT: nft, ShowNFT: true })
    }

    CloseViewUser() {
        this.setState({ show_user: false })
    }

    OpenViewUser(useraddress: string) {
        this.setState({ selected_user: useraddress, show_user: true })
    }

    CloseCreateNFT() {
        this.setState({ show_create_NFT: false })
    }

    OpenCreateNFT() {
        this.setState({ show_create_NFT: true })
    }

    ShowSingleCollection(txid: string) {
        const body = JSON.stringify({ groupid: txid })
        api.FilterNft(body, (assets: any) => {
            api.GetNftcollection(txid, (class1: any) => {
                this.setState({ collection: class1, NFTs: assets, selected_collection: txid })
            })
        })
    }

    HideSingleCollection() {
        this.setState({ selected_collection: "" })
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value } as any, () => {
            this.filter()
        });
    }
    handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value } as any, () => {
            //this.filter()
        });
    }

    filter() {
        if (this.state.show_all) {
            const body = JSON.stringify({
                name: this.state.name,
                creator: this.state.creator,
                id: this.state.id,
                description: this.state.description,
                owner: "",
            })

            api.FilterNftcollection(body, (data: any) => { this.setState({ collections: data, loading: false }); })
        }
        else {
            const body = JSON.stringify({
                name: this.state.name,
                creator: this.state.creator,
                id: this.state.id,
                description: this.state.description,
                owner: Settings.current_identity.address,
            })
            api.FilterNftcollection(body, (data: any) => { this.setState({ collections: data, loading: false }); })
        }
    }
    //need to get the filter values into an interface to post

    public RenderAllTable() {
        const data = this.state.collections;
        const columns = [
            {
                Header: "Name",
                accessor: 'collection_name',
                className: "sticky",
                headerClassName: "sticky"
            },
            {
                Header: settings.language.Description,
                accessor: 'cpllection_description'
            },
            {
                Header: "owner",
                accessor: 'owner.username'
            },
            {
                Header: "creator",
                accessor: 'creator.username'
            },
            {
                Header: "id",
                accessor: 'txid'
            },

        ]

        return <div className="main-page-body table table-responsive">
            <div className="body-header">
                <h1>{this.props.title}</h1>
            </div>
            <div className="body-content">

                {this.props.mobile ?

                    <div className="row">
                        <div className="form-group col-xs-6">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Name</span>
                                <input type="text" className="form-control" placeholder="Username" aria-describedby="basic-addon1" name="name" value={this.state.name} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-xs-6">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Creator</span>
                                <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" name="description" value={this.state.description} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>

                    </div>

                    :
                    <div className="row ">
                        <div className="form-group col-sm-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Name</span>
                                <input type="text" className="form-control" placeholder="Name" aria-describedby="basic-addon1" name="name" value={this.state.name} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Creator</span>
                                <input type="text" className="form-control" placeholder="Creator" aria-describedby="basic-addon1" name="creator" value={this.state.description} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">ID</span>
                                <input type="text" className="form-control" placeholder="ID" aria-describedby="basic-addon1" name="id" value={this.state.id} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-3">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Description</span>
                                <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" name="description" value={this.state.description} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                    </div>
                }

                {this.props.mobile ?
                    < ReactTable
                        data={data} columns={columns} showPagination={true} defaultPageSize={40} minRows={10} className="-highlight" loading={this.state.loading}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: () => {
                                    console.log("A Td Element was clicked!");
                                    this.ShowSingleCollection(rowInfo.row.txid)
                                }
                            };
                        }}

                    />
                    :
                    < ReactTable
                        data={data} columns={columns} showPagination={true} defaultPageSize={40} minRows={10} className="-highlight" loading={this.state.loading}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onDoubleClick: () => {
                                    console.log("A Td Element was clicked!");
                                    this.ShowSingleCollection(rowInfo.row.txid)
                                }
                            };
                        }}

                    />
                }

            </div>
            {this.state.ShowNFTCollection ?
                < NFTCollectionInfoPopup txid={this.state.SelectedNFTCollection.txid} close_callback={() => { this.setState({ ShowNFTCollection: false }) }} />
                : null
            }
        </div>
    }

    public RenderGroupTable(txid: string) {
        var data = this.state.NFTs;

        const columns = [
            {
                Header: "Immage",
                accessor: 'immage',
                Cell: (row: any) => (
                    <img src={"https://smartchain.validitytech.com/api/public/ipfsfull/" + row.original.immage} onError={(e) => console.log(e.nativeEvent)} alt={""} className="img-responsive img-thumbnail" />

                )
            },
            {
                Header: "Name",
                accessor: 'name'
            },
            {
                Header: "owner",
                accessor: 'owner.username'
            },
            {
                Header: "creator",
                accessor: 'creator.username'
            },
            {
                Header: "id",
                accessor: 'txid'
            },

        ]

        return <div className="outside-table-div">
            <div className=" center-block text-center">
                <h3>All NFTs in the "{this.state.collection.collection_name}" Collection, created by {this.state.collection.owner.username}</h3>

                <br />
            </div>

            <div className="row">
                <div className="form-group col-sm-4">
                    <button type="button" className="btn btn-success mr-3 " onClick={this.HideSingleCollection.bind(this)} >Back</button>
                </div>
                <div className="form-group col-sm-4">
                    <button type="button" className="btn btn-success mr-3 " onClick={() => { this.OpenViewCollection(this.state.collection) }} >View Group Details</button>

                </div>
                <div className="form-group col-sm-4">
                    {Settings.current_identity.address == this.state.collection.owner.address ? <button type="button" className="btn btn-success mr-3 " onClick={() => { this.OpenCreateNFT() }} >Create NFT in this group</button>
                        : null}
                </div>

            </div>

            <div className="inside-table-div table table-responsive">

                {this.props.mobile ?
                    < ReactTable
                        data={data} columns={columns} showPagination={false} defaultPageSize={40} minRows={10} className="-highlight" loading={this.state.loading}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: () => {
                                    console.log("A Td Element was clicked!");
                                    this.OpenViewNFT(rowInfo.row)
                                }
                            };
                        }}

                    />
                    :
                    < ReactTable
                        data={data} columns={columns} showPagination={false} defaultPageSize={40} minRows={10} className="-highlight" loading={this.state.loading}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onDoubleClick: () => {
                                    console.log("A Td Element was clicked!");
                                    this.OpenViewNFT(rowInfo.row)
                                }
                            };
                        }}

                    />
                }

            </div>
            {this.state.show_create_NFT ?
                <CreateNFTinCollectionPopup collection={this.state.collection} close_callback={this.CloseCreateNFT.bind(this)} />
                : null
            }
            {this.state.ShowNFTCollection ?
                < NFTCollectionInfoPopup txid={this.state.SelectedNFTCollection.txid} close_callback={() => { this.setState({ ShowNFTCollection: false }) }} />
                : null
            }
            {this.state.ShowNFT ?
                < NFTInfoPopup nfttxid={this.state.SelectedNFT.txid} close_callback={() => { this.setState({ ShowNFT: false }) }} />
                : null
            }
        </div>
    }

    public render() {
        let contents = <p><em>{settings.language.Loading}</em></p>
        if (this.state.selected_collection != "")
            contents = this.RenderGroupTable(this.state.selected_collection);
        else
            contents = this.RenderAllTable();

        return contents
    }
}