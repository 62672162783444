import * as React from 'react';
import { NFT } from '../_Interfaces/NFT'
import img_noimage from '../../../Assets/noimage.png';
import img_loading from '../../../Assets/ipfsloading.png';
import { TxStatus } from '../_Interfaces/iSmartTx';
import { OfferNFTPopup } from '../../MainPages/Marketplace/OfferNFTPopup'
import * as Settings from '../../Global/settings'
import { ReviewOffersPopup } from './ReviewOffersPopup';
interface State {
    make_offer: boolean
    review_offer: boolean
}
export interface Props {
    nft: NFT
    open_view_asset_callback: any
}

export class MarketTile extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            make_offer: false,
            review_offer: false,
        };
    }

    show_make_offer() {
        this.setState({ make_offer: true })
    }

    hide_make_offer() {
        this.setState({ make_offer: false })
    }

    show_review_offer() {
        this.setState({ review_offer: true })
    }

    hide_review_offer() {
        this.setState({ review_offer: false })
    }

    public render() {
        var image;

        if (this.props.nft.status != null && this.props.nft.status.status == TxStatus.loading)
            image = img_loading;
        else if (this.props.nft.immage == null)
            image = img_noimage;
        else
            image = "https://smartchain.validitytech.com/api/public/ipfsthumb/" + this.props.nft.immage;

        let collection_name: string = ' p-2 tileborder  col-md-12 NFTTile';

        var rarity: string = 'normal';

        if (this.props.nft.custom_fields != null) {
            this.props.nft.custom_fields.map(custom => {
                if (custom.key == "rarity") {
                    switch (custom.value) {
                        case "rare":
                            collection_name += ' tileborderrare';
                            rarity = 'rare';

                            break;
                        case "uncommon":
                            collection_name += ' tileborderuncommon';
                            rarity = 'uncommon';

                            break;
                        default:
                            collection_name += ' tileborder';
                    }
                }
            })
        }

        var top: number = 0;

        if (this.props.nft.pending_offers != undefined) {
            this.props.nft.pending_offers.map(pending => {
                if (pending.amount > top)
                    top = pending.amount;
            })
        }

        return (
            this.props.nft.txid != null ?
                <div className={collection_name} >

                    <div className='text-center ' style={{ marginTop: 'auto' }}>

                        <img src={image} alt={""} height="100%" width="100%" className=" nfttilecontent img-responsive img-thumbnail" />

                    </div>

                    <div className='row  text-center'>
                        <div className='col-sm-7 text-left'>
                            <div className=' text-ellipsis' ><strong>{this.props.nft.collection.collection_name}</strong></div>
                        </div>
                        <div className='col-sm-5'>
                            <div className='text-right' id="rarityColor" >{rarity}</div>
                        </div>
                    </div>
                    <div className='row  text-center'>
                        <div className='col-md-12 text-left'>
                            <div className=' text-ellipsis' ><em>{this.props.nft.name}</em></div>
                        </div>
                    </div>
                    <div className='row  text-center'>
                        <div className='col-md-6 text-left'>
                            {Settings.current_identity.address == this.props.nft.owner.address ?
                                this.props.nft.pending_offers != undefined ? <button type="button" className="btn  btn-success mr-3 " onClick={() => { this.show_review_offer() }}>Review Offers</button> : null
                                : Settings.current_identity.address == "" ?
                                    null :
                                    <button type="button" className="btn  btn-success mr-3 " onClick={() => { this.show_make_offer() }}>Make Offer</button>
                            }

                        </div>
                        <div className='col-md-6 text-left'>

                            <div className='text-right text-ellipsis'>{this.props.nft.pending_offers != undefined ? "Top Offer: " + top + " Val" : "No current offers"}</div>
                            <div className='text-right text-ellipsis'>{this.props.nft.list_price != undefined ? "Requested price: " + this.props.nft.list_price + " Val" : null}</div>
                        </div>
                    </div>

                    {/* <div className='rarity' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Rarity: <p id="rarityColor" >{ rarity }</p></div>*/}
                    <div className='text-center'>

                    </div>
                    {this.state.make_offer ? <OfferNFTPopup nft={this.props.nft} close_callback={this.hide_make_offer.bind(this)} /> : null}
                    {this.state.review_offer ? <ReviewOffersPopup nft={this.props.nft} close_callback={this.hide_review_offer.bind(this)} /> : null}
                </div>
                :
                null

        )
    }
}