import * as React from 'react';
import { SignedFilesTable } from '../SignedFiles/SignedFilesTable';
import * as Settings from '../../Global/settings'
import { VerifyFilePopup } from '../SignedFiles/VerifyFIlePopup'
interface UsersState {
    loading: boolean;

    ShowSignFile: boolean;
    ShowVerifyFile: boolean;
    SmartChain_Synced: boolean
    mobile: boolean;
}

export class AllRecords extends React.Component<{}, UsersState> {
    constructor(props: {}) {
        super(props);
        this.state = { loading: false, ShowSignFile: false, ShowVerifyFile: false, SmartChain_Synced: false, mobile: false };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    ShowSignFile() {
        this.setState({ ShowSignFile: true })
    }
    ShowVerifyFile() {
        this.setState({ ShowVerifyFile: true })
    }
    CloseSignFile() {
        this.setState({ ShowSignFile: false })
    }
    CloseVerifyFile() {
        this.setState({ ShowVerifyFile: false })
    }

    synced_callback() {
        this.setState({ SmartChain_Synced: true })
    }

    get_content() {
        return <SignedFilesTable mobile={this.state.mobile} title={"All Signed Files"} defaultPageSize={-1} showPagination={true} />
    }

    public render() {
        let content = this.get_content()
        return content;
    }
}