import * as React from 'react';
import { Redirect } from 'react-router';
import { User, blank_user } from '../_Interfaces/iUser'
import * as settings from '../../Global/settings'
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { UnixToDate } from '../../Global/UnixToDate'
import { PersonalFileTable } from './PersonalFileTable'
import { CreateUserPopup } from '../Users/CreateUserPopup'
import { EditUserPopup } from '../Users/EditUserPopup'
import { EditUserPopupConfirmation } from '../Users/EditUserPopupConfirmation'
import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'
import { InfoPopup } from '../../Global/InfoPopup'
import * as statics from '../../Global/statics'

import * as bitcoin from 'radiumjs-lib'
import * as ecc from 'tiny-secp256k1';
import * as api from '../../Global/API'
import * as iSigning from '../_Interfaces/iSigning'
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
import { utxo } from '../_Interfaces/iUTXO'
interface State {
    load_complete: boolean;

    ShowCreateIdentity: boolean;

    mobile: boolean;
    rads_balance: number;

    user: User;
    new_user: User;
    encoded_user: encoding_result;
    send_result: SendResult;

    show_edit: boolean;
    show_confirmation: boolean;
    show_result: boolean;

    info_show: boolean;
    info_title: string;
    info_message: string;

    _my_account: my_account;
}

export class MyProfile extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            load_complete: false,

            ShowCreateIdentity: false,
            mobile: false,
            rads_balance: 0,

            user: blank_user,
            new_user: blank_user,
            encoded_user: blank_encoding_result,
            send_result: blank_send_result,

            show_edit: false,
            show_confirmation: false,
            show_result: false,

            info_show: false,
            info_title: "",
            info_message: "",

            _my_account: {
                coins: 0,
                credits: 0,
                pubkey: "",
                RowKey: "",
            }
        };

        this.tick()
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }
    tick() {
        api.GetUser(settings.current_identity.address, (data: any) => { this.setState({ user: data, load_complete: true }); })

        api.GetAddressUtxos(settings.current_identity.address, (data: any) => {
            var value: number = 0;
            data.forEach(
                function (element: utxo) {
                    value += element.value
                }
            )
            this.setState({ rads_balance: value });
        })

        this.get_my_info();
    }

    get_my_info() {
        const body = JSON.stringify({
            method: "getmyinfo",
            block: settings.top_block.hash,
            pubkey: settings.current_identity.pub_key,
            sig: iSigning.sign_message(settings.top_block.hash + "getmyinfo")
        })

        api.GetMyInfo(body, (data: any) => { this.setState({ _my_account: data }); })
    }

    show_edit() {
        this.setState({ show_edit: true })
    }

    close_edit() {
        this.setState({ show_edit: false })
    }

    Should_show(st: string): boolean {
        if (typeof (st) == 'string' && st.length > 0)
            return true;

        return false
    }
    ShowCreateIdentity() {
        this.setState({ ShowCreateIdentity: true })
    }
    CloseCreateUser() {
        this.setState({ ShowCreateIdentity: false })
    }

    get_content() {
        if (settings.current_identity.address === "") { return <Redirect to='/users' /> }

        if (settings.current_identity.username === "") { return <Redirect to='/createuser' /> }
        if (this.state.user.txid == null && settings.current_identity.registration_pending) { return this.render_pending() }
        return this.render_main()
    }

    render_pending() {
        return <span>

            <div className="main-page-head">
                <h2>Welcome to your Dashboard</h2>
            </div>

            <div className="main-page-body">
                <h2> Welcome {this.state.user.username} </h2>
                <h4> Please wait, your account creation is pending! </h4>
            </div>

            <div className="main-page-foot" />

        </span>
    }

    public render() {
        let content = this.get_content()
        return (content)
    }

    public paypal() {
        return <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="73NV4Y372NQY4" />
            <table>
                <tr><td><input type="hidden" name="on0" value="Quantity" />Quantity</td></tr><tr><td><select name="os0">
                    <option value="One (1) Credit">One (1) Credit $0.75 USD</option>
                    <option value="Five (5) Credits">Five (5) Credits $3.00 USD</option>
                    <option value="Twenty (20) Credits">Twenty (20) Credits $10.00 USD</option>
                </select> </td></tr>
            </table>
            <input type="hidden" name="currency_code" value="USD" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" name="submit" alt="PayPal - The safer, easier way to pay online!" />
            <img alt="" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
    }

    ResultsClose() {
        this.setState({ show_result: false })
    }

    info_close() {
        this.setState({ info_show: false });
    }

    EditUserEncode(_user: User) {
        this.setState({ show_edit: false, new_user: _user })

        const body = JSON.stringify({
            user: _user,
        })
        api.EncodeEditUser(body, (data: encoding_result) => {
            this.setState({ encoded_user: data });
            if (data.success) {
                this.setState({ show_confirmation: true });
            } else {
                this.setState({ info_show: true, info_title: "error", info_message: data.message })
            }
        })
    }

    send_smart_tx() {
        const body = JSON.stringify({
            hex: this.state.encoded_user.hex,
            fee: this.state.encoded_user.cost,
            identity: settings.current_identity.address,
        })

        api.BuildSmartTx(body, (data: any) => {
            if (data.success) {
                var tx = bitcoin.Transaction.fromHex(data.message);
                var txb // = bitcoin.TransactionBuilder.fromTransaction(tx, bitcoin.networks.radium);
                txb.sign(0, settings.private_keypair)

                var stx = txb.build().toHex();
                const body2 = JSON.stringify({
                    hex: stx
                })
                //send raw tx
                api.SendRawTx(body2, (data: SendResult) => {
                    if (data.success == true)
                        this.setState({ show_confirmation: false, show_result: true })
                    else
                        this.setState({ info_title: "error", info_message: data.message, info_show: true })

                    this.setState({ send_result: data });
                })
            }
            //show error message because unable to build smart tx
            else { this.setState({ info_title: "error", info_message: data.message, info_show: true }) }
        })
    }

    public render_main() {
        // {
        //    this.state.ShowCreateIdentity ?
        //     <CreateUserPopup close_callback={this.CloseCreateUser.bind(this)} language={this.state.language} />
        //     : null
        //  }

        const prof_url = "https://smartchain.validitytech.com/api/public/ipfsthumb/" + this.state.user.profile_immage
        return <span>

            <div className="main-page-head">
                <h2>Welcome to your Dashboard</h2>
            </div>

            <div className="main-page-body">
                <div className="row">
                    <div className="col-sm-4">
                        {this.state.load_complete ? <span>

                            <div className="row">
                                <div className="col-md-4">
                                    {this.state.user.profile_immage != null ? <img src={prof_url} className="img-responsive img-thumbnail" /> : null}
                                </div>
                                <div className="col-md-8">
                                    <h3> {this.state.user.username}</h3>
                                </div>
                            </div>

                            <h5><dl className="dl-horizontal">

                                <dt>{settings.language.Address} :</dt><dd>{this.state.user.address}</dd>
                                {this.Should_show(this.state.user.description) ? <span><dt>{settings.language.Description} :</dt> <dd>{this.state.user.description}</dd></span> : null}
                                {this.Should_show(this.state.user.company) ? <span><dt>{settings.language.Company} :</dt> <dd>{this.state.user.company}</dd></span> : null}
                                {this.Should_show(this.state.user.streetaddress) ? <span><dt>{settings.language.StreetAddress} :</dt> <dd>{this.state.user.streetaddress}</dd></span> : null}
                                {this.Should_show(this.state.user.phone) ? <span><dt>{settings.language.Phone} :</dt> <dd>{this.state.user.phone}</dd></span> : null}
                                {this.Should_show(this.state.user.email) ? <span><dt>{settings.language.Email} :</dt> <dd>{this.state.user.email}</dd></span> : null}
                                {this.Should_show(this.state.user.website) ? <span><dt>{settings.language.Website} :</dt> <dd>{this.state.user.website}</dd></span> : null}
                                <dt>{settings.language.Join_Date} :</dt> <dd><UnixToDate unix={this.state.user.unixtime} /></dd>

                            </dl></h5>

                            <button type="button" className="btn btn-success mr-3 " onClick={this.show_edit.bind(this)}>Edit Profile</button>
                            <p>Avalible Credits: {this.state._my_account.credits}</p>
                            <p>Avalible Rads: {this.state.rads_balance}</p>

                        </span>

                            : 'loading'
                        }

                    </div>

                    <div className="col-sm-1"> <dt></dt><dd></dd></div>
                    <div className="col-sm-7 outside-table-div">
                        <PersonalFileTable mobile={this.state.mobile} defaultPageSize={-1} showPagination={true} />
                    </div>
                </div>
            </div>

            <div className="main-page-foot" />

            {this.state.show_edit ? <EditUserPopup p_user={this.state.user} close_callback={this.close_edit.bind(this)} /> : null}
            {this.state.info_show ? <InfoPopup title={this.state.info_title} info={this.state.info_message} close_callback={() => { this.info_close() }} /> : null}

        </span>;
    }
}

interface utxo_array {
    result: utxo[],
}

interface my_account {
    RowKey: string,
    pubkey: string,
    credits: number,
    coins: number,
}