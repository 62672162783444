import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Stats } from './components/MainPages/Stats/Stats';
import { Messages } from './components/MainPages/SignedMessages/SignedMesages';
import { Login } from './components/MainPages/Login/Login';
import { MyProfile } from './components/MainPages/MyProfile/MyProfile';
import { Explore } from './components/MainPages/Explore/Explore';
import { Dashboard } from './components/MainPages/Dashboard/Dashboard';
import { SignedFiles } from './components/MainPages/SignedFiles/SignedFiles';
import { Elections } from './components/MainPages/Elections/Elections';
import { NFT } from './components/MainPages/NFT/NFT';
import { NFTCollections } from './components/MainPages/NFTCollections/NFTCollections';
import { RedirectExplore } from './components/Global/RedirectExplore';
import { Tools } from './components/MainPages/Tools/Tools';
import { Marketplace } from './components/MainPages/Marketplace/Marketplace';

import './custom.css'
export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={RedirectExplore} />
                <Route exact path='/explore' component={Explore} />
                <Route exact path='/explore/*' component={Explore} />
                <Route exact path='/home' component={Dashboard} />
                <Route exact path='/signedfiles' component={SignedFiles} />
                <Route exact path='/elections' component={Elections} />
                <Route exact path='/nft' component={NFT} />
                <Route exact path='/nftcollections' component={NFTCollections} />
                <Route exact path='/market' component={Marketplace} />
                <Route exact path='/stats' component={Stats} />
                <Route exact path='/messages' component={Messages} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/myprofile' component={MyProfile} />

                <Route exact path='/tools' component={Tools} />
            </Layout>
        );
    }
}