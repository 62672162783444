import * as React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

interface state {
    interval: any;
    dot_count: number;
}
export interface Props {
    close_callback: any
}

export class LoadingModal extends React.Component<Props, state> {
    constructor(props: Props) {
        super(props);
        this.state = { interval: 250, dot_count: 0 };
    }

    componentDidMount() {
        var inttick = setInterval(() => this.tick(), 250);
        // store intervalId in the state so it can be accessed later:
        this.setState({ interval: inttick });
    }
    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    tick() {
        if (this.state.dot_count < 5) { this.setState({ dot_count: this.state.dot_count + 1 }) }
        else { this.setState({ dot_count: 1 }) }
    }

    getdots(): string {
        var dots = ""
        var i: number = 0
        for (i = 0; i > this.state.dot_count; i++) {
            dots = dots + ".";
        }
        return dots
    }

    render() {
        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Loading {this.getdots()}

            </ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button onClick={() => { this.props.close_callback() }}>Close</button>

                </div>

            </ModalFooter>
        </Modal>
    }
}