import { NFT_command } from '../MainPages/_Interfaces/NFT'

//const FamilyContext = React.createContext({});

//export const FamilyProvider = FamilyContext.Provider;
//export const FamilyConsumer = FamilyContext.Consumer;

export function getCommandTypeText(command: NFT_command): string {
    if (command.command_type.toString() == "destroy")
        return "Destroy";

    if (command.command_type.toString() == "send")
        return "Send";

    if (command.command_type.toString() == "set_locking")
        return "List For Sale";

    if (command.command_type.toString() == "transfer")
        return "Transfer";

    var test = command.command_type;
}