import * as React from 'react';
import { NFT, NFT_command, NFT_command_type } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as settings from "../../Global/settings"
import { SubmitOfferResultComponent } from "./SubmitOfferResultComponent"
import { OfferNFTPopupConfirmation } from "./OfferNFTPopupConfirmation"
import { encoding_result, blank_encoding_result } from '../_Interfaces/iResult'
import * as api from '../../Global/API'
import { utxo } from '../_Interfaces/iUTXO'
import img_noimage from '../../../Assets/noimage.png';
import img_loading from '../../../Assets/ipfsloading.png';
import { TxStatus } from '../_Interfaces/iSmartTx';
interface Props {
    nft: NFT
    close_callback: any;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean
    confirm_name: string
    confirm_id: string

    val_balance: number,
    offer_amount: string,
}

export class OfferNFTPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            show_result: false,
            //
            confirm_id: "",
            confirm_name: "",

            val_balance: 0,
            offer_amount: "",
        };

        api.GetAddressUtxos(settings.current_identity.address, (data: any) => {
            var value: number = 0;
            data.forEach(
                function (element: utxo) {
                    value += element.value
                }
            )
            this.setState({ val_balance: value });
        })
    }

    validate() {
        if (this.props.nft.txid != this.state.confirm_id) {
            this.setState({ show_info: true, info_title: "Error", info_body: "Confirmation ID does not match" })
            return;
        }
        if (this.props.nft.name != this.state.confirm_name) {
            this.setState({ show_info: true, info_title: "Error", info_body: "Confirmation name does not match" })
            return;
        }

        // chesks that asset is transferable
        if (!this.props.nft.can_owner_transfer) {
            this.setState({ show_info: true, info_title: "Error", info_body: "This NFT is non-transferable!" })
            return;
        }

        if (Number.isNaN(Number.parseFloat(this.state.offer_amount))) {
            this.setState({ show_info: true, info_title: "Error", info_body: "Offer amount is not a number" })
            return;
        }

        if (Number.parseFloat(this.state.offer_amount) > this.state.val_balance) {
            this.setState({ show_info: true, info_title: "Error", info_body: "You do not have that much val!" })
            return;
        }

        this.setState({ show_confirmation: true, })
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    results_close() {
        this.setState({ show_result: false })
    }

    popup_close() {
        this.props.close_callback();
    }

    set_offer_amount(value: string) {
        this.setState({ offer_amount: value })
    }

    render() {
        if (this.state.show_confirmation) {
            return <OfferNFTPopupConfirmation NFT={this.props.nft} offer_amount={Number.parseFloat(this.state.offer_amount)} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }
        if (this.state.show_result) {
            return <SubmitOfferResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        var image;

        if (this.props.nft.status != null && this.props.nft.status.status == TxStatus.loading)
            image = img_loading;
        else if (this.props.nft.immage == null)
            image = img_noimage;
        else
            image = "https://smartchain.validitytech.com/api/public/ipfsfull/" + this.props.nft.immage;

        return (<Modal size="lg" backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >

                <div className='row  text-center'>

                    <div className=' p-1  col-md-7'>
                        <img src={image} alt={""} height="100%" width="100%" className=" nfttilecontent img-responsive img-thumbnail" />
                    </div>
                    <div className=' p-1  col-md-5'>
                        Make Offer
                        <dl className="dl-horizontal">
                            <dt>Name :</dt> <dd>{this.props.nft.name}</dd>
                            <dt>Description :</dt> <dd>{this.props.nft.description}<br /></dd>
                            <dt>Owner :</dt> <dd>{this.props.nft.owner!.username}</dd>
                            <dt>ID :</dt> <dd>{this.props.nft.txid}</dd>

                            <dt>Avalible VAL</dt> <dd>{this.state.val_balance}</dd>

                        </dl>
                    </div>
                </div>

            </ModalHeader>
            <ModalBody>

                <p />

                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">Offer Amount</span>
                    <input type="text" className="form-control" placeholder="Click to edit" aria-describedby="basic-addon1" required={true} name="username" onChange={e => { this.set_offer_amount(e.target.value) }} ></input>

                </div>
                <p />
                <span>Once an offer is made, the buyer can accept it at any time untill it is cancled</span>
                <span>Please enter the NFT name and ID below to confirm</span>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">NFT Name*:</span>
                    <input type="text" className="form-control" placeholder="Name" aria-describedby="basic-addon1" required={true} name="username" value={this.state.confirm_name} onChange={e => { this.setState({ confirm_name: e.target.value }) }} ></input>

                </div>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">NFT ID:</span>
                    <input type="text" className="form-control" placeholder="ID" aria-describedby="basic-addon1" required={true} name="username" value={this.state.confirm_id} onChange={e => { this.setState({ confirm_id: e.target.value }) }} ></input>

                </div>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Cancel</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Submit Offer</button>
                </div>
            </ModalFooter>
        </Modal>
        )
    }
}