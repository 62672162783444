import { User, blank_user } from "./iUser";

export interface iFileHash {
    title: string;
    hash: string;
    creator: User;
    unix_time: number;
    username: string;
    txid: string;
    block: number;
}

export const blank_hash = {
    title: "",
    hash: "",
    creator: blank_user,
    unixtime: 0,
    username: "",
    txid: "",
    block: 0,
}