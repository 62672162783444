//const FamilyContext = React.createContext({});

//export const FamilyProvider = FamilyContext.Provider;
//export const FamilyConsumer = FamilyContext.Consumer;

export const requestHeaders: any = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};
export const requestFileHeadders: any = {
    'Accept': 'multipart/form-data',
    'Content-Type': 'multipart/form-data',
};