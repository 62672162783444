import * as React from 'react';
import { User, customfield, blank_user } from '../_Interfaces/iUser'
import { EditUserPopupConfirmation } from './EditUserPopupConfirmation'
import { UploadFilePopup } from '../../Global/UploadFilePopup'

import * as Settings from '../../Global/settings'
import { GetInputPopup } from '../../Global/GetInputPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as api from '../../Global/API'
import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'
import { InfoPopup } from '../../Global/InfoPopup'
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'

interface Props {
    p_user: User;
    close_callback: any;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean
    //object
    user: User

    // stage 3 - try send SmartTx
    upload_stataus: string

    edit_profile: boolean
    edit_pic_hash: boolean
    edit_description: boolean
    edit_company: boolean
    edit_street: boolean
    edit_phone: boolean
    edit_email: boolean
    edit_website: boolean

    profile_pic: any

    show_upload: boolean
}

export class EditUserPopup extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            show_result: false,

            //object
            user: blank_user,
            //

            upload_stataus: "",

            edit_profile: false,
            edit_pic_hash: false,
            edit_description: false,
            edit_company: false,
            edit_street: false,
            edit_phone: false,
            edit_email: false,
            edit_website: false,

            profile_pic: null,
            show_upload: false,
        };
        api.GetUser(this.props.p_user.address, (data: any) => { this.setState({ user: data }); })
    }

    togle_edit_profile() {
        this.setState({ edit_profile: !this.state.edit_profile })
    }
    add_custom() {
        var temp: User = this.state.user
        var custom: customfield = {
            key: "",
            value: "",
            index: 0,
        };
        custom.index = this.state.user.custom_fields.length

        temp.custom_fields.push(custom)

        this.setState({ user: temp });
    }
    remove_custom(index: number) {
        var temp: User = this.state.user
        var custom: customfield[] = temp.custom_fields;

        for (var _i = 0; _i < temp.custom_fields.length; _i++) {
            if (temp.custom_fields[_i].index == index) {
                custom.splice(_i, 1);
                break;
            }
        }

        temp.custom_fields = custom

        this.setState({ user: temp });
    }
    update_custom_key(index: number, value: string) {
        var temp: User = this.state.user
        var custom: customfield = temp.custom_fields[index];
        custom.key = value
        temp.custom_fields[index] = custom

        this.setState({ user: temp });
    }

    update_custom_value(index: number, value: string) {
        var temp: User = this.state.user
        var custom: customfield = temp.custom_fields[index];
        custom.value = value
        temp.custom_fields[index] = custom

        this.setState({ user: temp });
    }
    toggle_upload_immage() {
        this.setState({ show_upload: !this.state.edit_pic_hash })
    }

    togle_edit_description() {
        this.setState({ edit_description: !this.state.edit_description })
    }
    togle_edit_edit_company() {
        this.setState({ edit_company: !this.state.edit_company })
    }
    togle_edit_street() {
        this.setState({ edit_street: !this.state.edit_street })
    }
    togle_edit_phone() {
        this.setState({ edit_phone: !this.state.edit_phone })
    }
    togle_edit_email() {
        this.setState({ edit_email: !this.state.edit_email })
    }
    togle_edit_website() {
        this.setState({ edit_website: !this.state.edit_website })
    }

    show_profile_help() {
        var text = "To set your profile picture, please uplad a picture of yourself to IPFS."
        text = text + "We suggest  using https://globalupload.io/.  Once uploaded, enter the hash in the 'Profile Picture Hash' box. "
        text = text + "All IPFS hashes all start with 'Qm' and look similar to this: QmZDQxCAK1yjvf7MkkpFqNphv4tJzPksmnS4BN5LfTw1JS "
        text = text + "Your profile picture will become part of the permanent web, maintained by the ZeroClient server."
        this.setState({ info_title: "Info", info_body: text, show_info: true })
    }

    validate() {
        var u: User = this.state.user;

        if (!this.state.edit_description) {
            u.description = ""
        }
        if (!this.state.edit_company) {
            u.company = ""
        }
        if (!this.state.edit_street) {
            u.streetaddress = ""
        }
        if (!this.state.edit_phone) {
            u.phone = ""
        }
        if (!this.state.edit_email) {
            u.email = ""
        }
        if (!this.state.edit_website) {
            u.website = ""
        }

        var temp: User = this.state.user
        var new_customs: customfield[] = [];

        for (var _i = 0; _i < u.custom_fields.length; _i++) {
            if (this.IsNew(u.custom_fields[_i]))
                new_customs.push(u.custom_fields[_i])
        }

        u.custom_fields = new_customs

        this.setState({ show_confirmation: true })
    }

    IsNew(test_field: customfield): boolean {
        for (var _j = 0; _j < this.props.p_user.custom_fields.length; _j++) {
            if (this.props.p_user.custom_fields[_j].value == test_field.value && this.props.p_user.custom_fields[_j].key == test_field.key)
                return false;
        }
        return true;
    }

    set_profile(hash: string) {
        this.setState({ user: { ...this.state.user, profile_immage: hash }, show_upload: false })
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    results_close() {
        this.setState({ show_result: false })
    }

    popup_close() {
        this.props.close_callback();
    }

    render() {
        if (this.state.show_confirmation) {
            return <EditUserPopupConfirmation user={this.state.user} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }

        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        const prof_url = "https://smartchain.validitytech.com/api/public/ipfsthumb/" + this.state.edit_pic_hash ? this.state.user.profile_immage : this.state.user.profile_immage
        return (<span><Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Edit Existing Identity

            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-4">
                        <img src={"https://smartchain.validitytech.com/api/public/ipfsthumb/" + prof_url} className="img-responsive img-thumbnail" />
                    </div>

                </div>

                <dl className="dl-horizontal">
                    <dt className="left">{Settings.language.Username + "*"}  :</dt> <dd>   {this.props.p_user.username} </dd>
                    <dt className="left">{Settings.language.Address + "*"}  :</dt> <dd>   {this.state.user.address} </dd>
                </dl>

                <p />
                Click headder to edit
                <div className="input-group" >
                    <span className="input-group-text" id="basic-addon1" onClick={this.toggle_upload_immage.bind(this)} >Profile Picture Hash: {this.state.edit_pic_hash ? <span>(editing)</span> : null}</span>
                    <input type="text" className="form-control" disabled={true} aria-describedby="basic-addon1" name="pic_hash" value={this.state.edit_pic_hash ? this.state.user.profile_immage : this.state.user.profile_immage != null ? this.state.user.profile_immage : ""}  ></input>

                </div>
                <p />

                <div className="input-group" >
                    <span className="input-group-text" id="basic-addon1" onClick={this.togle_edit_description.bind(this)} >Description: {this.state.edit_description ? <span>(editing)</span> : null}</span>
                    <input type="text" className="form-control" disabled={!this.state.edit_description} aria-describedby="basic-addon1" name="description" value={this.state.edit_description ? this.state.user.description : this.state.user.description != null ? this.state.user.description : ""} onChange={e => { this.setState({ user: { ...this.state.user, description: e.target.value } }) }} ></input>
                </div>
                <p />
                <div className="input-group" >
                    <span className="input-group-text" id="basic-addon1" onClick={this.togle_edit_edit_company.bind(this)} >Company: {this.state.edit_company ? <span>(editing)</span> : null}</span>
                    <input type="text" className="form-control" disabled={!this.state.edit_company} aria-describedby="basic-addon1" name="company" value={this.state.edit_company ? this.state.user.company : this.state.user.company != null ? this.state.user.company : ""} onChange={e => { this.setState({ user: { ...this.state.user, company: e.target.value } }) }} ></input>
                </div>
                <p />
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1" onClick={this.togle_edit_street.bind(this)} >Street Address:{this.state.edit_street ? <span>(editing)</span> : null}</span>
                    <input type="text" className="form-control" disabled={!this.state.edit_street} aria-describedby="basic-addon1" name="streetaddress" value={this.state.edit_street ? this.state.user.streetaddress : this.state.user.streetaddress != null ? this.state.user.streetaddress : ""} onChange={e => { this.setState({ user: { ...this.state.user, streetaddress: e.target.value } }) }} ></input>
                </div>
                <p />
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1" onClick={this.togle_edit_phone.bind(this)} >Phone:{this.state.edit_phone ? <span>(editing)</span> : null}</span>
                    <input type="text" className="form-control" disabled={!this.state.edit_phone} aria-describedby="basic-addon1" name="phone" value={this.state.edit_phone ? this.state.user.phone : this.state.user.phone != null ? this.state.user.phone : ""} onChange={e => { this.setState({ user: { ...this.state.user, phone: e.target.value } }) }} ></input>
                </div>
                <p />
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1" onClick={this.togle_edit_email.bind(this)} >Email:{this.state.edit_email ? <span>(editing)</span> : null}</span>
                    <input type="text" className="form-control" disabled={!this.state.edit_email} aria-describedby="basic-addon1" name="email" value={this.state.edit_email ? this.state.user.email : this.state.user.email != null ? this.state.user.email : ""} onChange={e => { this.setState({ user: { ...this.state.user, email: e.target.value } }) }} ></input>
                </div>
                <p />
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1" onClick={this.togle_edit_website.bind(this)} >Website:{this.state.edit_website ? <span>(editing)</span> : null}</span>
                    <input type="text" className="form-control" disabled={!this.state.edit_website} aria-describedby="basic-addon1" name="website" value={this.state.edit_website ? this.state.user.website : this.state.user.website != null ? this.state.user.website : ""} onChange={e => { this.setState({ user: { ...this.state.user, website: e.target.value } }) }} ></input>
                </div>

                <p />

                * Required

                {this.state.user.custom_fields.map(custom =>
                    <span>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">
                                <input type="text" className="custom_input " placeholder="Custom Key" name="website" value={custom.key} onChange={e => { this.update_custom_key(custom.index, e.target.value) }} ></input>
                            </span>
                            <input type="text" className="form-control" placeholder="Custom Value" aria-describedby="basic-addon1" name="website" value={custom.value} onChange={e => { this.update_custom_value(custom.index, e.target.value) }} ></input>
                            <span className="input-group-addon nopad " id="basic-addon1">
                                <button type="button" className="btn btn-success mr-3 btn-success nopad noborder" onClick={e => { this.remove_custom(custom.index) }}>X</button>
                            </span>
                        </div>
                        <p />
                    </span>

                )}
                <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.add_custom.bind(this)}>Add Custom Field</button>
                <p />
            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Close</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Continue</button>
                </div>
            </ModalFooter>

        </Modal>

            {this.state.show_upload ? <UploadFilePopup success_callback={this.set_profile.bind(this)} cancel_callback={() => { this.setState({ show_upload: false }) }} /> : null}

        </span>

        )
    }
}