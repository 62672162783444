import * as React from 'react';
import ReactTable from 'react-table';

import { FileInfoPopup } from './FileInfoPopup'
import { UnixToDate } from '../../Global/UnixToDate'
import { iFileHash } from '../_Interfaces/iFileHash'
import * as Settings from '../../Global/settings'
import * as statics from '../../Global/statics'
import * as api from '../../Global/API'
import * as settings from '../../Global/settings'
interface FileTableState {
    users: iFileHash[];
    rangestart: number;
    loading: boolean;
    selected_file: iFileHash;
    ShowFile: boolean;

    title: string;
    username: string;
    hash: string
    verified: string;

    intervaltick: any;
    sc_top: string

    show_all: boolean;
    pagination_rows: number;
}
export interface Props {
    defaultPageSize: number;
    showPagination: boolean;

    mobile: boolean;
    address?: string;
    title: string;
}

export class SignedFilesTable extends React.Component<Props, FileTableState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            users: [],
            rangestart: 0,
            loading: true,
            selected_file: null,
            ShowFile: false,
            verified: "",
            username: "",
            title: "",
            hash: "",
            intervaltick: 5000,
            sc_top: "",
            show_all: true,
            pagination_rows: 10,
        };
        this.load_data()
    }

    load_data() {
        const body = JSON.stringify({
            owner: this.props.address,
        })

        api.GetFilteredFiles(body, (data: any) => { this.setState({ users: data, loading: false }); })
    }

    resize() {
        var rows = Math.round((window.innerHeight - (window.innerHeight * .25)) / 50)

        this.setState({ pagination_rows: rows })
    }

    tick() {
        if (settings.top_block.sc_top != this.state.sc_top) {
            this.load_data()
            this.setState({ sc_top: settings.top_block.sc_top })
        }
    }
    componentDidMount() {
        var inttick = setInterval(() => this.tick(), 5000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervaltick: inttick });
        window.addEventListener("resize", this.resize.bind(this))
        this.resize();
    }
    componentWillUnmount() {
        clearInterval(this.state.intervaltick);
    }

    CloseViewFile() {
        this.setState({ ShowFile: false })
    }

    OpenViewFile(file: iFileHash) {
        this.setState({ selected_file: file, ShowFile: true })
    }

    public render() {
        let contents = this.state.loading
            ? <p><em>{settings.language.Loading}</em></p>
            : this.RenderUserTable();

        return contents
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value } as any, () => {
            this.filter()
        });
    }
    handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value } as any, () => {
            this.filter()
        });
    }

    //need to get the filter values into an interface to post

    filter() {
        var owner: string = this.state.show_all ? "" : Settings.current_identity.address;
        const body = JSON.stringify({
            title: this.state.title,
            username: this.state.username,
            status: this.state.verified,
            hash: this.state.hash,
            owner: owner,
        })

        api.GetFilteredFiles(body, (data: any) => { this.setState({ users: data, loading: false }); })
    }

    public RenderUserTable() {
        const data = this.state.users;
        const columns = [
            {
                Header: 'title',
                accessor: 'title',
                className: "sticky",
                headerClassName: "sticky",
                filterMethod: (filter: any, row: any) =>

                    false
            },
            {
                Header: settings.language.Username,
                accessor: 'creator.username'
            },
            {
                Header: 'hash',
                accessor: 'hash'
            },
            {
                Header: 'date recorded',
                accessor: 'unix_time',
                Cell: (row: any) => (
                    <UnixToDate unix={row.value} />
                )
            }

        ]

        return <div className="main-page-body table table-responsive">
            <div className="body-header">
                <h1>{this.props.title}</h1>
            </div>
            <div className="body-content">

                {this.props.mobile ?
                    <div className="row">
                        <div className="form-group col-sm-6">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Title</span>
                                <input type="text" className="form-control" placeholder="Title" aria-describedby="basic-addon1" name="title" value={this.state.title} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Hash</span>
                                <input type="text" className="form-control" placeholder="Hash" aria-describedby="basic-addon1" name="hash" value={this.state.hash} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>

                    </div>
                    :

                    <div className="row">
                        <div className="form-group col-sm-4">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Title</span>
                                <input type="text" className="form-control" placeholder="Title" aria-describedby="basic-addon1" name="title" value={this.state.title} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-4">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Username</span>
                                <input type="text" className="form-control" placeholder="Username" aria-describedby="basic-addon1" name="username" value={this.state.username} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-4">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Hash</span>
                                <input type="text" className="form-control" placeholder="Hash" aria-describedby="basic-addon1" name="hash" value={this.state.hash} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                    </div>
                }
                {this.props.mobile ?
                    <ReactTable

                        data={data}
                        columns={columns}
                        showPagination={true}
                        defaultPageSize={this.state.pagination_rows}
                        minRows={this.state.pagination_rows}
                        className="-highlight"
                        loading={this.state.loading}
                        defaultSorted={[{ id: "unix_time", desc: true }]}

                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: () => {
                                    console.log("A Td Element was clicked!");

                                    this.OpenViewFile(rowInfo.row.original)
                                    // IMPORTANT! React-Table uses onClick internally to trigger
                                    // events like expanding SubComponents and pivots.
                                    // By default a custom 'onClick' handler will override this functionality.
                                    // If you want to fire the original onClick handler, call the
                                    // 'handleOriginal' function.
                                }
                            };
                        }}

                    />

                    :
                    < ReactTable

                        data={data}
                        columns={columns}
                        showPagination={true}
                        defaultPageSize={this.state.pagination_rows}
                        minRows={this.state.pagination_rows}
                        className="-highlight"
                        loading={this.state.loading}
                        defaultSorted={[{ id: "unix_time", desc: true }]}

                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onDoubleClick: () => {
                                    console.log("A Td Element was clicked!");

                                    this.OpenViewFile(rowInfo.row._original)
                                    // IMPORTANT! React-Table uses onClick internally to trigger
                                    // events like expanding SubComponents and pivots.
                                    // By default a custom 'onClick' handler will override this functionality.
                                    // If you want to fire the original onClick handler, call the
                                    // 'handleOriginal' function.
                                }
                            };
                        }}

                    />
                }

                {this.state.ShowFile ?
                    <FileInfoPopup file={this.state.selected_file} close_callback={this.CloseViewFile.bind(this)} />
                    : null
                }

            </div>
        </div>
    }
}