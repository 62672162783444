import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Topright } from '../../Global/toprightmenue/toprightmenue'
interface NavMenuState {
    mobile: boolean
}

interface Props {
}

export class ExploreMenu extends React.Component<Props, NavMenuState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            mobile: false,
        };
    }

    componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this))

        // store intervalId in the state so it can be accessed later:
    }
    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    public render() {
        return <div className='row navbar main-nav topmenue navbar-expand-lg'>
            <nav className="col-sm-8   ">

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="nav navbar-nav navbar-justify">
                        <li>
                            <NavLink to={'/explore/smarttxs'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                                <span className='fa fa-users'></span> SmartTxs
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/explore/users'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                                <span className='fa fa-users'></span> Users
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/explore/records'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                                <span className='fa fa-file'></span> Records
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/explore/elections'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                                <span className='fa fa-check-square'></span> Elections
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/explore/nfts'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse'>
                                <span className='fa fa-tag'></span> NFTs
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/explore/nftcollections'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                                <span className='fa fa-tag'></span> NFT Collections
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className="col-sm-4 ">
                <div >
                    <Topright />
                </div>
            </div>
        </div>;
    }
}