import * as bitcoin from 'radiumjs-lib'
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../MainPages/_Interfaces/iResult'
import * as statics from './statics'
import * as settings from "../Global/settings"
import { payments, networks } from 'radiumjs-lib'

import * as ecc from 'tiny-secp256k1';

export var pub_base: string = "/api/public/"
var priv_base: string = "/api/auth/"

//export function SetApi(callback: any) {
//    fetch("api_servers")
//        .then(response => response.json() as Promise<string[]>)
//        .then((json) => {
//            json.forEach(
//                function iterator(item) {
//                    check_fetch_api(item, callback);
//                },
//            )
//        });
//}

//function check_fetch_api(endpoint: string, callback: any) {
//    fetch(endpoint + "/api/public/gettopblock")
//        .then(response => response.json() as Promise<Block>)
//        .then((json) => {
//            if (json.height > 500000)
//                if (pub_base == "") {
//                    pub_base = endpoint + "/api/public/"
//                        priv_base = endpoint + "/api/auth/"
//                    callback(true);
//                }

//        });
//}

export function GetLockingOutput(address: string, callback: any) {
    pub_get_json('getlockingoutput/' + address, callback)
}

export function BuildLockintOutputs(_body: any, callback: any) {
    pub_post_json('makerawlockinginput', _body, callback)
}

export function signAcceptOffer(hex: string, incomplete: boolean, callback: any): string {
    const { address } = payments.p2pkh({ pubkey: settings.private_keypair.publicKey, network: networks.radium })

    var tx = bitcoin.Transaction.fromHex(hex);
    //var txb = bitcoin.TransactionBuilder.fromTransaction(tx, bitcoin.networks.radium);

    //txb.sign(0, settings.private_keypair)

    var stx;

    if (incomplete) {
        //stx = txb.buildIncomplete().toHex();
    }
    else {
        // stx = txb.build().toHex();
    }

    callback(stx);

    return null;
}

export function signMakeOffer(hex: string, incomplete: boolean, callback: any): string {
    const { address } = payments.p2pkh({ pubkey: settings.private_keypair.publicKey, network: networks.radium })

    var tx = bitcoin.Transaction.fromHex(hex);
    var txb //= bitcoin.TransactionBuilder.fromTransaction(tx, bitcoin.networks.radium);

    for (var i = 0; i < tx.ins.length; i++) {
        if (i > 0)
            txb.sign(i, settings.private_keypair)
    }

    var stx;

    if (incomplete) {
        stx = txb.buildIncomplete().toHex();
    }
    else {
        stx = txb.build().toHex();
    }

    callback(stx);

    return null;
}

export function send(res: encoding_result, callback: any): SendResult {
    const { address } = payments.p2pkh({ pubkey: settings.private_keypair.publicKey, network: networks.radium })

    const body = JSON.stringify({
        hex: res.hex,
        fee: res.cost,
        identity: address,
    })

    BuildSmartTx(body, (build_data: SendResult) => {
        if (build_data.success) {
            var tx = bitcoin.Transaction.fromHex(build_data.message);
            var txb = bitcoin.TransactionBuilder.fromTransaction(tx, bitcoin.networks.radium);

            txb.sign(0, settings.private_keypair)

            var stx = txb.build().toHex();
            const body2 = JSON.stringify({
                hex: stx
            })
            //send raw tx
            SendRawTx(body2, (send_data: SendResult) => {
                callback(send_data);
            })
        }
        else {
            callback(build_data);
        }
    })
    return null;
}

export function send_raw(hex: string, callback: any): SendResult {
    const { address } = payments.p2pkh({ pubkey: settings.private_keypair.publicKey, network: networks.radium })
    var tx = bitcoin.Transaction.fromHex(hex);
    var txb //= bitcoin.TransactionBuilder.fromTransaction(tx, bitcoin.networks.radium);

    txb.sign(0, settings.private_keypair)

    var stx = txb.build().toHex();
    const body2 = JSON.stringify({
        hex: stx
    })
    //send raw tx
    SendRawTx(body2, (send_data: SendResult) => {
        callback(send_data);
    })

    return null;
}

export function GetFilteredPendingObjectCommands(target: string, callback: any) {
    pub_get_json('GetFilteredPendingObjectCommands/' + target, callback)
}

export function GetFilteredPendingOutputs(target: string, callback: any) {
    pub_get_json('GetFilteredPendingOutputs/' + target, callback)
}

export function GetFilteredPendingSpent(target: string, callback: any) {
    pub_get_json('GetFilteredPendingSpent/' + target, callback)
}

export function GetCancelOfferTx(_body: any, callback: any) {
    pub_post_json('GetCancelOfferTx', _body, callback)
}
export function GetCancelSaleTx(_body: any, callback: any) {
    pub_post_json('GetCancelSaleTx', _body, callback)
}

export function UploadToIpfs(_body: any, callback: any) {
    pub_post_file('IpfsUpload', _body, callback)
}

export function IsWeb(callback: any) {
    pub_get_json('IsWeb', callback)
}

export function GetFilteredOffers(_body: any, callback: any) {
    pub_post_json('GetFilteredOffers', _body, callback)
}

export function CheckOffer(_body: any, callback: any) {
    pub_post_json('CheckOffer', _body, callback)
}

export function SubmitNewOffer(_body: any, callback: any) {
    pub_post_json('SubmitNewOffer', _body, callback)
}

export function getrecentsmarttxs(callback: any) {
    pub_get_json('getrecentsmarttxs', callback)
}

export function PostNewMultisig(_body: any, callback: any) {
    pub_post_json('PostNewMultisig', _body, callback)
}
export function PostCreateRawTransaction(_body: any, callback: any) {
    pub_post_json('PostCreateRawTransaction', _body, callback)
}
export function GetInputValue(_body: any, callback: any) {
    pub_post_json('GetInputValue', _body, callback)
}

export function EncodeEditUser(_body: any, callback: any) {
    pub_post_json('EncodeEditUser', _body, callback)
}

export function EncodeNewUser(_body: any, callback: any) {
    pub_post_json('EncodeNewUser', _body, callback)
}

export function GetBlockByHash(hash: string, callback: any) {
    pub_get_json('GetBlockByHash/' + hash, callback)
}

export function EncodeFileHash(_body: any, callback: any) {
    pub_post_json('EncodeFileHash', _body, callback)
}

export function GetAllRecords(callback: any) {
    pub_get_json('GetAllRecords', callback)
}

export function GetFileHash(hash: string, callback: any) {
    pub_get_json('GetFileHash/' + hash, callback)
}

export function GetNetworkInfo(callback: any) {
    pub_get_json('GetNetworkInfo', callback)
}
export function GetServerInfo(callback: any) {
    pub_get_json('GetServerInfo', callback)
}

export function GetFilteredFiles(_body: string, callback: any) {
    pub_post_json('GetFilteredFiles', _body, callback)
}

export function GetMyInfo(_body: any, callback: any) {
    auth_post_json('getmyinfo', _body, callback)
}

export function GetAddressUtxos(address: string, callback: any) {
    pub_get_json('GetAddressUtxos/' + address, callback)
}

export function GetFilteredElections(_body: string, callback: any) {
    pub_post_json('GetFilteredElections', _body, callback)
}

export function GetAllElections(callback: any) {
    pub_get_json('GetAllElections', callback)
}

export function GetElection(electionID: string, callback: any) {
    pub_get_json('GetElection/' + electionID, callback)
}

export function EncodeNewElection(_body: any, callback: any) {
    pub_post_json('EncodeNewElection', _body, callback)
}
export function EncodeNewVote(_body: any, callback: any) {
    pub_post_json('EncodeNewVote', _body, callback)
}

export function GetAllNfts(callback: any) {
    pub_get_json('GetAllNFTs', callback)
}

export function GetNFT(nfttxid: string, callback: any) {
    pub_get_json('GetNFT/' + nfttxid, callback)
}

export function EncodeNewNftCommand(_body: any, callback: any) {
    pub_post_json('EncodeNewNFTCommand', _body, callback)
}

export function EncodeNewNFTOffer(_body: any, callback: any) {
    pub_post_json('EncodeNewNFTOffer', _body, callback)
}

export function EncodeNewNft(_body: any, callback: any) {
    pub_post_json('EncodeNewNFT', _body, callback)
}

export function EncodeNewNftCollection(_body: any, callback: any) {
    pub_post_json('EncodeNewNFTCollection', _body, callback)
}

export function FilterNft(_body: string, callback: any) {
    pub_post_json('GetFilteredNFTs', _body, callback)
}

export function FilterMarketplace(_body: string, callback: any) {
    pub_post_json('GetFilteredMarketplace', _body, callback)
}

export function FilterNftcollection(_body: any, callback: any) {
    pub_post_json('GetFilteredNFTCollections', _body, callback)
}

export function EncodeNewIpfsLink(_body: any, callback: any) {
    pub_post_json('EncodeNewIPFSLink', _body, callback)
}

export function GetSCTop(callback: any) {
    pub_get_string("SCTop", callback);
}

export function gettopblock(callback: any) {
    pub_get_json("GetTopBlock", callback);
}

export function GetUser(address: string, callback: any) {
    pub_get_json('GetUser/' + address, callback)
}

export function GetUserFromName(name: string, callback: any) {
    pub_get_json('GetUserFromName/' + name, callback)
}

export function GetAllUsers(callback: any) {
    pub_get_json('GetAllUsers', callback)
}

export function AllNftcollectiones(callback: any) {
    pub_get_json('GetAllNFTCollections', callback)
}

export function FilteredNftcollectiones(body: any, callback: any) {
    pub_get_json('GetAllNFTCollections', callback)
}

export function GetNftcollection(nft_id: any, callback: any) {
    pub_get_json('GetNFTCollection/' + nft_id, callback)
}

export function SendRawTx(_body: any, callback: any) {
    pub_post_json('SendRawTX', _body, callback)
}

export function BuildSmartTx(_body: any, callback: any) {
    auth_post_json('BuildSmartTx', _body, callback)
}

export function FilteredUsers(_body: any, callback: any) {
    pub_post_json('GetFilteredUsers', _body, callback)
}

export function GetSetupInfo(callback: any) {
    pub_get_json('Status', callback);
}

export function SyncInfo(callback: any) {
    pub_get_json('SyncInfo', callback);
}

export function DashboardInfo(callback: any) {
    pub_get_json('GetDashboardInfo', callback);
}

function pub_post_json(endpoint: string, _body: string, callback: any) {
    fetch(pub_base + endpoint, {
        method: 'POST',
        headers: statics.requestHeaders,
        body: _body
    })
        .then(response => response.json())
        .then(data => {
            callback(data);
        });
}

function auth_post_json(endpoint: string, _body: string, callback: any) {
    fetch(priv_base + endpoint, {
        method: 'POST',
        headers: statics.requestHeaders,
        body: _body
    })
        .then(response => response.json())
        .then(data => {
            callback(data);
        });
}

function pub_get_json(endpoint: string, callback: any) {
    fetch(pub_base + endpoint)
        .then((response) => { return response.json() })
        .then((json) => {
            callback(json);
        });
}
function pub_get_string(endpoint: string, callback: any) {
    fetch(pub_base + endpoint)
        .then((response) => { return response.text() })
        .then((text) => {
            callback(text);
        });
}

function pub_post_file(endpoint: string, selectedFile: any, callback: any) {
    const formData = new FormData();
    formData.append('body', selectedFile);

    fetch(pub_base + endpoint, {
        method: 'POST',

        body: formData
    })
        .then(response => response.json())
        .then(data => {
            callback(data);
        });
}