import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as settings from '../../Global/settings'
import * as api from '../../Global/API'
import { InfoPopup } from '../../Global/InfoPopup'
import { encoding_result, blank_encoding_result } from '../_Interfaces/iResult'
interface Props {
    cancel_callback: any;
    continue_callback: any;

    title: string;
    hash: string;
}
interface SignFileConfirmationComponentState {
    encoding_result: encoding_result;
}

export class SignFileConfirmationComponent extends React.Component<Props, SignFileConfirmationComponentState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            encoding_result: blank_encoding_result
        };
        const body = JSON.stringify({
            title: this.props.title,
            hash: this.props.hash,
        })

        api.EncodeFileHash(body, (data: any) => { this.setState({ encoding_result: data }); })
    }

    render() {
        if (!this.state.encoding_result.success) {
            return <InfoPopup title={'Error'} info={this.state.encoding_result.message} close_callback={this.props.cancel_callback} />
        }
        return (<Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.cancel_callback() }} >
                <h4> Please ensure the following information is correct</h4>

            </ModalHeader>
            <ModalBody>
                <dl className="dl-horizontal">
                    <dt>File Hash :</dt> <dd>{this.props.hash}</dd>
                    <dt>Title :</dt> <dd>{this.props.title}</dd>
                    <dt>Cost :</dt> <dd>{this.state.encoding_result.cost}</dd>

                    <dt>Using Identity:</dt> <dd></dd>
                    <dt>{settings.language.Username}:</dt> <dd>{settings.current_identity.username}</dd>
                    <dt>{settings.language.Address}:</dt> <dd>{settings.current_identity.address}</dd>
                </dl>

                <h5>"The cost for this operation is {this.state.encoding_result.cost} Val. Are you sure?</h5>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.props.cancel_callback()}>Back</button>
                <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.props.continue_callback(this.state.encoding_result)}>Continue</button>
            </ModalFooter>
        </Modal>

        );
    }
}