import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as api from '../../Global/API'
import { UnixToDate } from '../../Global/UnixToDate'
import { iFileHash, blank_hash } from '../_Interfaces/iFileHash'
interface Props {
    close_callback: any;

    file: iFileHash;
}
interface state {
    title: string;
    loading: boolean;
    username: string;
}

export class FileInfoPopup extends React.Component<Props, state>{
    constructor(props: Props) {
        super(props);
    }

    back() {
        this.props.close_callback()
    }

    get_content() {
        if (this.props.file.hash == "") {
            return this.render_fail()
        }
        else { return this.render_success() }
    }

    render_fail() {
        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                <h4>Unknown File! Procede with caution </h4>

            </ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.back.bind(this)}>Back</button>

            </ModalFooter>
        </Modal>
    }
    render_success() {
        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                <h4> File </h4>
            </ModalHeader>
            <ModalBody>
                <dl className="dl-horizontal">
                    <dt>Title :</dt> <dd>{this.props.file.title}</dd>
                    <dt>File Hash :</dt> <dd>{this.props.file.hash}</dd>
                    <dt>Signing Identity:</dt> <dd>{this.props.file.creator.username}</dd>
                    <dt>Signing Address:</dt> <dd>{this.props.file.creator.address}</dd>
                    <dt>Signing Date:</dt> <dd><UnixToDate unix={this.props.file.unix_time} /></dd>
                    <dt>Signing Txid:</dt> <dd> {this.props.file.txid} </dd>
                </dl></ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.back.bind(this)}>Back</button>

            </ModalFooter>
        </Modal>
    }

    render() {
        let content = this.get_content()
        return content
    }
}