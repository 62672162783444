import * as React from 'react';
import { blank_user, User } from '../_Interfaces/iUser'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as api from '../../Global/API'
import { LoadingModal } from '../../Global/LoadingModal'
import { UnixToDate } from '../../Global/UnixToDate'

import * as settings from '../../Global/settings'
const icon48 = require('../../../Assets/radium-48.png');

interface state {
    title: string;
    user: User
    loading: boolean;
    username: string;
}
interface Props {
    close_callback: any;
    location: string;
}
export class Standalone_User extends React.Component<Props, state> {
    constructor(props: Props) {
        super(props);
        this.state = {
            title: "",
            user: blank_user,
            loading: true,
            username: ""
        };

        var start: number = this.props.location.lastIndexOf("=") + 1;
        var end: number = this.props.location.length;

        api.GetUserFromName(this.props.location.substring(start, end), (data: any) => {
            this.setState({ user: data, loading: false });
        })
    }

    get_content() {
        if (this.state.loading) {
            return <LoadingModal close_callback={null} />
        }

        if (this.state.user.address === "") {
            return this.render_fail()
        }
        else { return this.render_success() }
    }

    render_fail() {
        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                <h4>Unknown File! Procede with caution </h4>

            </ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>
    }
    render_success() {
        const prof_url = "https://smartchain.validitytech.com/api/public/ipfsthumb/" + this.state.user.profile_immage
        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                <div className="col-md-4">
                    {this.state.user.profile_immage != null ? <img src={prof_url} alt={icon48} className="img-responsive img-thumbnail" /> : null}
                </div>
                <div className="col-md-8">
                    {this.state.user.username}
                </div>
            </ModalHeader>
            <ModalBody>

                {!this.state.loading ?
                    <dl className="dl-horizontal">

                        <dt>Address :</dt><dd>{this.state.user.address}</dd>
                        {this.Should_show(this.state.user.description) ? <span><dt>{settings.language.Description} :</dt> <dd>{this.state.user.description}</dd></span> : null}
                        {this.Should_show(this.state.user.company) ? <span><dt>{settings.language.Company} :</dt> <dd>{this.state.user.company}</dd></span> : null}
                        {this.Should_show(this.state.user.streetaddress) ? <span><dt>{settings.language.StreetAddress} :</dt> <dd>{this.state.user.streetaddress}</dd></span> : null}
                        {this.Should_show(this.state.user.phone) ? <span><dt>{settings.language.Phone} :</dt> <dd>{this.state.user.phone}</dd></span> : null}
                        {this.Should_show(this.state.user.email) ? <span><dt>{settings.language.Email} :</dt> <dd>{this.state.user.email}</dd></span> : null}
                        {this.Should_show(this.state.user.website) ? <span><dt>{settings.language.Website} :</dt> <dd>{this.state.user.website}</dd></span> : null}

                        {this.state.user.custom_fields.map(custom =>
                            <span><dt>{custom.key} :</dt> <dd>{custom.value}</dd></span>

                        )}

                        <dt>Join Date :</dt> <dd><UnixToDate unix={this.state.user.unixtime} /></dd>

                    </dl>

                    : 'loading...'}
            </ModalBody>
            <ModalFooter>
                <div><h5>Blockchain secured record validation services provided by <a href="https://validitytech.com"> Validity Platform </a>  </h5></div>
                <div> Developed and maintaind by SmartchainSoftware Solutions, LLC.</div>
            </ModalFooter>
        </Modal>
    }

    Should_show(st: string): boolean {
        if (typeof (st) == 'string' && st.length > 0)
            return true;

        return false
    }

    render() {
        let content = this.get_content()
        return content
    }
}