import * as React from 'react';

import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import * as statics from '../../Global/statics'
import { encoding_result, blank_encoding_result } from '../_Interfaces/iResult'
import { InfoPopup } from '../../Global/InfoPopup'
import * as api from '../../Global/API'
import { Election_lite, candidate_lite } from '../_Interfaces/Elections'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as Settings from '../../Global/settings'
interface Props {
    election: Election_lite

    complete_callback: any;
    cancel_callback: any;
}
interface CreateElectionPopupConfirmationState {
    encoding_result: encoding_result;
}
export class CreateElectionPopupConfirmation extends React.Component<Props, CreateElectionPopupConfirmationState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            encoding_result: blank_encoding_result
        };
        const body = JSON.stringify({
            election: this.props.election,
        })

        api.EncodeNewElection(body, (data: any) => { this.setState({ encoding_result: data }); })
    }

    continue() {
        this.props.complete_callback(this.state.encoding_result)
    }
    back() {
        this.props.cancel_callback()
    }

    render() {
        if (!this.state.encoding_result.success) {
            return <InfoPopup title={'Error'} info={this.state.encoding_result.message} close_callback={this.props.cancel_callback} />
        }

        return (<Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.cancel_callback() }} >
                Please ensure the following information is correct

            </ModalHeader>
            <ModalBody>

                <dl className="dl-horizontal">
                    <dt>Title :</dt> <dd>{this.props.election.title}</dd>
                    <dt>{Settings.language.Description} :</dt><dd>{this.props.election.description}</dd>
                    <dt>candidates :</dt><dd></dd>
                    {this.props.election.candidates.map(forecast =>
                        <span><dt></dt> <dd>{forecast.text}</dd></span>

                    )}

                    <dt>Start Block :</dt><dd>{this.props.election.start_block}</dd>
                    <dt>End Block :</dt><dd>{this.props.election.end_block}</dd>
                </dl>

                <h4>The cost for this operation is {this.state.encoding_result.cost} Radium. Are you sure?</h4>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.back.bind(this)}>Close</button>
                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.continue.bind(this)}>Create</button>
                </div>
            </ModalFooter>
        </Modal>

        );
    }
}