import * as React from 'react';
import { CreateElectionPopup } from './CreateElectionPopup'

import * as Settings from '../../Global/settings'
import { InfoPopup } from '../../Global/InfoPopup'
import { Topright } from '../../Global/toprightmenue/toprightmenue'
interface NavMenuState {
    show_create: boolean,
    show_info: boolean,
    info_title: string,
    info_message: string,
    mobile: boolean
}

interface Props {
}

export class ElectionsMenu extends React.Component<Props, NavMenuState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show_create: false,
            show_info: false,
            info_title: "",
            info_message: "",
            mobile: false,
        };
    }

    show_create(e: any) {
        this.setState({ show_create: true })
    }
    close_create() {
        this.setState({ show_create: false })
    }

    show_vote() {
        this.setState({ show_info: true, info_title: "Info!", info_message: "Double click an election for voting options!" })
    }

    close_info() {
        this.setState({ show_info: false })
    }

    componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this))

        // store intervalId in the state so it can be accessed later:
    }
    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    public render() {
        return <div className='row navbar topmenue main-nav navbar-expand-lg'>
            <nav className="col-sm-8   ">

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="nav navbar-nav navbar-justify">
                        <li>
                            <div onClick={() => { this.setState({ show_create: true }) }} className='nav-link div-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                                <span className=' fa fa-pencil-square-o'> Create Election</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={this.show_vote.bind(this)} className='nav-link div-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                                <span className=' fa fa-exchange'> Vote</span>
                            </div>
                        </li>

                    </ul>
                </div>
            </nav>
            <div className="col-sm-4 ">
                <div >
                    <Topright />
                </div>
            </div>

            {this.state.show_create ? <CreateElectionPopup close_callback={this.close_create.bind(this)} /> : null}
            {this.state.show_info ? <InfoPopup title={this.state.info_title} info={this.state.info_message} close_callback={this.close_info.bind(this)} /> : null}

        </div>;
    }
}