import * as React from 'react';

import * as Settings from '../../Global/settings'
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { User, blank_user } from '../_Interfaces/iUser'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { UnixToDate } from '../../Global/UnixToDate'
import * as api from '../../Global/API'

interface Props {
    address: string;

    close_callback: any;
}
interface UserInfoPopupState {
    user: User
    load_complete: boolean
}
export class UserInfoPopup extends React.Component<Props, UserInfoPopupState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            user: blank_user, load_complete: false
        };
        api.GetUser(this.props.address, (data: any) => {
            this.setState({
                user: data,
                load_complete: true
            });
        })
    }
    //required for security, set pass to null

    close() {
        this.props.close_callback(true)
    }
    toggle() { };
    Should_show(st: string): boolean {
        if (typeof (st) == 'string' && st.length > 0)
            return true;

        return false
    }

    render() {
        const prof_url = "https://smartchain.validitytech.com/api/public/ipfsfull/" + this.state.user.profile_immage
        return (
            <Modal isOpen={true} toggle={() => { this.props.close_callback() }}>
                <ModalHeader toggle={() => { this.props.close_callback() }} >
                    User Profile
                    <div className="row">
                        <div className="col-md-4">
                            {this.state.user.profile_immage != null ? <img src={prof_url} className="img-responsive img-thumbnail" /> : null}
                        </div>
                        <div className="col-md-8">
                            {this.state.user.username}
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>

                    {this.state.load_complete ?
                        <dl className="dl-horizontal">

                            <dt className='fa fa-address-book'>{" " + Settings.language.Address} :</dt><dd> {this.state.user.address}</dd>
                            {this.Should_show(this.state.user.description) ? <span><dt className='fa fa-pencil-square'>{" " + Settings.language.Description} :</dt> <dd>{this.state.user.description}</dd></span> : null}
                            {this.Should_show(this.state.user.company) ? <span><dt className='fa fa-building'> {" " + Settings.language.Company} :</dt> <dd>{this.state.user.company}</dd></span> : null}
                            {this.Should_show(this.state.user.streetaddress) ? <span><dt className='fa fa-road'> {" " + Settings.language.StreetAddress} :</dt> <dd>{this.state.user.streetaddress}</dd></span> : null}
                            {this.Should_show(this.state.user.phone) ? <span><dt className='fa fa-phone'> {" " + Settings.language.Phone} :</dt> <dd>{this.state.user.phone}</dd></span> : null}
                            {this.Should_show(this.state.user.email) ? <span><dt className='fa fa-envelope'> {" " + Settings.language.Email} :</dt> <dd>{this.state.user.email}</dd></span> : null}
                            {this.Should_show(this.state.user.website) ? <span><dt className='fa fa-address-card-'>{" " + Settings.language.Website} :</dt> <dd>{this.state.user.website}</dd></span> : null}

                            {this.state.user.custom_fields.map(custom =>
                                <span><dt className='fa fa-cogs' >{" " + custom.key} :</dt> <dd>{custom.value}</dd></span>

                            )}

                            <dt className='fa fa-calendar'>{" " + Settings.language.Join_Date} :</dt> <dd><UnixToDate unix={this.state.user.unix_time} /></dd>

                        </dl>

                        : 'loading...'}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-default btn-danger" onClick={() => { this.props.close_callback() }}>{Settings.language.Cancel}</button>
                </ModalFooter>
            </Modal>

        );
    }
}