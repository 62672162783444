import * as React from 'react';
import { NFT, blank_NFT, NFTCollection } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { User, blank_user } from "../_Interfaces/iUser"
import { SelectUser } from "../../Global/SelectUser"
import * as settings from "../../Global/settings"
import { CreateNFTinCollectionPopupConfirmation } from "./CreateNFTinCollectionPopupConfirmation"
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'

import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'

interface Props {
    close_callback: any;

    address?: string;
    collection: NFTCollection;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean

    // stage 1 - gather info
    NFT: NFT
    show_select_user: boolean

    // stage 2 - confirm info

    use_assetclass: boolean

    issue_to_self: boolean
    issueto_display_name: string;
}

export class CreateNFTinCollectionPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
        address: ""
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            show_result: false,
            //
            NFT: blank_NFT,
            show_select_user: false,

            //
            use_assetclass: false,
            issue_to_self: false,
            issueto_display_name: "",
        };

        var temp: NFT = this.state.NFT;

        temp.collection = this.props.collection;

        this.setState({ NFT: temp })
    }

    close() {
        this.props.close_callback(true)
    }

    set_user(u: User) {
        if (u == undefined) { return }
        this.setState({ issueto_display_name: "User:" + u.username + " Address:" + u.address })
        this.setState({ NFT: { ...this.state.NFT, owner: u } })
        this.setState({ show_select_user: false })
    }

    validate() {
        if (this.state.issue_to_self) {
            this.setState({ NFT: { ...this.state.NFT, owner: settings.current_identity } })
        }

        this.setState({ show_confirmation: true })
    }

    close_select_user() {
        this.setState({ show_select_user: false })
    }

    fail() {
        this.setState({ show_confirmation: false, show_result: false })
    }

    success() {
        this.props.close_callback()
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    results_close() {
        this.setState({ show_result: false })
    }

    popup_close() {
        this.props.close_callback();
    }

    render() {
        if (this.state.show_confirmation) {
            return <CreateNFTinCollectionPopupConfirmation NFT={this.state.NFT} collection={this.props.collection} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }
        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }
        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Create New NFT within a Collection

            </ModalHeader>
            <ModalBody>

                {this.props.collection.nft_inherit_name ?

                    <dl className="dl-horizontal" >
                        <dt>NFT Name :</dt><dd>{this.props.collection.nft_name == "" || this.props.collection.nft_name == undefined || this.props.collection.nft_name == null ? this.props.collection.collection_name : this.props.collection.nft_name}</dd>
                        <dt>NFT Description :</dt><dd>{this.props.collection.nft_name == "" || this.props.collection.nft_name == undefined || this.props.collection.nft_name == null ? this.props.collection.collection_description : this.props.collection.nft_description}</dd>
                    </dl>

                    :
                    <span>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">NFT Name*:</span>
                            <input type="text" className="form-control" placeholder="Name" aria-describedby="basic-addon1" required={true} name="username" value={this.state.NFT.name} onChange={e => { this.setState({ NFT: { ...this.state.NFT, name: e.target.value } }) }} ></input>

                        </div>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">NFT Description:</span>
                            <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" required={true} name="username" value={this.state.NFT.description} onChange={e => { this.setState({ NFT: { ...this.state.NFT, description: e.target.value } }) }} ></input>

                        </div>
                    </span>
                }

                <p />
                {!this.state.issue_to_self ?
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">Issue To:</span>
                        <input type="text" className="form-control" placeholder="Click to edit" aria-describedby="basic-addon1" required={true} name="username" value={this.state.issueto_display_name} onClick={e => { this.setState({ show_select_user: true }) }} ></input>

                    </div>
                    :
                    null}

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.issue_to_self} onChange={e => { this.setState({ issue_to_self: !this.state.issue_to_self }) }} />
                        Issue NFT to yourself</label>
                </div>
                <dl className="dl-horizontal" >
                    <dt> Creators can destroy :</dt><dd><TrueFalseIcon state={this.props.collection.nft_can_creator_destroy} /></dd>
                    <dt> Owner can destroy :</dt><dd><TrueFalseIcon state={this.props.collection.nft_can_owner_destroy} /></dd>
                    <dt> Owner can transfer :</dt><dd><TrueFalseIcon state={this.props.collection.nft_can_owner_transfer} /></dd>
                </dl>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Close</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Continue</button>
                </div>
            </ModalFooter>
            {this.state.show_select_user ?
                <SelectUser cancel_callback={this.close_select_user.bind(this)} return_callback={this.set_user.bind(this)} />
                : null
            }

        </Modal>
        )
    }
}

interface result {
    hex: string
    cost: number
}