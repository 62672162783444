import * as React from 'react';
import { NFTTable } from '../NFT/NFTTable';

interface NFTState {
    mobile: boolean;
}

export interface LoadingProps {
}
export class AllNFT extends React.Component<LoadingProps, NFTState> {
    constructor(props: LoadingProps) {
        super(props);
        this.state = {
            mobile: false,
        };
        //used to bypas loading screen for gui dev
        //this.props.callback(true)
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    public render() {
        return <NFTTable mobile={this.state.mobile} title={"All NFT's"} defaultPageSize={-1} showPagination={true} />

            ;
    }
}

interface setup_data {
    message: string;
    progress: number;
}