import * as React from 'react';
import t from '../../Language/Language'
import * as statics from '../../Global/statics'
import { TrueFalseIcon } from "../../Global/TrueFalseIcon"
import * as api from '../../Global/API'
import { NFT, NFTCollection } from '../_Interfaces/NFT'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { InfoPopup } from '../../Global/InfoPopup'
import * as settings from "../../Global/settings"
import { encoding_result, blank_encoding_result } from '../_Interfaces/iResult'
interface Props {
    NFT: NFT
    collection: NFTCollection
    cancel_callback: any;
    continue_callback: any;
}
interface State {
    encoding_result: encoding_result;
}

export class CreateNFTinCollectionPopupConfirmation extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            encoding_result: blank_encoding_result,
        };

        const body = JSON.stringify({
            nft: this.props.NFT,
        })
        api.EncodeNewNft(body, (data: any) => {
            this.setState({ encoding_result: data })
        })
    }
    //required for security, set pass to null

    Should_show(st: string): boolean {
        if (typeof (st) == 'string' && st.length > 0)
            return true;

        return false
    }

    close_info() {
        this.props.cancel_callback()
    }

    get_nft_name(): string {
        if (this.props.NFT.name != "")
            return this.props.NFT.name
        if (this.props.collection.nft_name != undefined)
            return this.props.collection.nft_name
        return this.props.collection.collection_name
    }

    get_nft_description(): string {
        if (this.props.NFT.description != "")
            return this.props.NFT.description
        if (this.props.collection.nft_description != undefined)
            return this.props.collection.nft_description
        return this.props.collection.collection_description
    }

    render() {
        if (!this.state.encoding_result.success) {
            return <InfoPopup title={'Error'} info={this.state.encoding_result.message} close_callback={this.props.cancel_callback} />
        }

        return (<Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.cancel_callback() }} >
                Please ensure the following information is correct

            </ModalHeader>
            <ModalBody>

                <dl className="dl-horizontal">
                    <dt>NFT Name :</dt> <dd>{this.get_nft_name()}</dd>
                    <dt>{settings.language.Description} :</dt><dd>{this.get_nft_description()}</dd>
                    <dt>NFT Collection :</dt><dd> {this.props.collection.owner.username}.{this.props.collection.collection_name}</dd>
                    <dt>NFT Issued To :</dt><dd>{
                        this.props.NFT.owner == undefined ? <span>Self</span> : this.props.NFT.owner.username
                    }</dd>

                    <dt> Creators can destroy NFTs :</dt> <dd><TrueFalseIcon state={this.props.collection.nft_can_creator_destroy} /></dd>
                    <dt> Owner can destroy NFTs :</dt> <dd><TrueFalseIcon state={this.props.collection.nft_can_owner_destroy} /></dd>
                    <dt> Owner can transfer NFTs :</dt> <dd><TrueFalseIcon state={this.props.collection.nft_can_owner_transfer} /></dd>

                </dl>

                <h4>The cost for this operation is {this.state.encoding_result.cost} VAl. Are you sure?</h4>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => {
                        this.props.cancel_callback()
                    }}>Close</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => {
                        this.props.continue_callback(this.state.encoding_result)
                    }}>Create</button>
                </div>
            </ModalFooter>
        </Modal>

        );
    }
}