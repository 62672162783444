import * as React from 'react';
import { Election, blank_election, candidate } from '../_Interfaces/Elections'
import { User, blank_user } from '../_Interfaces/iUser'
import ReactTable from 'react-table';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { CastVoteConfirmation } from './CastVoteConfirmation'
import { InfoPopup } from '../../Global/InfoPopup'
import { UnixToDate } from '../../Global/UnixToDate'
import * as settings from '../../Global/settings'
import * as api from '../../Global/API'
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'

interface Props {
    hash: string;

    show_me: boolean
    close_callback: any;
}
interface ElectionInfoPopupState {
    election: Election
    load_complete: boolean
    selected_index: number;
    show_confirmation: boolean;

    encoding_result: encoding_result;
    send_result: SendResult;

    show_result: boolean;

    // info popup
    show_info: boolean
    info_title: string
    info_body: string
}
export class ElectionInfoPopup extends React.Component<Props, ElectionInfoPopupState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            election: blank_election,
            load_complete: false,
            selected_index: -1,
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            send_result: blank_send_result,

            show_result: false,
            show_info: false,
            info_body: "",
            info_title: "",
        };
        api.GetElection(this.props.hash, (data: any) => {
            this.setState({ election: data, load_complete: true });
        })
    }
    //required for security, set pass to null

    close() {
        this.props.close_callback(true)
    }

    Fail_vote() {
        this.setState({ show_confirmation: false, show_result: false })
    }
    open_confirm_vote() {
        if (settings.current_identity.address == "") {
            this.setState({ show_info: true, info_title: "Not logged in", info_body: "Please login before voting!" })
            return;
        }

        this.setState({ show_confirmation: true })
    }
    close_confirm_vote() {
        this.setState({ show_confirmation: false })
    }

    Should_show(st: string): boolean {
        if (typeof (st) == 'string' && st.length > 0)
            return true;

        return false
    }

    set_selected_index(index: number) {
        this.setState({ selected_index: index })
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }

    is_open(election: Election): boolean {
        console.log(settings.top_block.height)
        if (settings.top_block.height < election.start_block)
            return false;
        if (settings.top_block.height > election.end_block)
            return false;
        return true;
    }

    select_content() {
        if (this.state.show_confirmation) {
            return (<CastVoteConfirmation election={this.state.election} candidate_index={this.state.selected_index} cancel_callback={this.close_confirm_vote.bind(this)} continue_callback={this.send.bind(this)} identity={settings.current_identity.address} />)
        }

        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.close() }} fail_callback={this.Fail_vote.bind(this)} />
        }

        const data: candidate[] = this.state.election.candidates;
        const own: User = this.state.election.creator;
        const columns = [
            {
                Header: 'Candidate',
                accessor: 'text'
            },
            {
                Header: "Votes",
                accessor: 'votes',
                Cell: (row: any) => (
                    <span>{row.value.length}</span>
                )
            },

        ]

        return (

            <span className="test">
                <Modal isOpen={true} onHide={() => { this.close() }}>
                    <ModalHeader toggle={() => { this.close() }} >
                        <dl className="dl-horizontal">
                            <span><dt> Called by:</dt> <dd>{this.state.election.creator.username}</dd></span>
                            <span><dt>Voting Period :</dt> <dd>block {this.state.election.start_block} to {this.state.election.end_block}{this.is_open(this.state.election) ? " -Open-" : " -Closed-"}</dd></span>
                            <span><dt>VoteID :</dt> <dd>{this.state.election.id} </dd></span>
                            <span><dt>Title :</dt> <dd>{this.state.election.title} </dd></span>
                            <span><dt>CurrentBlock :</dt> <dd>{settings.top_block.height} </dd></span>
                        </dl>

                    </ModalHeader>
                    <ModalBody>
                        <dl className="dl-horizontal" >
                            {this.state.load_complete ? <span>
                                <dt>Description :</dt> <dd>{this.state.election.description}</dd>
                                < div className="table table-responsive" >
                                    < ReactTable
                                        data={data}
                                        columns={columns}
                                        showPagination={false}
                                        minRows={2}
                                        className="-highlight"
                                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                                            return {
                                                onClick: (e: MouseEvent) => { this.set_selected_index(rowInfo.row._index) },
                                                style: {
                                                    background: this.state.selected_index == rowInfo.index ? '#43b581' : 'white',
                                                }
                                            };
                                        }}
                                    />
                                </div  >
                            </span> : <span>Loading...</span>}
                        </dl>
                    </ModalBody>
                    <ModalFooter>
                        <div className="btn-toolbar" role="group" aria-label="...">
                            {this.is_open(this.state.election) ?
                                <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => { this.open_confirm_vote() }}>Cast Vote</button>
                                : null}
                            <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.close.bind(this)}>Close</button>
                        </div>
                    </ModalFooter>
                    {this.state.show_info ? <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => { this.setState({ show_info: false }) }} /> : null}
                </Modal>
            </span>

        );
    }

    render() {
        let content = this.select_content()

        return (content)
    }
}

interface result {
    hex: string
    cost: number
}