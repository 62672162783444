import * as React from 'react';
import * as api from '../../Global/API'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { encoding_result, SendResult, blank_send_result } from '../../MainPages/_Interfaces/iResult'
interface Props {
    show: boolean;
    fail_callback: any;
    success_callback: any;
    encoding_result: encoding_result;
}
interface SmartTxSendResultComponentState {
    show_info: boolean;
    info_message: string;
    info_title: string;
    send_result: SendResult;
    send_complete: boolean;
}
export class SubmitOfferResultComponent extends React.Component<Props, SmartTxSendResultComponentState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            show_info: false,
            info_message: "",
            info_title: "",
            send_result: blank_send_result,
            send_complete: false,
        };

        api.signMakeOffer(this.props.encoding_result.hex, true, (build_data: string) => {
            const body = JSON.stringify({
                offer: build_data,
            })

            api.SubmitNewOffer(body, (data: SendResult) => {
                this.setState({ send_result: data, send_complete: true })
                if (data.success == false)
                    this.setState({ info_title: "error", info_message: data.message, show_info: true })
            })
        })
    }

    success() {
        this.props.success_callback()
    }

    fail() {
        this.props.fail_callback()
    }

    select_headder() {
        if (this.state.send_result.success) {
            return "Transaction sent"
        }
        else {
            return "Error!"
        }
    }

    select_body() {
        if (!this.state.send_complete) {
            return <dl className="dl-horizontal">
                <span><h4> Your message is being sent, please wait!</h4></span>
            </dl>
        }

        if (this.state.send_result.success) {
            return <dl className="dl-horizontal">
                <span><h4> Success! Your offer has been submitted. It will remain active until it is canceled or accepted. Offers can be canceled in the 'Your Pending Offers to Buy' menue. </h4></span>
            </dl>
        }
        else {
            return this.state.send_result.message
        }
    }

    render() {
        return (<span>
            <Modal isOpen={this.props.show} backdrop={"static"} toggle={() => { this.success() }}>
                <ModalHeader toggle={() => { this.success() }} >
                    {this.select_headder()}

                </ModalHeader>
                <ModalBody>
                    {this.select_body()}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.state.send_result.success ? this.success.bind(this) : this.fail.bind(this)}>{this.state.send_result.success ? "close" : "return"}</button>
                </ModalFooter>
            </Modal>

        </span>
        )
    }
}