import * as React from 'react';
import t from '../../Language/Language'
import { NFT, NFT_command, blank_NFT_command, NFT_command_type } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import * as Settings from '../../Global/settings'
import { GetInputPopup } from '../../Global/GetInputPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { User, blank_user } from "../_Interfaces/iUser"
import { SelectUser } from "../../Global/SelectUser"
import * as functions from "../../Global/functions"
import * as settings from "../../Global/settings"
import { SmartTxSendResultComponent } from "../../Global/SmartTxSendResultComponent"
import { SellNFTPopupConfirmation } from "./SellNFTPopupConfirmation"
import { CreateOutput } from "./CreateOutput"
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
import { utxo, blank_utxo, get_utxo_result, blank_utxo_result } from '../_Interfaces/iUTXO'
import { iSmartTx } from '../_Interfaces/iSmartTx'
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'

import * as api from '../../Global/API'
interface Props {
    nft: NFT
    close_callback: any;
    success_callback: any;
    language: number;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean

    command: NFT_command

    price: number

    show_select_user: boolean

    // stage 2 - confirm info

    issueto_display_name: string

    show_make_input: boolean

    get_utxo_result: get_utxo_result;

    pending_commands: NFT_command[];

    pending_outputs: utxo[];
}

export class SellNFTPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            show_result: false,
            //

            price: 0,

            command: {
                destination: undefined,
                amount: undefined,
                object_id: this.props.nft.txid,
                command_type: NFT_command_type.set_locking,
            },
            show_select_user: false,

            //
            issueto_display_name: "",

            show_make_input: false,

            get_utxo_result: blank_utxo_result,
            pending_commands: [],

            pending_outputs: [],
        };

        this.load_pending_utxos = this.load_pending_utxos.bind(this);

        this.load_data()
        this.load_pending_commands()
        this.load_pending_utxos()
    }

    timerid: any = 0;
    time_loop_count: number = 0;

    load_data() {
        api.GetLockingOutput(settings.current_identity.address, (data: get_utxo_result) => {
            this.setState({ get_utxo_result: data });
            if (data.success) {
                this.setState({ command: { ...this.state.command, locking_txid: data.data.txid, index: data.data.index } })
            }
        })

        api.GetFilteredPendingObjectCommands(this.props.nft.txid, (data: any) => {
            this.setState({ pending_commands: data });
        });
    }

    load_pending_commands() {
        api.GetFilteredPendingObjectCommands(this.props.nft.txid, (data: any) => {
            this.setState({ pending_commands: data });
        })
    }

    load_pending_utxos() {
        api.GetFilteredPendingOutputs(settings.current_identity.address, (data: utxo[]) => {
            if (data.length > 0) {
                if (this.timerid == 0)
                    this.timerid = setInterval(this.load_pending_commands.bind(this), 1000)
            }
            else {
                this.time_loop_count++;
                if (this.time_loop_count < 10)
                    return;
                clearInterval(this.timerid)
                this.time_loop_count = 0;
                this.timerid = 0;
                this.load_data();
            }

            if (this.state.pending_outputs.length != data.length) {
                this.setState({ pending_outputs: data });
                this.load_data();
            }
        })
    }

    close() {
        this.props.close_callback(true)
    }

    set_user(u: User) {
        if (u == undefined) { return }
        this.setState({ issueto_display_name: "User:" + u.username + " Address:" + u.address })
        this.setState({ command: { ...this.state.command, destination: u } })
        this.setState({ show_select_user: false })
    }

    validate() {
        // chesks that asset is transferable
        if (!this.props.nft.can_owner_transfer) {
            this.setState({ show_info: true, info_title: "Error", info_body: "This NFT is non-transferable!" })
            return;
        }

        let can_transfer: boolean = false
        if (this.props.nft.can_owner_transfer) {
            if (this.props.nft.owner!.address == settings.current_identity.address) {
                can_transfer = true;
            }
        }
        if (!can_transfer) {
            this.setState({ show_info: true, info_title: "Error", info_body: "You do not have permission to transfer this NFT!" })
            return;
        }

        //command: { ...this.state.command, locking_txid: data.data.txid, index: data.data.index }

        this.setState({ command: { ...this.state.command, amount: Math.round(this.state.price * 100000000) } })

        this.setState({ show_confirmation: true })
    }

    close_select_user() {
        this.setState({ show_select_user: false })
    }

    fail() {
        this.setState({ show_confirmation: false, show_result: false })
    }

    success() {
        this.props.success_callback()
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    results_close() {
        this.setState({ show_result: false })
    }

    show_make_input() {
        this.setState({ show_make_input: true })
    }
    hide_make_input() {
        this.setState({ show_make_input: false })

        if (this.timerid == 0) {
            this.timerid = setInterval(this.load_pending_utxos.bind(this), 1000)
            this.time_loop_count = 0;
        }
    }

    popup_close() {
        this.props.close_callback();
    }

    render_locking() {
        if (this.state.get_utxo_result.success) {
            return <div className='row p-1 '>
                <div className='   col-md-4'>
                    #1 Locking Tx:
                </div>
                <div className='   col-md-8'>
                    <TrueFalseIcon state={true} />{this.state.get_utxo_result.data.txid.substring(0, 30) + "..."}{this.state.get_utxo_result.data.index}
                </div>
            </div>
        }
        else {
            if (this.state.pending_outputs.length > 0) {
                return <div className='row p-1 '>
                    <div className='   col-md-4'>
                        #1 Locking Tx:
                    </div>

                    <div className='   col-md-8'>
                        <TrueFalseIcon state={false} /> You have pending uxtos in the mempool. Please wait for these to confirm before continuing.
                    </div>
                </div>
            }
            else {
                return <div className='row p-1 '>
                    <div className='   col-md-4'>
                        #1 Locking Tx:
                    </div>

                    <div className='   col-md-1'>
                        <TrueFalseIcon state={false} />
                    </div>
                    <div className='   col-md-7'>
                        <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.show_make_input.bind(this)}>Make Inputs</button>
                    </div>
                </div>
            }
        }
    }

    getCommandTypeText(command: NFT_command) {
        if (command.command_type.toString() == "destroy")
            return "Destroy";

        if (command.command_type.toString() == "send")
            return "Send";

        if (command.command_type.toString() == "set_locking")
            return "List For Sale";

        if (command.command_type.toString() == "transfer")
            return "Transfer";

        var test = command.command_type;
    }

    render() {
        if (this.state.show_make_input) {
            return <CreateOutput close_callback={this.hide_make_input.bind(this)} />
        }

        if (this.state.show_confirmation) {
            return <SellNFTPopupConfirmation NFT={this.props.nft} command={this.state.command} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }
        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }
        if (this.state.pending_commands.length > 0)

            return <InfoPopup title={"Warning"} info={"This object has a pending " + functions.getCommandTypeText(this.state.pending_commands[0]) + " action. Please wait untill the current action clears the mempool"} close_callback={this.close.bind(this)} />

        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                List NFT for sale

            </ModalHeader>
            <ModalBody>

                <span>Listing an NFT for sale allows others to place buy offers.</span>
                <span>You will need to manually accept any buy offers you may receive. </span>
                <span>You can set a price below. It is a sugestion and you may receive </span>
                <span>offers that are different from your price.</span>
                <p />
                <dl className="dl-horizontal">
                    <dt>Name :</dt> <dd>{this.props.nft.name}</dd>
                    <dt>Description :</dt> <dd>{this.props.nft.description}<br /></dd>
                    <dt>Owner :</dt> <dd>{this.props.nft.owner!.username}</dd>
                    <dt>ID :</dt> <dd>{this.props.nft.txid}</dd>
                </dl>
                <p />
                <h5> Listing Requirements </h5>

                {this.render_locking()}

                <div className='row p-1'>

                    <div className='   col-md-4'>
                        #2 Sugested Price:
                    </div>
                    <div className='   col-md-8'>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">Price (Val):</span>
                            <input type="number" className="form-control" placeholder="Name" aria-describedby="basic-addon1" required={true} name="username" value={this.state.price} onChange={e => { this.setState({ price: Number.parseFloat(e.target.value) }) }} ></input>

                        </div>
                    </div>

                </div>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Cancel</button>

                    {this.state.get_utxo_result.success ? <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Continue</button> : null}

                </div>
            </ModalFooter>

        </Modal>
        )
    }
}

interface result {
    hex: string
    cost: number
}