import * as React from 'react';

import { ElectionTable } from '../Elections/ElectionsTable';

interface UsersState {
    loading: boolean;

    ShowSignFile: boolean;
    show_create: boolean;
    SmartChain_Synced: boolean;
    mobile: boolean;
}

export class AllElections extends React.Component<{}, UsersState> {
    constructor(props: {}) {
        super(props);
        this.state = { loading: false, ShowSignFile: false, show_create: false, SmartChain_Synced: false, mobile: false };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }
    ShowCreateElection() {
        this.setState({ show_create: true })
    }
    CloseCreateElection() {
        this.setState({ show_create: false })
    }

    synced_callback() {
        this.setState({ SmartChain_Synced: true })
    }

    get_content() {
        return <ElectionTable title={"Elections"} mobile={this.state.mobile} defaultPageSize={-1} showPagination={true} />
    }

    public render() {
        let content = this.get_content()
        return content;
    }
}