import { User, blank_user } from './iUser'
import { SmartTxStatus, blank_SmartTxStatus } from './iSmartTx'
import { utxo, blank_utxo } from '../_Interfaces/iUTXO'

export interface NFTCollection {
    block: 0,
    unix_time: 0,
    txid: string;

    nft_name: string;
    nft_description: string;
    nft_inherit_name: boolean;

    nft_can_creator_destroy: boolean;
    nft_can_owner_destroy: boolean;
    nft_can_owner_transfer: boolean;

    collection_name: string;
    collection_description: string;
    collection_image: string;
    collection_can_owner_destroy: boolean;

    collection_transferable: boolean;

    transfer_exclusions: string;
    transfer_inclusions: string;

    meta_data: string;

    creator: User;
    owner: User;

    status: SmartTxStatus;
}

export const blank_NFTCollection: NFTCollection = {
    block: 0,
    unix_time: 0,
    txid: "",

    nft_name: "",
    nft_description: "",
    nft_inherit_name: false,

    nft_can_creator_destroy: false,
    nft_can_owner_destroy: false,
    nft_can_owner_transfer: false,

    collection_name: "",
    collection_description: "",
    collection_image: "",
    collection_can_owner_destroy: false,
    collection_transferable: false,

    transfer_exclusions: "",
    transfer_inclusions: "",

    meta_data: "",

    creator: blank_user,
    owner: blank_user,
    status: blank_SmartTxStatus,
}

export interface NFT {
    name: string;
    immage: string;
    description: string;
    can_creator_destroy: boolean;
    can_owner_destroy: boolean;
    can_owner_transfer: boolean;
    collection?: NFTCollection;
    owner: User;
    transfer_inclusions: string;
    transfer_exclusions: string;
    meta_data: string;
    block: 0,
    unix_time: 0,
    txid: string;
    creator: User;
    status: SmartTxStatus;
    custom_fields: key_value[];
    pending_offers: Offer[];
    chain_lock: utxo;
    list_price: number;

    pending_action: string;
}

export const blank_NFT: NFT = {
    name: "",
    immage: "",
    description: "",
    can_creator_destroy: false,
    can_owner_destroy: false,
    can_owner_transfer: false,
    transfer_inclusions: "",
    transfer_exclusions: "",
    meta_data: "",
    block: 0,
    unix_time: 0,
    txid: "",
    creator: blank_user,
    owner: blank_user,
    status: blank_SmartTxStatus,
    custom_fields: [],
    pending_offers: [],
    chain_lock: blank_utxo,
    list_price: 0,
    pending_action: "",
}

export interface Offer {
    Buyer: User,
    NFT: NFT,
    transaction: string,
    amount: number,
    pending_action: string,
    pending_cancel: boolean
}

export const blank_offer: Offer = {
    Buyer: blank_user,
    NFT: blank_NFT,
    transaction: "",
    amount: 0,
    pending_action: "",
    pending_cancel: false
}

export interface NFT_command {
    object_id: string,
    command_type: NFT_command_type,
    destination?: User,
    amount?: number,
    locking_txid?: string,
    index?: number,
}

export const blank_NFT_command: NFT_command = {
    object_id: "",
    command_type: 1
}

export enum NFT_command_type {
    transfer = 1,
    destroy = 2,
    send = 3,
    set_locking = 4,
}

export interface ipfs_link {
    link: string,
}

export const blank_ipfs_link: ipfs_link = {
    link: "",
}

export interface key_value {
    key: string,
    value: string,
}

export const blank_key_value: key_value = {
    key: "",
    value: "",
}