import * as React from 'react';
import { MyOffersPopup } from './MyOffersPopup'
import * as Settings from '../../Global/settings'
import { InfoPopup } from '../../Global/InfoPopup'
import { Topright } from '../../Global/toprightmenue/toprightmenue'
import { MyForSalePopup } from './MyForSalePopup';
interface NavMenuState {
    show_my_offers: boolean,
    show_my_for_sale: boolean,
    mobile: boolean
}

interface Props {
}

export class MarketMenu extends React.Component<Props, NavMenuState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show_my_offers: false,
            show_my_for_sale: false,

            mobile: false,
        };
    }

    show_my_offers(e: any) {
        this.setState({ show_my_offers: true })
    }
    close_my_offers() {
        this.setState({ show_my_offers: false })
    }

    show_my_forsale(e: any) {
        this.setState({ show_my_for_sale: true })
    }
    close_my_forsale() {
        this.setState({ show_my_for_sale: false })
    }
    componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this))

        // store intervalId in the state so it can be accessed later:
    }
    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    public render() {
        return <div className='row navbar topmenue main-nav navbar-expand-lg'>
            <nav className="col-sm-8   ">

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="nav navbar-nav navbar-justify">

                        {Settings.current_identity.address != "" ? <li>
                            <div onClick={() => { this.setState({ show_my_offers: true }) }} className='nav-link div-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                                <span className=' fa fa-pencil-square-o'>Your Pending Offers to Buy</span>
                            </div>
                        </li> : null}

                        {Settings.current_identity.address != "" ? <li>
                            <div onClick={() => { this.setState({ show_my_for_sale: true }) }} className='nav-link div-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                                <span className=' fa fa-pencil-square-o'>Your NFT's for sale</span>
                            </div>
                        </li> : null}

                    </ul>
                </div>
            </nav>
            <div className="col-sm-4 ">
                <div >
                    <Topright />
                </div>
            </div>

            {this.state.show_my_offers ? <MyOffersPopup close_callback={this.close_my_offers.bind(this)} /> : null}
            {this.state.show_my_for_sale ? <MyForSalePopup close_callback={this.close_my_forsale.bind(this)} /> : null}

        </div>;
    }
}