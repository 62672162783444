import * as React from 'react';
import { TrueFalseIcon } from "../../Global/TrueFalseIcon"
import * as api from '../../Global/API'
import { NFTCollection } from '../_Interfaces/NFT'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { InfoPopup } from '../../Global/InfoPopup'
import { encoding_result, blank_encoding_result } from '../_Interfaces/iResult'
interface Props {
    collection: NFTCollection

    cancel_callback: any;
    continue_callback: any;
}
interface State {
    encoding_result: encoding_result;
}

export class CreateNFTCollectionPopupConfirmation extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            encoding_result: blank_encoding_result,
        };
        const body = JSON.stringify({ collection: this.props.collection })
        api.EncodeNewNftCollection(body, (data: any) => { this.setState({ encoding_result: data }); })
    }
    //required for security, set pass to null

    Should_show(st: string): boolean {
        if (typeof (st) == 'string' && st.length > 0)
            return true;

        return false
    }

    close_info() {
        this.props.cancel_callback()
    }

    render() {
        if (!this.state.encoding_result.success) {
            return <InfoPopup title={'Error'} info={this.state.encoding_result.message} close_callback={this.props.cancel_callback} />
        }
        return (<Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.cancel_callback() }} >
                Please ensure the following information is correct

            </ModalHeader>
            <ModalBody>

                <dl className="dl-horizontal" >
                    <dt>Name :</dt><dd>{this.props.collection.collection_name}</dd>
                    <dt>Description :</dt><dd>{this.props.collection.collection_description}</dd>
                    <dt>Collection ID :</dt><dd>{this.props.collection.txid}<br /></dd>
                    <dt>Current Owner :</dt><dd>{
                        this.props.collection.owner != undefined ? this.props.collection.owner.username : this.props.collection.creator.username
                    }<br /></dd>
                    <dt>Creator :</dt> <dd>{this.props.collection.creator.username}<br /></dd>
                    <dt>Owner can transfer collection :</dt> <dd><TrueFalseIcon state={this.props.collection.collection_transferable} /></dd>
                    <dt>Owner can destroy collection :</dt> <dd><TrueFalseIcon state={this.props.collection.collection_can_owner_destroy} /></dd>
                    <dt>NFTs have identical names :</dt> <dd><TrueFalseIcon state={this.props.collection.nft_inherit_name} /></dd>
                    {this.props.collection.nft_inherit_name ?
                        <span>
                            <dt> NFT Name :</dt> <dd>{this.props.collection.nft_name === undefined ? this.props.collection.collection_name : this.props.collection.nft_name}<br /></dd>
                            <dt>NFT Description </dt> <dd>{this.props.collection.nft_description === undefined ? this.props.collection.collection_description : this.props.collection.nft_description}<br /></dd>
                        </span>
                        : null}
                    <dt> Creators can destroy NFTs :</dt> <dd><TrueFalseIcon state={this.props.collection.nft_can_creator_destroy} /></dd>
                    <dt> Owner can destroy NFTs :</dt> <dd><TrueFalseIcon state={this.props.collection.nft_can_owner_destroy} /></dd>
                    <dt> Owner can transfer NFTs :</dt> <dd><TrueFalseIcon state={this.props.collection.nft_can_owner_transfer} /></dd>

                </dl >

                <h4>The cost for this operation is {this.state.encoding_result.cost} Val. Are you sure?</h4>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => {
                        this.props.cancel_callback()
                    }}>Close</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => {
                        this.props.continue_callback(this.state.encoding_result)
                    }}>Create</button>
                </div>
            </ModalFooter>
        </Modal>

        );
    }
}