import * as React from 'react';
import { NFT, blank_NFT, NFT_command, blank_NFT_command, NFT_command_type } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as settings from "../../Global/settings"
import { DestroyNFTPopup } from "./DestroyNFTPopup"
import { TransferNFTPopup } from "./TransferNFTPopup"
import { SellNFTPopup } from "./SellNFTPopup "
import * as api from '../../Global/API'
import { UserInfoPopup } from '../Users/UserInfoPopup'
import * as functions from "../../Global/functions"
import img_noimage from '../../../Assets/noimage.png';
interface Props {
    nfttxid: string
    close_callback: any;
    nft: NFT
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    show_sell_nft: boolean
    show_transfer_nft: boolean
    show_destroy_nft: boolean

    show_user_info: boolean
    show_user_address: string

    pending_commands: NFT_command[];
}

export class NFTInfoPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
        nft: blank_NFT
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            info_title: "",
            info_body: "",
            show_info: false,
            //
            show_sell_nft: false,
            show_destroy_nft: false,
            show_transfer_nft: false,

            show_user_info: false,
            show_user_address: "",
            pending_commands: [],
        };
        this.load_pending_commands = this.load_pending_commands.bind(this);

        this.load_pending_commands();
    }

    load_pending_commands() {
        api.GetFilteredPendingObjectCommands(this.props.nfttxid, (data: NFT_command[]) => {
            if (data.length > 0) {
                if (this.timerid == 0)
                    this.timerid = setInterval(this.load_pending_commands.bind(this), 1000)
            }
            else {
                this.time_loop_count++;
                if (this.time_loop_count < 10)
                    return;
                clearInterval(this.timerid)
                this.time_loop_count = 0;
                this.timerid = 0;
            }

            this.setState({ pending_commands: data });
        })
    }

    timerid: any = 0;
    time_loop_count: number = 0;

    close() {
        this.props.close_callback(true)
    }
    close_transfer_nft() {
        this.setState({ show_transfer_nft: false })
    }
    close_destroy_nft() {
        this.setState({ show_destroy_nft: false })
    }

    open_sell_nft() {
        this.setState({ show_sell_nft: true })
    }
    close_sell_nft() {
        this.setState({ show_sell_nft: false })
        if (this.timerid == 0) {
            this.timerid = setInterval(this.load_pending_commands.bind(this), 1000)
            this.time_loop_count = 0;
        }
    }

    open_transfer_nft() {
        this.setState({ show_transfer_nft: true })
    }
    open_destroy_nft() {
        this.setState({ show_destroy_nft: true })
    }

    can_destroy() {
        // if base permission is false, then below operators are false.
        var creator_destroy_ok: boolean = this.props.nft.can_creator_destroy;
        var owner_destroy_ok: boolean = this.props.nft.can_owner_destroy;

        // check current identity against creator
        if (settings.current_identity.address != this.props.nft.creator.address) {
            creator_destroy_ok = false;
        }

        // check current identity agains owner
        if (this.props.nft.owner == undefined) {
            //owner can't destory if owner is undefined
            owner_destroy_ok = false;
        }
        else {
            //assuming owner is defined
            if (this.props.nft.owner.address != settings.current_identity.address) {
                owner_destroy_ok = false;
            }
        }

        if (!creator_destroy_ok && !owner_destroy_ok) {
            return false;
        }
        return true;
    }

    can_transfer() {
        // if base permission is false, then below operators are false.
        var can_transfer: boolean = this.props.nft.can_owner_transfer;

        // check current identity against creator
        if (settings.current_identity.address != this.props.nft.owner.address) {
            can_transfer = false;
        }

        return can_transfer;
    }

    show_Creator() {
        this.setState({ show_user_address: this.props.nft.creator.address, show_user_info: true })
    }

    show_Owner() {
        this.setState({ show_user_address: this.props.nft.owner.address, show_user_info: true })
    }

    openInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
    }

    select_content() {
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }
        if (this.state.show_user_info) {
            return <UserInfoPopup address={this.state.show_user_address} close_callback={() => { this.setState({ show_user_info: false }) }} />
        }

        var image;
        if (this.props.nft.immage == null)
            image = img_noimage;
        else
            image = "https://smartchain.validitytech.com/api/public/ipfsthumb/" + this.props.nft.immage;

        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >

                <div className="row">
                    <div className="col-md-4">
                        <img src={image} onDoubleClick={() => this.openInNewTab("https://smartchain.validitytech.com/api/public/ipfsfull/" + this.props.nft.immage)} alt={null} className="img-responsive img-thumbnail" />
                    </div>
                    <div className="col-md-8">
                        {this.props.nft.name}
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <dl className="dl-horizontal" >
                    <dt>Name :</dt><dd>{this.props.nft.name}</dd>
                    <dt>Description :</dt><dd>{this.props.nft.description}<br /></dd>
                    <dt>Parrent Class :</dt><dd>{this.props.nft.collection != undefined ? this.props.nft.collection.collection_name : null} <br /></dd>

                    <dt>Current Owner :</dt><dd>{
                        this.props.nft.owner != undefined ?
                            <button type="button" className="btn  mr-3 " onClick={this.show_Owner.bind(this)} >{this.props.nft.owner.username}</button> :
                            <button type="button" className="btn  mr-3 " onClick={this.show_Creator.bind(this)} >{this.props.nft.creator.username}</button>
                    }</dd>
                    <dt>Creator :</dt> <dd><button type="button" className="btn  mr-3 " onClick={this.show_Creator.bind(this)} >{this.props.nft.creator.username}</button></dd>
                    <dt>Can Owner Destroy :</dt> <dd><TrueFalseIcon state={this.props.nft.can_owner_destroy} /></dd>
                    <dt>Can Owner Transfer :</dt> <dd><TrueFalseIcon state={this.props.nft.can_owner_transfer} /></dd>
                    <dt>Can Creator Destroy :</dt> <dd><TrueFalseIcon state={this.props.nft.can_creator_destroy} /></dd>

                    {this.props.nft.custom_fields.map(custom =>
                        <span><dt className='fa fa-cogs' >{" " + custom.key} :</dt> <dd>{custom.value}</dd></span>

                    )}
                </dl >

                {this.state.pending_commands.length > 0 ?

                    <div className="btn-toolbar" role="group" aria-label="...">
                        This object has a pending {functions.getCommandTypeText(this.state.pending_commands[0])} action. Please wait untill the current action clears the mempool

                    </div>

                    :

                    <div className="btn-toolbar" role="group" aria-label="...">
                        {this.can_destroy() ? <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.open_destroy_nft.bind(this)} >Destroy NFT</button> : null}

                        {this.can_transfer() ? <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.open_transfer_nft.bind(this)} >Transfer NFT</button> : null}
                        {this.can_transfer() && this.props.nft.chain_lock == null ? <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.open_sell_nft.bind(this)} >List For Sale</button> : null}
                        {settings.current_identity.address == "" ? "Please loging to view NFT destroy/transfer options" : null}
                        {this.props.nft.chain_lock != null ? <div>NFT is for sale!</div> : null}
                    </div>
                }

            </ModalBody>
            <ModalFooter>

                <button type="button" className="btn btn-success mr-3 " onClick={this.close.bind(this)} >Close</button>

            </ModalFooter>
            {this.state.show_transfer_nft ?
                <TransferNFTPopup nft={this.props.nft} close_callback={this.close_transfer_nft.bind(this)} success_callback={this.close.bind(this)} />
                : null
            }

            {this.state.show_sell_nft ?
                <SellNFTPopup nft={this.props.nft} close_callback={this.close_sell_nft.bind(this)} success_callback={this.close.bind(this)} />
                : null
            }

            {this.state.show_destroy_nft ?
                <DestroyNFTPopup NFT={this.props.nft} close_callback={this.close_destroy_nft.bind(this)} success_callback={this.close.bind(this)} />
                : null
            }

        </Modal>
        )
    }

    render() {
        console.log(this.state)
        let content = this.select_content()

        return (content)
    }
}

interface result {
    hex: string
    cost: number
}