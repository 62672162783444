import * as React from 'react';
import * as settings from '../../Global/settings'

import { payments, networks, ECPair } from 'radiumjs-lib'

import * as ecc from 'tiny-secp256k1';
import { LoginPopup } from '../../MainPages/Login/LoginPopup'
import { CreateUserPopup } from '../../MainPages/Users/CreateUserPopup'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { CreateKeyPopup } from './CreateKeyPopup'
import { InfoPopup } from '../../Global/InfoPopup'
import * as api from '../../Global/API'
import { RedirectExplore } from '../RedirectExplore';
import { blank_user } from '../../MainPages/_Interfaces/iUser';
interface SendState {
    show_make_user: boolean,
    show_login: boolean,
    language: number,
    key: string,
    setDropdownOpen: boolean,

    show_info: boolean
    info_title: string;
    info_body: string;

    login_complete: boolean

    go_home: boolean;
}
interface Props {
}

export class Topright extends React.Component<Props, SendState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            show_make_user: false,
            show_login: false,
            language: 0,
            key: "",
            show_info: false,
            info_title: "",
            info_body: "",
            login_complete: false,
            setDropdownOpen: false,
            go_home: false,
        };
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value } as any);
    }

    make_key() {
        const keyPair = ECPair.makeRandom({ network: networks.radium })
        const address = payments.p2pkh({ pubkey: keyPair.publicKey })

        return keyPair.toWIF()
    }

    show_create_key() {
        this.setState({ show_make_user: true })
    }

    hide_create_key() {
        this.setState({ show_make_user: false })
    }

    show_login() {
        this.setState({ show_login: true })
    }

    hide_login() {
        this.setState({ show_login: false })
    }

    login() {
        try {
            settings.set_priv_key(this.state.key)
            const { address } = payments.p2pkh({ pubkey: settings.private_keypair.publicKey, network: networks.radium })

            api.GetUser(address as string, (data: any) => {
                if (data.address == null) {
                    settings.clear_current_identity()
                }
                else {
                    settings.set_current_identity(data)
                    settings.set_priv_key(this.state.key)
                }

                this.setState({ key: "" });

                if (data.address == null) { this.show_incomplete_user() } else { this.show_welcome_user(data.username) }
                this.setState({ login_complete: true })
            })
        }
        catch (ex) {
            this.setState({ show_info: true, info_title: "Error ", info_body: "Invalid Validity Key" })
        }
    }

    show_welcome_user(username: string) {
        this.setState({ show_info: true, info_title: "Welcome back, " + username })
    }
    show_incomplete_user() {
        this.setState({ show_info: true, info_title: "Welcome Unknown User ", info_body: "Please complete the user registration under the My Profile tab" })
    }

    close_info() {
        this.setState({ show_info: false })
    }

    toggle() {
        this.setState({ setDropdownOpen: !this.state.setDropdownOpen })
    }

    log_out() {
        settings.clear_current_identity();

        this.setState({ setDropdownOpen: false, go_home: true })
    }

    getcontent() {
        if (settings.current_identity.address !== "")
            return <div className="row  toprightmenue">
                <div className="collapse navbar-collapse toprightmenue" id="navbarNav">
                    <ul className="nav navbar-nav navbar-justify">
                        <li>
                            {settings.current_identity.profile_immage != "" ? <div className='nav-link div-link' data-target='.navbar-collapse' >
                                <span ><img src={"https://smartchain.validitytech.com/api/public/ipfsfull/" + settings.current_identity.profile_immage} className="img-responsive img-thumbnail toprightmenue_img " /></span>
                            </div> : null}

                        </li>
                        <li>
                            <div className='nav-link div-link' data-target='.navbar-collapse' >
                                <span> {settings.current_identity.username}</span>
                            </div>
                        </li>

                        <li>
                            <div onClick={this.log_out.bind(this)} className='nav-link div-link' data-target='.navbar-collapse' >
                                <span> Log Out</span>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

        if (settings.current_identity.address == "")
            return <div className="row  toprightmenue" >
                <div className="collapse navbar-collapse toprightmenue" id="navbarNav">
                    <ul className="nav navbar-nav navbar-justify">
                        <li>
                            <div onClick={() => { this.setState({ show_make_user: true }) }} className='nav-link div-link' data-target='.navbar-collapse' >
                                <span className=' fa fa-pencil-square-o'>Register</span>
                            </div>
                        </li>
                        <li>
                            <div onClick={() => { this.setState({ show_login: true }) }} className='nav-link div-link' data-target='.navbar-collapse' >
                                <span className=' fa fa-exchange'> Login</span>
                            </div>
                        </li>

                    </ul>
                </div>

                {this.state.show_make_user ? <CreateUserPopup close_callback={this.hide_create_key.bind(this)} /> : null}

                {this.state.show_info ? <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={this.close_info.bind(this)} /> : null}
                {this.state.show_login ? <LoginPopup close_callback={() => { this.hide_login() }} /> : null}
            </div>
    }

    public render() {
        return this.getcontent()
    }
}