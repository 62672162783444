import * as React from 'react';
import { NFTClassTable } from '../NFTCollections/NFTCollectionTable';
interface State {
    loading: boolean;

    mobile: boolean;
}

export class AllNFTClasses extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            loading: false,

            mobile: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    get_content() {
        return <NFTClassTable mobile={this.state.mobile} title={"All NFT Collections"} defaultPageSize={-1} showPagination={true} />
    }

    public render() {
        let content = this.get_content()
        return content;
    }
}