import * as React from 'react'; import ReactTable from 'react-table';
import { NFT, blank_NFT, NFT_command, blank_NFT_command, NFT_command_type } from '../_Interfaces/NFT'
import { utxo, blank_utxo, get_utxo_result, blank_utxo_result } from '../_Interfaces/iUTXO'

import { InfoPopup } from '../../Global/InfoPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as settings from "../../Global/settings"
import { CancelSalePopup } from "./CancelSalePopup"
import * as api from '../../Global/API'

import spinner from '../../../Assets/spinner.gif';

interface Props {
    close_callback: any;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    nfts: NFT[]

    show_cancel_sale: boolean
    nft_to_cancel: NFT
    loading: boolean
}

export class MyForSalePopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            nfts: [],
            show_cancel_sale: false,
            nft_to_cancel: blank_NFT,
            loading: true,
        };
        this.load_data();
    }

    time_loop_count: number = 0;
    timerid: any = 0;

    load_data() {
        const body = JSON.stringify({
            locking_only: "",
            owner: settings.current_identity.address,
        })

        api.FilterNft(body, (nft_data: NFT[]) => {
            var pending: boolean = false;
            var has_change: boolean = nft_data.length != this.state.nfts.length;

            for (let i = 0; i < nft_data.length; i++) {
                if (nft_data[i].pending_action != null) {
                    pending = true;
                }
                if (!has_change) {
                    if (nft_data[i].txid != this.state.nfts[i].txid) {
                        has_change = true;
                    }
                    if (nft_data[i].pending_action != this.state.nfts[i].pending_action) {
                        has_change = true;
                    }
                }
            }

            if (!pending) {
                this.time_loop_count++;
                if (this.time_loop_count > 10) {
                    clearInterval(this.timerid);
                    this.timerid = 0;
                    this.time_loop_count = 0
                }
            }
            if (nft_data.length == 0) {
                clearInterval(this.timerid);
                this.timerid = 0;
            }

            if (this.state.nfts.length != nft_data.length) {
                this.setState({ nfts: nft_data, loading: false });
                return;
            }

            if (has_change) {
                this.setState({ nfts: nft_data, loading: false });
            }
        })
    }

    show_cancel_sale(nft: NFT) {
        this.setState({ show_cancel_sale: true, nft_to_cancel: nft })
        this.load_data();
    }

    close_cancel_offer() {
        this.setState({ show_cancel_sale: false })

        this.load_data();

        this.time_loop_count = 0;
        //start the load data timer.
        if (this.timerid == 0) {
            this.timerid = setInterval(this.load_data.bind(this), 1000);
        }
    }

    public render() {
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        const data = this.state.nfts;
        const columns = [
            {
                Header: "NFT",
                accessor: 'name',
            },
            {
                Cell: (row: any) => (
                    <button type="button" className="btn  btn-success mr-3 " onClick={() => { this.show_cancel_sale(row.original) }}>Cancel Sale</button>)
            },
            {
                Header: "action",
                accessor: "pending_action",
                style: {
                    "text-align": 'right'
                },
                Cell: (row: any) => (
                    <span className={"text-right"}>
                        {row.value == null ?
                            <button type="button" className="btn text-right btn-success mr-3 " onClick={() => { this.show_cancel_sale(row.original) }}>Cancel Sale</button>
                            : row.value == "cancel" ? <span className="mr-3 "> Sale Cancelation Pending <img
                                src={spinner}
                                style={{ width: '45px' }}
                                alt='Loading...'
                            />  </span> : null}
                    </span>
                )
            },

        ]

        return <Modal size="lg" backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >

                <h1>Your NFT's that are for sale</h1>

            </ModalHeader>
            <ModalBody>

                < ReactTable
                    data={data}
                    columns={columns}

                    className="-highlight"
                    defaultPageSize={5} minRows={5}
                    defaultSorted={[{ id: "unix_time", desc: true }]}

                />
                {this.state.show_cancel_sale ?
                    <CancelSalePopup nft={this.state.nft_to_cancel} close_callback={this.close_cancel_offer.bind(this)} />
                    : null
                }

            </ModalBody>
            <ModalFooter>

            </ModalFooter>

        </Modal>
    }
}