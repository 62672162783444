import * as React from 'react';
import { UserTable } from '../Users/UserTable';
import * as Settings from '../../Global/settings'
interface UsersState {
    loading: boolean;
    SmartChain_Synced: boolean;
    mobile: boolean;
}

export class AllUsers extends React.Component<{}, UsersState> {
    constructor(props: {}) {
        super(props);
        this.state = { loading: false, SmartChain_Synced: false, mobile: false };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    synced_callback() {
        this.setState({ SmartChain_Synced: true })
    }

    get_content() {
        return <UserTable mobile={this.state.mobile} defaultPageSize={-1} showPagination={true} />
    }

    public render() {
        let content = this.get_content()
        return content;
    }
}