import * as React from 'react';
import { NavLink } from 'react-router-dom';
import * as settings from './Global/settings';
import * as api from './Global/API'
import image from '../Assets/Validity-512.png';
import { Block } from './MainPages/_Interfaces/iBLock'
interface NavMenuState {
    user: string;
    webonly: boolean
    mobile: boolean
    intervaltick: any
    top_net_hash: string
    top_sc_hash: string
    user_address: string
}

interface Props {
}

export class NavMenu extends React.Component<Props, NavMenuState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            user: settings.current_identity.username,
            webonly: true,
            mobile: false,
            intervaltick: 5000,
            top_net_hash: "",
            top_sc_hash: "",
            user_address: "",
        };

        this.tick();
    }

    tick() {
        api.gettopblock((data: Block) => {
            if (settings.top_block.hash != data.hash || settings.top_block.sc_top != data.sc_top) {
                settings.set_network_block(data)
                console.log("block info updated");
                console.log("net " + settings.top_block.hash);
                console.log("sc " + settings.top_block.sc_top);
            }
        })
        if (settings.current_identity.address != this.state.user_address)
            this.setState({ user_address: settings.current_identity.address })
    }

    componentWillUnmount() {
        settings.clear_current_identity();
    }
    componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this))
        var inttick = setInterval(() => this.tick(), 5000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervaltick: inttick });
    }
    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    log_out() {
        settings.clear_current_identity();
        this.setState({ user: this.state.user });
    }

    get_button_text() {
        if (settings.current_identity.address === "") {
            return "Login Required"
        }
        if (settings.current_identity.address !== "") {
            if (settings.current_identity.username === "") {
                return "Unregisterd User"
            }
        }
        return settings.current_identity.username
    }

    public render() {
        return <div className='main-nav navbar '>
            <img src={image} alt="error" className="img-fluid image text-center " />
            <ul className='navbar-nav'>

                {settings.current_identity.address !== "" ?
                    <li>
                        <NavLink to={'/home'} className='nav-link' >
                            <span className='fa fa-home'></span> Home
                        </NavLink>
                    </li>
                    : null}

                {settings.current_identity.address !== "" ?
                    <li>
                        <NavLink to={'/myprofile'} className='nav-link' >
                            <span className='fa fa-user'></span> My Profile
                        </NavLink>
                    </li>
                    : null}

                {settings.current_identity.address !== "" ?
                    <li>
                        <NavLink to={'/signedfiles'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                            <span className='fa fa-file'></span> Signed Files
                        </NavLink>
                    </li>
                    : null}

                {settings.current_identity.address !== "" ?
                    <li>
                        <NavLink to={'/Elections'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                            <span className='fa fa-check-square'></span> Elections
                        </NavLink>
                    </li>
                    : null}

                {settings.current_identity.address !== "" ?
                    <li>
                        <NavLink to={'/nft'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                            <span className='fa fa-tag'></span> NFTs
                        </NavLink>
                    </li>
                    : null}

                {settings.current_identity.address !== "" ?
                    <li>
                        <NavLink to={'/nftcollections'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                            <span className='fa fa-tags'></span> NFT Collections
                        </NavLink>
                    </li>
                    : null}

                {false ? <li>
                    <NavLink to={'/send'} className='nav-link' >
                        <span className='fa fa-paper-plane'></span> Send
                    </NavLink>
                </li> : null}

                {false ? <li>
                    <NavLink to={'/Receive'} className='nav-link' >
                        <span className='fa fa-inbox'></span> Receive
                    </NavLink>
                </li> : null}

                {false ? <li>
                    <NavLink to={'/transactions'} className='nav-link' >
                        <span className='fa fa-exchange'></span> Transactions
                    </NavLink>
                </li> : null}

                <li>
                    <NavLink to={'/market'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                        <span className='fa fa-comments-o'></span> Marketplace
                    </NavLink>
                </li>

                <li>
                    <NavLink to={'/explore'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                        <span className='fa fa-comments-o'></span> Explore SmartChain
                    </NavLink>
                </li>

                <li>
                    <NavLink to={'/messages'} className='nav-link' data-toggle={this.state.mobile ? 'collapse' : null} data-target='.navbar-collapse' >
                        <span className='fa fa-comments-o'></span> Signed Messages
                    </NavLink>
                </li>

                {false ? <li>
                    <NavLink to={'/tools'} className='nav-link'    >
                        <span className='fa fa-wrench'></span> Tools
                    </NavLink>
                </li> : null}

            </ul>

        </div>;
    }
}