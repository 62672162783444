import * as React from 'react';

import * as Settings from './settings'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
interface Props {
    close_callback: any;
    data_callback: any;

    show_me: boolean;

    title: string;
    info: string;
}
interface GetInputPopupState {
    data: string;
}

export class GetInputPopup extends React.Component<Props, GetInputPopupState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            data: ""
        };
    }
    //required for security, set pass to null

    return_data() {
        this.props.data_callback(this.state.data)
    }

    close() {
        this.props.close_callback()
    }

    key_up(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            this.return_data()
        }
    }

    render() {
        return <Modal isOpen={this.props.show_me} onHide={this.props.close_callback}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                {this.props.title}

            </ModalHeader>
            <ModalBody>
                {this.props.info}
                <div> <input type="text" className="form-control" onKeyPress={evt => this.key_up(evt)} onChange={evt => this.setState({ data: evt.target.value })} /></div>

            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => { this.return_data() }} >{Settings.language.Continue}</button>
                <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.close() }}>{Settings.language.Cancel}</button>
            </ModalFooter>
        </Modal>
    }
}