import * as React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { User, blank_user } from '../_Interfaces/iUser'
import { UnixToDate } from '../../Global/UnixToDate'
import { SignedMesage, blank_SignedMesage } from '../_Interfaces/iSigning'

import * as settings from '../../Global/settings'
import { Block, blank_block } from '../_Interfaces/iBLock'
import * as api from '../../Global/API'
interface Props {
    close_callback: any;

    show_popup: boolean;

    message: SignedMesage;
}
interface InfoPopupState {
    user: User
    time: number
    loading: number
}

export class VerifiedMessagePopup extends React.Component<Props, InfoPopupState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            user: blank_user,
            time: 0,
            loading: 0
        };

        api.GetBlockByHash(this.props.message.blockhash, (data: any) => { this.setState({ time: data.time, loading: this.state.loading + 1 }) })
        api.GetUser(this.props.message.address, (data: any) => { this.setState({ user: data, loading: this.state.loading + 1 }); })
    }
    //required for security, set pass to null

    close() {
        this.props.close_callback()
    }

    render() {
        if (this.state.loading < 2) {
            return <Modal isOpen={this.props.show_popup} onHide={() => { }}>
                <ModalHeader toggle={() => { this.props.close_callback() }} >
                    Loading

                </ModalHeader>
                <ModalBody>
                    Please Wait!
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.close.bind(this)}>{settings.language.Continue}</button>
                </ModalFooter>
            </Modal>
        }
        else {
            return <Modal size={"lg"} isOpen={this.props.show_popup} onHide={() => { }}>
                <ModalHeader toggle={() => { this.props.close_callback() }} >
                    Verified Message

                </ModalHeader>
                <ModalBody>
                    <dl className="dl-horizontal">
                        <dt>Message From</dt><dd>{this.state.user.username}</dd>
                        <dt>Address</dt><dd>{this.state.user.address}</dd>
                        <dt>Signed on or after</dt><dd><UnixToDate unix={this.props.message.time} /></dd>
                        <dt>Message</dt><dd>{this.props.message.message}</dd>
                    </dl>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.close.bind(this)}>{settings.language.Continue}</button>
                </ModalFooter>
            </Modal>
        }
    }
}