import * as React from 'react';
import * as api from '../../Global/API'
import { NFT_command, NFTCollection } from '../_Interfaces/NFT'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { InfoPopup } from '../../Global/InfoPopup'

import { encoding_result, blank_encoding_result } from '../_Interfaces/iResult'
interface Props {
    Collection: NFTCollection
    command: NFT_command

    cancel_callback: any;
    continue_callback: any;
}
interface State {
    encoding_result: encoding_result;
}
export class TransferNFTCollectionPopupConfirmation extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            encoding_result: blank_encoding_result
        };

        const body = JSON.stringify({
            command: this.props.command,
        })
        api.EncodeNewNftCommand(body, (data: any) => { this.setState({ encoding_result: data }); })
    }
    //required for security, set pass to null

    Should_show(st: string): boolean {
        if (typeof (st) == 'string' && st.length > 0)
            return true;

        return false
    }

    close_info() {
        this.props.cancel_callback()
    }

    render() {
        if (!this.state.encoding_result.success) {
            return <InfoPopup title={'Error'} info={this.state.encoding_result.message} close_callback={this.props.cancel_callback} />
        }
        return (<Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.cancel_callback() }} >
                Please ensure the following information is correct

            </ModalHeader>
            <ModalBody>

                <span>Transfer NFT</span>
                <dl className="dl-horizontal">
                    <dt>Class Name :</dt> <dd>{this.props.Collection.collection_name}</dd>
                    <dt>Class Action:</dt> <dd>Transfer</dd>
                    <dt>Destination:</dt> <dd>{this.props.command.destination!.username}</dd>

                </dl>

                <span></span>

                <h4>The cost for this operation is {this.state.encoding_result.cost} Val. Are you sure?</h4>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => {
                        this.props.cancel_callback()
                    }}>Close</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => {
                        this.props.continue_callback(this.state.encoding_result)
                    }}>Transfer</button>
                </div>
            </ModalFooter>
        </Modal>

        );
    }
}