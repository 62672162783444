import * as React from 'react';
export interface encoding_result {
    success: boolean
    message: string
    hex: string
    cost: number
}

export const blank_encoding_result: encoding_result = {
    success: false,
    message: "",
    hex: "",
    cost: 0,
}

export interface SendResult {
    success: boolean
    message: string
}

export const blank_send_result: SendResult = {
    success: false,
    message: "",
}