import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as api from '../../Global/API'
import { UnixToDate } from '../../Global/UnixToDate'
import { iFileHash, blank_hash } from '../_Interfaces/iFileHash'
interface Props {
    close_callback: any;

    hash: string;
}
interface state {
    title: string;
    result: iFileHash;
    loading: boolean;
    username: string;
}

export class VerifyFileResult extends React.Component<Props, state>{
    constructor(props: Props) {
        super(props);
        this.state = {
            title: "",
            result: blank_hash,
            loading: true,
            username: ""
        };
        api.GetFileHash(this.props.hash, (data: any) => { this.setState({ result: data, loading: false }); })
    }

    back() {
        this.props.close_callback()
    }

    get_content() {
        if (this.state.loading) {
            setTimeout(() => { this.get_content(); }, 50);
        }
        if (this.state.result.hash == "") {
            return this.render_fail()
        }
        else { return this.render_success() }
    }

    render_fail() {
        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                <h4>Unknown File! Procede with caution </h4>

            </ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.back.bind(this)}>Back</button>

            </ModalFooter>
        </Modal>
    }
    render_success() {
        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                <h4> Known File! </h4>
                <h3>Ensure file is signed by an identity you trust </h3>

            </ModalHeader>
            <ModalBody>
                <dl className="dl-horizontal">
                    <dt>Title :</dt> <dd>{this.state.result.title}</dd>
                    <dt>File Hash :</dt> <dd>{this.state.result.hash}</dd>
                    <dt>Signing Identity:</dt> <dd>{this.state.result.username}</dd>
                    <dt>Signing Address:</dt> <dd>{this.state.result.creator}</dd>
                    <dt>Signing Date:</dt> <dd><UnixToDate unix={this.state.result.unixtime} /></dd>
                    <dt>Signing Txid:</dt> <dd> {this.state.result.txid} </dd>
                </dl></ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.back.bind(this)}>Back</button>

            </ModalFooter>
        </Modal>
    }

    render() {
        let content = this.get_content()
        return content
    }
}