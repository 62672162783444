import * as React from 'react';
import { MarketTable } from './MarketTable';
import * as Settings from '../../Global/settings'
import { MarketMenu } from './MarketMenu';
interface NFTState {
    mobile: boolean;
}

export interface LoadingProps {
}
export class Marketplace extends React.Component<LoadingProps, NFTState> {
    constructor(props: LoadingProps) {
        super(props);
        this.state = {
            mobile: false,
        };
    }
    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    public render() {
        return (<span>
            <MarketMenu />

            <MarketTable mobile={this.state.mobile} title={"Market"} address={Settings.current_identity.address} defaultPageSize={-1} showPagination={true} />

        </span>
        );
    }
}