import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { InfoPopup } from '../../Global/InfoPopup'
import * as settings from "../../Global/settings"
import { CopyButton } from '../../Global/CopyButton'

import { payments, networks, ECPair } from 'radiumjs-lib'
import * as ecc from 'tiny-secp256k1';
import { verify } from 'crypto';

interface Props {
    close_callback: any;
    return_key_callback?: any;
}
interface state {
    show_warning: boolean
    show_key: boolean
    show_verify_key: boolean
    key: string
    test_key: string
    show_info: boolean
}
export class CreateKeyPopup extends React.Component<Props, state>{
    constructor(props: Props) {
        super(props);
        this.state = {
            show_warning: true,
            show_key: false,
            show_verify_key: false,
            key: "",
            test_key: "",
            show_info: false,
        };
    }
    //required for security, set pass to null

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value } as any);
    }

    show_make_key() {
        this.setState({ show_key: true })
    }
    close() {
        this.props.close_callback()
    }

    make_key(): string {
        const keyPair = ECPair.makeRandom({ network: networks.radium })

        var _key = keyPair.toWIF()
        this.setState({ key: _key })
        return _key;
    }
    select_content() {
        if (this.state.show_info)
            return <InfoPopup title={"Error"} info={"Key does not match"} close_callback={() => { this.setState({ show_info: false, show_verify_key: false, show_key: false, show_warning: true }) }} />

        if (this.state.show_warning)
            return this.return_warning();
        if (this.state.show_key)
            return this.return_key();
        if (this.state.show_verify_key)
            return this.return_verify_key()
    }

    check_key() {
        console.log(this.state.key);
        console.log(this.state.test_key);

        if (this.state.key != this.state.test_key) {
            this.setState({ show_info: true });
            return;
        }
        if (this.props.return_key_callback) { this.props.return_key_callback(this.state.key); }
    }

    return_warning() {
        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.close() }} >
            <ModalHeader toggle={() => { this.close() }} >Private Key Generation</ModalHeader>
            <ModalBody>
                <h5> The key you are about to generate grants control over your account.
                    Do not share the key with anyone, ever. Account keys cannot be changed, replaced, or reset.
                    If you lose your key, you lose access to your account. Nobody can recover your key if you lose it.</h5>
                <br />
                <h5> After your account is created, use this key to login by clicking the login button at the top right of the screen. </h5>
                <br />
                <h5>Please be aware, it can take up to several minutes for your account to be confirmed on the network.</h5>
            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => { this.setState({ show_warning: false, show_key: true }) }}>Create</button>
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.close.bind(this)}>Close</button>
                </div>
            </ModalFooter>
        </Modal>
        )
    }

    return_key() {
        if (this.state.key == "")
            this.make_key()

        return (<Modal backdrop={"static"} isOpen={true} size="lg" toggle={() => { this.close() }} >
            <ModalHeader toggle={() => { this.close() }} >Below is your key. It will never be shown again.  Write it down or save it somewhere safe. Keep it handy for the next step.

            </ModalHeader>
            <ModalBody>
                <h2></h2>
                <h5> {this.state.key} </h5>
                <h4>  </h4>

            </ModalBody>
            <ModalFooter>

                <button type="button" className="btn btn-danger" onClick={() => { this.setState({ show_key: false, show_verify_key: true }) }}>Continue</button>
            </ModalFooter>
        </Modal>
        )
    }

    return_verify_key() {
        return (<Modal backdrop={"static"} isOpen={true} size="lg" toggle={this.props.close_callback} >
            <ModalHeader toggle={() => { this.props.close_callback() }} >Please re-enter your key below and click 'verify'.

            </ModalHeader>
            <ModalBody>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Key:</span>
                    </div>
                    <input type="text" className="form-control" placeholder="key" aria-describedby="basic-addon1" name="description" onChange={e => {
                        this.setState({ test_key: e.target.value })
                    }} ></input>
                </div>

            </ModalBody>
            <ModalFooter>

                <button type="button" className="btn btn-danger" onClick={() => { this.check_key() }}>Verify</button>
            </ModalFooter>
        </Modal>)
    }

    render() {
        let content = this.select_content()

        return (content)
    }
}

interface iText {
    text: string;
}

interface result {
    hex: string
    cost: number
}