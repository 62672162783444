import * as React from 'react';
import t from '../../Language/Language'
import { Offer } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
import * as api from '../../Global/API'
import img_noimage from '../../../Assets/noimage.png';
import img_loading from '../../../Assets/ipfsloading.png';
import { TxStatus } from '../_Interfaces/iSmartTx';
interface Props {
    offer: Offer
    close_callback: any;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean
    encoding_result: encoding_result
    send_result: SendResult;
    send_complete: boolean;
    loading: boolean
}

export class CancelOfferPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            encoding_result: blank_encoding_result,

            send_result: blank_send_result,
            //
            loading: true,

            send_complete: false,
            //
        };

        const body = JSON.stringify({
            transaction: this.props.offer.transaction,
        })

        api.GetCancelOfferTx(body, (data: any) => {
            this.setState({ encoding_result: data, loading: false });
        })
    }

    validate() {
        api.send_raw(this.state.encoding_result.hex, (data: SendResult) => {
            this.setState({ send_result: data, send_complete: true })
            if (data.success == false)
                this.setState({ info_title: "error", info_body: data.message, show_info: true })
            else {
                this.setState({ info_title: "success", info_body: "Cancel offer transaction has been sent! TXID: " + data.message, show_info: true })
            }
        })
    }

    popup_close() {
        this.props.close_callback();
    }

    render() {
        if (this.state.loading) {
            return <InfoPopup title={"Loading"} info={"Running safety checks on selected offer"} close_callback={() => { this.props.close_callback() }} />
        }

        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={this.popup_close.bind(this)} />
        }

        var image;

        if (this.props.offer.NFT.status != null && this.props.offer.NFT.status.status == TxStatus.loading)
            image = img_loading;
        else if (this.props.offer.NFT.immage == null)
            image = img_noimage;
        else
            image = "https://smartchain.validitytech.com/api/public/ipfsthumb/" + this.props.offer.NFT.immage;

        var rarity: string = 'normal';

        if (this.props.offer.NFT.custom_fields != null) {
            this.props.offer.NFT.custom_fields.map(custom => {
                if (custom.key == "rarity") {
                    switch (custom.value) {
                        case "rare":

                            rarity = 'rare';

                            break;
                        case "uncommon":

                            rarity = 'uncommon';

                            break;
                        default:
                    }
                }
            })
        }

        return (<Modal size="lg" backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >

                <div className='row  text-center'>

                    <div className=' p-1  col-md-5'>

                        <img src={image} alt={""} height="100%" width="100%" className=" nfttilecontent img-responsive img-thumbnail" />
                        <div className='row  text-center'>
                            <div className='col-sm-7 text-left'>
                                <div className=' text-ellipsis' ><strong>{this.props.offer.NFT.collection.collection_name}</strong></div>
                            </div>
                            <div className='col-sm-5'>
                                <div className='text-right' id="rarityColor" >{rarity}</div>
                            </div>
                        </div>
                        <div className='row  text-center'>
                            <div className='col-md-12 text-left'>
                                <div className=' text-ellipsis' ><em>{this.props.offer.NFT.name}</em></div>
                            </div>
                        </div>

                    </div>
                    <div className=' p-1  col-md-7'>
                        <h3>Offer Details</h3>
                        <p />
                        <dl className="dl-horizontal">
                            <dt>Buyer</dt> <dd>{this.props.offer.Buyer.username}</dd>
                            <dt>Amount</dt> <dd>+{this.props.offer.amount} Val<br /></dd>
                            <dt>Fees</dt> <dd>-{this.props.offer.NFT.chain_lock.value} Val<br /></dd>
                            <dt>Sale Total</dt> <dd>+{this.props.offer.amount - this.props.offer.NFT.chain_lock.value} Val<br /></dd>

                        </dl>
                    </div>
                </div>

            </ModalHeader>
            <ModalBody>

                <p />

                <h5>You are about to cancel this offer to buy ""{this.props.offer.NFT.name}" For {this.props.offer.amount} Val.</h5>
                <h5> You can always open another offer at any time!  </h5>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Go Back</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" disabled={this.state.send_complete} onClick={this.validate.bind(this)}>Cancel Offer</button>
                </div>
            </ModalFooter>

        </Modal>
        )
    }
}