import * as React from 'react';
import { HashFileComponent } from './HashFileComponent'
import { SignFileConfirmationComponent } from './SignFileConfirmationComponent'
import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'
import { User } from '../_Interfaces/iUser'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { InfoPopup } from '../../Global/InfoPopup'
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
import * as settings from '../../Global/settings'
interface Props {
    close_callback: any;
}
interface SignFilePopupState {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean

    file_title: string;
    file_hash: string;
    fee: number;
}

export class SignFilePopup extends React.Component<Props, SignFilePopupState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            show_result: false,

            file_title: "",
            file_hash: "",
            fee: 0,
        };
    }

    got_hash(hash: string) {
        this.setState({ file_hash: hash })
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value } as any)
    }

    close() {
        this.props.close_callback(true)
    }

    validate() {
        if (this.state.file_hash == "" || this.state.file_hash == null) {
            this.setState({ info_title: "Data Error", info_body: "You must select a file", show_info: true })
            return;
        }

        this.setState({ show_confirmation: true })
    }
    close_info() {
        this.setState({ show_info: false })
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    results_close() {
        this.setState({ show_result: false })
    }

    popup_close() {
        this.props.close_callback();
    }

    render() {
        if (this.state.show_confirmation) {
            return <SignFileConfirmationComponent title={this.state.file_title} hash={this.state.file_hash} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }

        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Sign a File

            </ModalHeader>
            <ModalBody>
                <HashFileComponent complete_callback={this.got_hash.bind(this)} />
                <span> Describe the file (optional)</span>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">Title:</span>
                    <input type="text" className="form-control" placeholder="Title" maxLength={60} aria-describedby="basic-addon1" name="file_title" value={this.state.file_title} onChange={evt => this.handleInputChange(evt)}></input>

                </div>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Close</button>
                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>{settings.language.Continue}</button>

                </div>

            </ModalFooter>
        </Modal>
    }
}

interface result {
    hex: string
    cost: number
}