import * as React from 'react';
import { NFTCollection, blank_NFTCollection } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import * as Settings from '../../Global/settings'
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { GetInputPopup } from '../../Global/GetInputPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { User, blank_user } from "../_Interfaces/iUser"
import { SelectUser } from "../../Global/SelectUser"
import * as settings from "../../Global/settings"
import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'
import { CreateNFTinCollectionPopup } from "./CreateNFTinCollectionPopup"
import * as api from '../../Global/API'
import { TransferAssetClassPopup } from './TransferNFTCollectionPopup';
import { DestroyNFTCollectionPopup } from './DestroyNFTCollectionPopup';

interface Props {
    txid: string
    close_callback: any;

    address?: string;
}
interface State {
    NFTCollection: NFTCollection

    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    show_transfer_NFT_collection: boolean
    show_destroy_NFT_collection: boolean
    show_create_NFT: boolean

    load_complete: boolean
}

export class NFTCollectionInfoPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
        address: ""
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            info_title: "",
            info_body: "",
            show_info: false,
            //
            NFTCollection: blank_NFTCollection,
            show_destroy_NFT_collection: false,
            show_transfer_NFT_collection: false,
            show_create_NFT: false,

            load_complete: false,
        };
        api.GetNFT(this.props.txid, (data: any) => { this.setState({ NFTCollection: data, load_complete: true }); })
    }

    open_create_NFT() {
        this.setState({ show_create_NFT: true })
    }
    close_create_NFT() {
        this.setState({ show_create_NFT: false })
    }

    open_transfer_NFT_collection() {
        this.setState({ show_transfer_NFT_collection: true })
    }
    close_transfer_NFT_collection() {
        this.setState({ show_transfer_NFT_collection: false })
    }

    open_destroy_NFT_collection() {
        this.setState({ show_destroy_NFT_collection: true })
    }
    close_destroy_NFT_collection() {
        this.setState({ show_destroy_NFT_collection: false })
    }

    close() {
        this.props.close_callback(true)
    }

    can_destroy() {
        // check current identity agains owner
        if (this.state.NFTCollection.owner == undefined) {
            //owner can't destory if owner is undefined
            return false;
        }
        else {
            //assuming owner is defined
            if (this.state.NFTCollection.owner.address != settings.current_identity.address) {
                return false;
            }
        }

        return this.state.NFTCollection.nft_can_owner_destroy
    }

    can_transfer() {
        // if base permission is false, then below operators are false.
        var can_transfer: boolean = this.state.NFTCollection.collection_transferable;

        // check current identity against creator
        if (settings.current_identity.address != this.state.NFTCollection.owner.address) {
            can_transfer = false;
        }

        return can_transfer;
    }

    select_content() {
        if (this.state.show_create_NFT) {
            return <CreateNFTinCollectionPopup collection={this.state.NFTCollection} close_callback={this.close_create_NFT.bind(this)} />
        }

        if (this.state.show_transfer_NFT_collection) {
            return <TransferAssetClassPopup Collection={this.state.NFTCollection} close_callback={this.close_transfer_NFT_collection.bind(this)} success_callback={this.close.bind(this)} />
        }

        if (this.state.show_destroy_NFT_collection) {
            return <DestroyNFTCollectionPopup collection={this.state.NFTCollection} close_callback={this.close_destroy_NFT_collection.bind(this)} success_callback={this.close.bind(this)} />
        }

        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                NFT Collection Information

            </ModalHeader>
            <ModalBody>
                <dl className="dl-horizontal" >
                    <dt>Name :</dt><dd>{this.state.NFTCollection.collection_name}</dd>
                    <dt>Description :</dt><dd>{this.state.NFTCollection.collection_description}</dd>
                    <dt>Collection ID :</dt><dd>{this.state.NFTCollection.txid}</dd>
                    <dt>Current Owner :</dt><dd>{
                        this.state.NFTCollection.owner != undefined ? this.state.NFTCollection.owner.username : this.state.NFTCollection.creator.username
                    }</dd>
                    <dt>Creator :</dt> <dd>{this.state.NFTCollection.creator.username}</dd>
                    <dt>Owner can transfer collection :</dt> <dd><TrueFalseIcon state={this.state.NFTCollection.collection_transferable} /></dd>
                    <dt>Owner can destroy collection :</dt> <dd><TrueFalseIcon state={this.state.NFTCollection.collection_can_owner_destroy} /></dd>
                    <dt>NFTs have identical names :</dt> <dd><TrueFalseIcon state={this.state.NFTCollection.nft_inherit_name} /></dd>
                    {this.state.NFTCollection.nft_inherit_name ?
                        <span>
                            <dt> NFT Name :</dt> <dd>{this.state.NFTCollection.nft_name === undefined ? this.state.NFTCollection.nft_name : this.state.NFTCollection.nft_name}<br /></dd>
                            <dt>NFT Description </dt> <dd>{this.state.NFTCollection.nft_description === undefined ? this.state.NFTCollection.collection_description : this.state.NFTCollection.nft_description}<br /></dd>
                        </span>
                        : null}
                    <dt> Creators can destroy NFTs :</dt> <dd><TrueFalseIcon state={this.state.NFTCollection.nft_can_creator_destroy} /></dd>
                    <dt> Owner can destroy NFTs :</dt> <dd><TrueFalseIcon state={this.state.NFTCollection.nft_can_owner_destroy} /></dd>
                    <dt> Owner can transfer NFTs :</dt> <dd><TrueFalseIcon state={this.state.NFTCollection.nft_can_owner_transfer} /></dd>

                </dl >

            </ModalBody>
            <ModalFooter>

                <div className="btn-toolbar" role="group" aria-label="...">
                    {this.can_destroy() ? <button type="button" className="btn btn-success mr-3 btn-danger" onClick={this.open_destroy_NFT_collection.bind(this)} >Destroy NFT Collection</button> : null}

                    {this.can_transfer() ? <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.open_transfer_NFT_collection.bind(this)} >Transfer NFT Collection</button> : null}
                    {settings.current_identity.address == "" ? "Please loging to view NFT destroy/transfer options" : null}
                    <button type="button" className="btn btn-success mr-3 " onClick={this.close.bind(this)} >Ok</button>
                </div>

            </ModalFooter>

        </Modal>
        )
    }

    render() {
        console.log(this.state)
        let content = this.select_content()

        return (content)
    }
}

interface result {
    hex: string
    cost: number
}