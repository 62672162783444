import * as React from 'react';
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { UnixToDate } from '../../Global/UnixToDate'
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
import { Election_lite, candidate_lite } from '../_Interfaces/Elections'
import ReactTable from 'react-table';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { GetInputPopup } from '../../Global/GetInputPopup'
import { InfoPopup } from '../../Global/InfoPopup'
import { PickBlockButton } from '../../Global/PickBlockButton'
import { CreateElectionPopupConfirmation } from './CreateElectionPopupConfirmation'
import * as settings from "../../Global/settings"
import * as api from '../../Global/API'

import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'

interface Props {
    close_callback: any;
}
interface CreateElectionPopupState {
    show_confirmation: boolean;
    show_result: boolean;
    encoding_result: encoding_result;
    send_result: SendResult;

    new_election: Election_lite;
    length: number;
    load_complete: boolean
    new_candidate_text: string;

    show_get_input: boolean;

    info_title: string;
    info_body: string;
    show_info: boolean;

    warning_label: string;
}
export class CreateElectionPopup extends React.Component<Props, CreateElectionPopupState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            new_election: {
                title: "",
                description: "",
                candidates: [],
                start_block: 0,
                end_block: 0,
            },

            length: 0,
            load_complete: false,

            new_candidate_text: "",
            show_get_input: false,
            info_body: "",
            info_title: "",
            show_info: false,
            show_confirmation: false,

            send_result: blank_send_result,
            encoding_result: blank_encoding_result,
            show_result: false,
            warning_label: "",
        };
    }
    //required for security, set pass to null

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value } as any);
    }

    set_startblock(block: number, unix: number) {
        this.setState({ new_election: { ...this.state.new_election, start_block: block } });
    }
    set_stopblock(block: number, unix: number) {
        this.setState({ new_election: { ...this.state.new_election, end_block: block } });
    }

    add_candidate(text: string) {
        var elect: Election_lite = this.state.new_election

        var can: candidate_lite = { text }

        elect.candidates.push(can);

        this.setState({ new_election: elect, show_get_input: false })
    }
    remove_candidate(index: number) {
        var elect: Election_lite = this.state.new_election
        var cans: candidate_lite[] = this.state.new_election.candidates

        elect.candidates.splice(index, 1);

        this.setState({ new_election: elect })
    }
    clear_all() {
        var elect: Election_lite = this.state.new_election
        elect.candidates = []
        this.setState({ new_election: elect })
    }

    show_get_input() {
        this.setState({ show_get_input: true })
    }

    validate() {
        if (this.state.new_election.title == "" || this.state.new_election.title == null) {
            this.setState({ info_title: "Data Error", info_body: "You must enter a title", show_info: true })
            return false
        }

        if (this.state.new_election.candidates.length < 1) {
            this.setState({ info_title: "Data Error", info_body: "You must have at least one candidate!", show_info: true })
            return false
        }
        if (this.state.new_election.candidates.length > 200) {
            this.setState({ info_title: "Data Error", info_body: "You may not have more than 200 candidates!", show_info: true })
            return false
        }

        if (this.state.new_election.end_block == 0) {
            this.setState({ info_title: "Data Error", info_body: "Voting stop time is not set!", show_info: true })
            return false
        }

        if (this.state.new_election.start_block == 0) {
            this.setState({ info_title: "Data Error", info_body: "Voting start time is not set!", show_info: true })
            return false
        }

        //todo start block & end block validation.

        this.setState({ show_confirmation: true })
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }

    fail() {
        this.setState({ show_confirmation: false, show_result: false })
    }

    success() {
        this.props.close_callback()
    }

    close_info() {
        this.setState({ show_info: false })
    }

    start_send() {
        this.validate()
    }

    results_close() {
        this.setState({ show_result: false })
    }

    popup_close() {
        this.props.close_callback();
    }

    select_content() {
        if (this.state.show_confirmation) {
            return (<CreateElectionPopupConfirmation election={this.state.new_election} cancel_callback={this.fail.bind(this)} complete_callback={this.send.bind(this)} />)
        }
        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        const data: iText[] = this.state.new_election.candidates;

        const columns = [
            {
                Header: 'Candidates',
                accessor: 'text',
            },

        ]

        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Create New Election

            </ModalHeader>
            <ModalBody>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">Title*:</span>
                    <input type="text" className="form-control" placeholder="Title" aria-describedby="basic-addon1" required={true} name="title" value={this.state.new_election.title} onChange={e => this.setState({ new_election: { ...this.state.new_election, title: e.target.value } })} ></input>
                </div>
                <p />

                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">Description:</span>
                    <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" name="Description" value={this.state.new_election.description} onChange={e => this.setState({ new_election: { ...this.state.new_election, description: e.target.value } })}></input>
                </div>
                <p />
                <p />
                <p />

                <div className="row">
                    <div className="form-group col-xs-6">
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">Start Block*</span>
                            <PickBlockButton button_text={this.state.new_election.start_block == 0 ? "Select Date" : this.state.new_election.start_block.toString()} return_callback={this.set_startblock.bind(this)} />

                        </div>
                    </div>

                    <div className="form-group col-xs-6">
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">End Block*</span>
                            <PickBlockButton button_text={this.state.new_election.end_block == 0 ? "Select Date" : this.state.new_election.end_block.toString()} return_callback={this.set_stopblock.bind(this)} />

                        </div>
                    </div>
                </div>

                <p />
                * Required
                <p />

                < ReactTable

                    data={data}
                    columns={columns}
                    showPagination={false}

                    minRows={2}
                    className="-highlight"
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                            onDoubleClick: () => {
                                console.log("A Td Element was clicked!");

                                //this.open_vote_view(rowInfo.row)
                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                            }
                        };
                    }}

                />
                <p />

                <p />
                <p />
                <p />

                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 " onClick={this.show_get_input.bind(this)}>Add Candidate</button>
                    <button type="button" className="btn btn-success mr-3 btn-warning" onClick={() => { this.clear_all() }}>Clear All Candidates</button>

                </div>

                <div className="row">
                    <div className="form-group col-xs-10">
                        <div className="btn-toolbar" role="group" aria-label="...">

                        </div>
                    </div>
                    <div className="form-group col-xs-2">

                    </div>
                </div>

                <GetInputPopup title={"Create New Candidate"} info={"Enter Candidate's name"} show_me={this.state.show_get_input} close_callback={() => this.setState({ show_get_input: false })} data_callback={this.add_candidate.bind(this)} />

            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <div className="btn-toolbar" role="group" aria-label="...">

                        <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Create</button>
                        <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Close</button>
                    </div>
                </div>
                <div className="row">
                    <h4> {this.state.warning_label}</h4>
                </div>
            </ModalFooter>
        </Modal>

        )
    }

    render() {
        let content = this.select_content()

        return (
            this.state.show_info ?
                <InfoPopup close_callback={this.close_info.bind(this)} title={this.state.info_title} info={this.state.info_body} />
                :
                content
        )
    }
}

interface iText {
    text: string;
}