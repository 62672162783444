import * as React from 'react';
import { TrueFalseIcon } from "../../Global/TrueFalseIcon"
import * as api from '../../Global/API'
import { ipfs_link } from '../_Interfaces/NFT'
import * as Settings from '../../Global/settings'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { InfoPopup } from '../../Global/InfoPopup'
import { encoding_result, blank_encoding_result } from '../_Interfaces/iResult'
interface Props {
    link: ipfs_link

    cancel_callback: any;
    continue_callback: any;
}
interface State {
    encoding_result: encoding_result;
}

export class CreateIpfsObjectConfirmation extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            encoding_result: blank_encoding_result,
        };
        const body = JSON.stringify({ link: this.props.link, creator: Settings.current_identity.address })
        api.EncodeNewIpfsLink(body, (data: any) => { this.setState({ encoding_result: data }); })
    }
    //required for security, set pass to null

    Should_show(st: string): boolean {
        if (typeof (st) == 'string' && st.length > 0)
            return true;

        return false
    }

    close_info() {
        this.props.cancel_callback()
    }

    render() {
        if (!this.state.encoding_result.success) {
            return <InfoPopup title={'Error'} info={this.state.encoding_result.message} close_callback={this.props.cancel_callback} />
        }
        return (<Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.cancel_callback() }} >
                Please ensure the following information is correct

            </ModalHeader>
            <ModalBody>

                <dl className="dl-horizontal" >
                    <dt>IPFS link :</dt><dd>{this.props.link.link}</dd>
                </dl>

                <h4>Please note. There is little to no validation done on the correctness of the
                    ipfs linked object at this stage. It is up to you to ensure that the link points
                    to valid smartobject data!
                </h4>
                <br />

                <h4>The cost for this operation is {this.state.encoding_result.cost} Val. Are you sure?</h4>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => {
                        this.props.cancel_callback()
                    }}>Close</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => {
                        this.props.continue_callback(this.state.encoding_result)
                    }}>Create</button>
                </div>
            </ModalFooter>
        </Modal>

        );
    }
}