import * as React from 'react';
import { blank_NFT, NFT } from '../_Interfaces/NFT'
import img_noimage from '../../../Assets/noimage.png';
import img_loading from '../../../Assets/ipfsloading.png';
import { TxStatus } from '../_Interfaces/iSmartTx';

import { NFTInfoPopup } from './NFTInfoPopup'
interface State {
    showFull: boolean;
}
export interface Props {
    nft: NFT
}

export class NFTTile extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { showFull: false };
    }

    setShowFull(value: boolean) {
        this.setState({ showFull: value })
    }

    public render() {
        var image;
        if (this.props.nft == null)
            return (<div />);
        if (this.props.nft.status != null && this.props.nft.status.status == TxStatus.loading)
            image = img_loading;
        else if (this.props.nft.immage == null)
            image = img_noimage;
        else
            image = "https://smartchain.validitytech.com/api/public/ipfsthumb/" + this.props.nft.immage;

        let collection_name: string = ' p-2 tileborder  col-md-12 NFTTile';

        var rarity: string = 'normal';

        if (this.props.nft.custom_fields != null) {
            this.props.nft.custom_fields.map(custom => {
                if (custom.key == "rarity") {
                    switch (custom.value) {
                        case "rare":
                            collection_name += ' tileborderrare';
                            rarity = 'rare';

                            break;
                        case "uncommon":
                            collection_name += ' tileborderuncommon';
                            rarity = 'uncommon';

                            break;
                        default:
                            collection_name += ' tileborder';
                    }
                }
            })
        }

        return (
            this.props.nft.txid != null ?
                <div className={collection_name} onClick={() => this.setShowFull(true)}>
                    <div className='text-center text-ellipsis' >{this.props.nft.name}</div>
                    <p className='text-center' id="rarityColor" >{rarity == "normal" ? "____" : rarity}</p>
                    <div className='text-center ' style={{ marginTop: 'auto' }}>

                        <img src={image} alt={""} height="100%" width="100%" className=" nfttilecontent img-responsive img-thumbnail" />

                    </div>
                    <br />

                    {/* <div className='rarity' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Rarity: <p id="rarityColor" >{ rarity }</p></div>*/}

                    <div className=' overflow text-center'>{this.props.nft.description}</div>
                    {this.state.showFull ?
                        < NFTInfoPopup nft={this.props.nft} close_callback={() => this.setShowFull(false)} />
                        : null
                    }
                </div>

                :
                null

        )
    }
}