import * as React from 'react';
import { NFT, } from '../_Interfaces/NFT'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as settings from "../../Global/settings"
import { OfferTable } from "./OfferTable"
import * as api from '../../Global/API'
import { utxo } from '../_Interfaces/iUTXO'
interface Props {
    nft: NFT
    close_callback: any;
    success_callback: any;
}
interface State {
    val_balance: number,
}

export class ReviewOffersPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            val_balance: 0,
        };
        api.GetAddressUtxos(settings.current_identity.address, (data: any) => {
            var value: number = 0;
            data.forEach(
                function (element: utxo) {
                    value += element.value
                }
            )
            this.setState({ val_balance: value });
        })
    }
    close() {
        this.props.close_callback(true)
    }
    render() {
        return (<Modal size="lg" backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >

                A list of pending offers. Select one

            </ModalHeader>
            <ModalBody>
                <OfferTable Offers={this.props.nft.pending_offers} />

            </ModalBody>
            <ModalFooter>

            </ModalFooter>

        </Modal>
        )
    }
}