import * as React from 'react';
import { NFT_command, blank_NFT_command, NFT_command_type, NFTCollection } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import * as Settings from '../../Global/settings'
import { GetInputPopup } from '../../Global/GetInputPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { User, blank_user } from "../_Interfaces/iUser"
import { SelectUser } from "../../Global/SelectUser"
import * as settings from "../../Global/settings"
import { SmartTxSendResultComponent } from "../../Global/SmartTxSendResultComponent"
import { TransferNFTCollectionPopupConfirmation } from "./TransferNFTCollectionPopupConfirmation"
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
interface Props {
    Collection: NFTCollection
    close_callback: any;
    success_callback: any;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean

    command: NFT_command

    confirm_name: string
    confirm_id: string

    show_select_user: boolean

    issueto_display_name: string
}

export class TransferAssetClassPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            info_title: "",
            info_body: "",
            show_info: false,

            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            show_result: false,
            //
            confirm_id: "",
            confirm_name: "",

            command: {
                destination: undefined,
                amount: undefined,

                object_id: this.props.Collection.txid,
                command_type: NFT_command_type.transfer,
            },
            show_select_user: false,

            issueto_display_name: "",
        };
    }

    close() {
        this.props.close_callback(true)
    }

    set_user(u: User) {
        if (u == undefined) { return }
        this.setState({ issueto_display_name: "User:" + u.username + " Address:" + u.address })
        this.setState({ command: { ...this.state.command, destination: u } })
        this.setState({ show_select_user: false })
    }

    validate() {
        if (this.props.Collection.txid != this.state.confirm_id) {
            this.setState({ show_info: true, info_title: "Error", info_body: "Confirmation ID does not match" })
            return;
        }
        if (this.props.Collection.collection_name != this.state.confirm_name) {
            this.setState({ show_info: true, info_title: "Error", info_body: "Confirmation name does not match" })
            return;
        }

        // chesks that asset is transferable
        if (!this.props.Collection.collection_transferable) {
            this.setState({ show_info: true, info_title: "Error", info_body: "This NFT class is non-transferable!" })
            return;
        }

        let can_transfer: boolean = false
        if (this.props.Collection.collection_name) {
            if (this.props.Collection.owner!.address == settings.current_identity.address) {
                can_transfer = true;
            }
        }
        if (!can_transfer) {
            this.setState({ show_info: true, info_title: "Error", info_body: "You do not have permission to transfer this NFT class!" })
            return;
        }

        // checks that destination is set
        if (this.state.command.destination == undefined) {
            this.setState({ show_info: true, info_title: "Error", info_body: "You must select a person to transfer this NFT class to!" })
            return;
        }

        this.setState({ show_confirmation: true })
    }

    close_select_user() {
        this.setState({ show_select_user: false })
    }

    fail() {
        this.setState({ show_confirmation: false, show_result: false })
    }

    success() {
        this.props.success_callback()
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    results_close() {
        this.setState({ show_result: false })
    }

    popup_close() {
        this.props.close_callback();
    }

    render() {
        if (this.state.show_confirmation) {
            return <TransferNFTCollectionPopupConfirmation Collection={this.props.Collection} command={this.state.command} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }

        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Transfer NFT
                <dl className="dl-horizontal">
                    <dt>Name :</dt> <dd>{this.props.Collection.collection_name}</dd>
                    <dt>Description :</dt> <dd>{this.props.Collection.collection_description}</dd>
                    <dt>Owner :</dt> <dd>{this.props.Collection.owner!.username}</dd>
                    <dt>ID :</dt> <dd>{this.props.Collection.txid}</dd>
                </dl>

            </ModalHeader>
            <ModalBody>

                <p />

                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">Transfer To:</span>
                    <input type="text" className="form-control" placeholder="Click to edit" aria-describedby="basic-addon1" required={true} name="username" value={this.state.issueto_display_name} onClick={e => { this.setState({ show_select_user: true }) }} ></input>

                </div>
                <p />
                <span>Transfering an NFT is an irriviersable action.</span>
                <span>Please enter the NFT name and ID below to confirm</span>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">NFT Name*:</span>
                    <input type="text" className="form-control" placeholder="Name" aria-describedby="basic-addon1" required={true} name="username" value={this.state.confirm_name} onChange={e => { this.setState({ confirm_name: e.target.value }) }} ></input>

                </div>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">NFT ID:</span>
                    <input type="text" className="form-control" placeholder="ID" aria-describedby="basic-addon1" required={true} name="username" value={this.state.confirm_id} onChange={e => { this.setState({ confirm_id: e.target.value }) }} ></input>

                </div>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Cancel</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Transfer</button>
                </div>
            </ModalFooter>
            {this.state.show_select_user ?
                <SelectUser cancel_callback={this.close_select_user.bind(this)} return_callback={this.set_user.bind(this)} />
                : null
            }

        </Modal>
        )
    }
}

interface result {
    hex: string
    cost: number
}