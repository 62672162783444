import * as React from 'react';
import { HashFileComponent } from './HashFileComponent'
import { VerifyFileResult } from './VerifyFileResult'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as settings from '../../Global/settings'
interface Props {
    close_callback: any;
}
interface state {
    show_result: boolean;
    file_hash: string;
}

export class VerifyFilePopup extends React.Component<Props, state>{
    constructor(props: Props) {
        super(props);
        this.state = {
            show_result: false,
            file_hash: "",
        };
    }

    got_hash(hash: string) {
        this.setState({ file_hash: hash, show_result: true })
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value } as any)
    }

    close() {
        this.props.close_callback(true)
    }

    render() {
        if (this.state.show_result) {
            return <VerifyFileResult hash={this.state.file_hash} close_callback={this.close.bind(this)} />
        }

        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Verify a file with SmartChain

            </ModalHeader>
            <ModalBody>
                <HashFileComponent complete_callback={this.got_hash.bind(this)} />

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Close</button>
                </div>

            </ModalFooter>
        </Modal>
    }
}

interface result {
    hex: string
    cost: number
}