import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Topright } from '../../Global/toprightmenue/toprightmenue'
interface NavMenuState {
    mobile: boolean
}

interface Props {
}

export class SignedMessagesMenu extends React.Component<Props, NavMenuState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            mobile: false,
        };
    }

    componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this))

        // store intervalId in the state so it can be accessed later:
    }
    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    public render() {
        return <div className='row navbar main-nav navbar-expand-lg'>
            <nav className="col-sm-8   ">

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="nav navbar-nav navbar-justify">

                    </ul>
                </div>
            </nav>
            <div className="col-sm-4 ">
                <div >
                    <Topright />
                </div>
            </div>
        </div>;
    }
}