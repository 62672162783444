import * as React from 'react';
import ReactTable from 'react-table';
import { UserInfoPopup } from './UserInfoPopup'
import { UnixToDate } from '../../Global/UnixToDate'
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { User } from '../_Interfaces/iUser'
import * as settings from '../../Global/settings'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as api from '../../Global/API'
import * as statics from '../../Global/statics'

interface UserTableState {
    users: User[];
    rangestart: number;
    loading: boolean;
    SelectedUser: string;
    ShowUser: boolean;

    verified: string;
    username: string;
    description: string;
    address: string;
    intervaltick: any;
    sctop: string;
    modal: boolean;
    sc_top: string;
}
export interface Props {
    defaultPageSize: number;
    showPagination: boolean;

    mobile: boolean;
}

export class UserTable extends React.Component<Props, UserTableState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            users: [], rangestart: 0, loading: true, SelectedUser: "", ShowUser: false,
            verified: "", username: "", description: "", address: "", intervaltick: 5000, sctop: "", modal: false,
            sc_top: "",
        };

        this.load_data();
    }
    load_data() {
        api.GetAllUsers((data: User[]) => {
            this.setState({ users: data, loading: false })
            console.log(data);
        })
    }
    tick() {
        if (settings.top_block.sc_top != this.state.sc_top) {
            this.load_data()
            this.setState({ sc_top: settings.top_block.sc_top })
        }
    }

    componentDidMount() {
        var inttick = setInterval(() => this.tick(), 5000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervaltick: inttick });
    }
    componentWillUnmount() {
        clearInterval(this.state.intervaltick);
    }

    CloseViewUser() {
        this.setState({ ShowUser: false })
    }

    OpenViewUser(useraddress: string) {
        this.setState({ SelectedUser: useraddress, ShowUser: true })
    }

    public render() {
        let contents = this.state.loading
            ? <p><em>{settings.language.Loading}</em></p>
            : this.RenderUserTable();

        return contents;
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value } as any, () => {
            this.filter()
        });
    }
    handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value } as any, () => {
            this.filter()
        });
    }

    //need to get the filter values into an interface to post
    toggle = () => {
        this.setState({ ShowUser: true })
    }
    filter() {
        api.FilteredUsers(JSON.stringify({
            username: this.state.username,
            description: this.state.description,
            address: this.state.address,
            status: this.state.verified
        }),
            (data: any) => this.setState({ users: data, loading: false })

        )
    }

    public RenderUserTable() {
        const data = this.state.users;
        const columns = [
            {
                Header: settings.language.Username,
                accessor: 'username',
                className: "sticky",
                headerClassName: "sticky",
                filterMethod: (filter: any, row: any) =>

                    false
            },
            {
                Header: settings.language.Description,
                accessor: 'description'
            },
            {
                Header: settings.language.Address,
                accessor: 'address'
            },
            {
                Header: settings.language.Join_Date,
                accessor: 'unix_time',
                Cell: (row: any) => (
                    <UnixToDate unix={row.value} />
                )
            }

        ]

        return <div className="main-page-body table table-responsive">
            <div className="body-header">
                <h1>Users</h1>
            </div>
            <div className="body-content">

                {this.props.mobile ?
                    <div className="row">
                        <div className="form-group col-xs-6">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Username</span>
                                <input type="text" className="form-control" placeholder="Username" aria-describedby="basic-addon1" name="username" value={this.state.username} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>

                        <div className="form-group col-xs-6">
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">Status</span>
                                <select className="form-control" aria-describedby="basic-addon1" id="sel1" name="verified" value={this.state.verified} onChange={evt => this.handleSelectChange(evt)}>
                                    <option>All</option>
                                    <option>Verified</option>
                                    <option>Unverified</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row ">
                        <div className="col-sm-4">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">Username</span>
                                </div>
                                <input type="text" className="form-control" placeholder="Username" aria-describedby="basic-addon1" name="username" value={this.state.username} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className=" col-sm-4 filtering-optional">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text " id="basic-addon1">Description</span>
                                </div>
                                <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" name="description" value={this.state.description} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-4 filtering-optional">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text filtering-optional" id="basic-addon1">Address</span>
                                </div>
                                <input type="text" className="form-control" placeholder="Address" aria-describedby="basic-addon1" name="address" value={this.state.address} onChange={evt => this.handleInputChange(evt)}></input>
                            </div>
                        </div>

                    </div>
                }

                {this.props.mobile ?
                    < ReactTable
                        data={data}
                        columns={columns}
                        showPagination={false}
                        defaultPageSize={50}
                        minRows={5}
                        className="-highlight"
                        loading={this.state.loading}
                        defaultSorted={[{ id: "unix_time", desc: true }]}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: () => {
                                    console.log("A Td Element was clicked!");
                                    this.OpenViewUser(rowInfo.row.address)
                                }
                            };
                        }}

                    />
                    :
                    < ReactTable
                        data={data}
                        columns={columns}
                        showPagination={true}
                        defaultPageSize={50}
                        minRows={5}
                        className="-highlight"
                        loading={this.state.loading}
                        defaultSorted={[{ id: "unix_time", desc: true }]}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onDoubleClick: () => {
                                    console.log("A Td Element was clicked!");
                                    this.OpenViewUser(rowInfo.row.address)
                                }
                            };
                        }}

                    />
                }

                {this.state.ShowUser ?
                    <UserInfoPopup address={this.state.SelectedUser} close_callback={this.CloseViewUser.bind(this)} />
                    : null
                }

            </div>
        </div>
    }
}