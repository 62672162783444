import * as React from 'react';
import { Redirect } from 'react-router';
import * as settings from '../../Global/settings'

import { payments, networks, ECPair } from 'radiumjs-lib'
import * as ecc from 'tiny-secp256k1';
import { InfoPopup } from '../../Global/InfoPopup'
import * as api from '../../Global/API'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { CreateUserPopup } from '../../MainPages/Users/CreateUserPopup'
interface SendState {
    key: string,

    show_error: boolean
    show_incomplete: boolean
    show_welcome: boolean
    show_create_user: boolean
    info_title: string;
    info_body: string;
    go_home: boolean;

    login_complete: boolean
}
interface Props {
    close_callback: any;
}

export class LoginPopup extends React.Component<Props, SendState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            key: "",
            show_error: false,
            show_incomplete: false,
            show_welcome: false,
            show_create_user: false,
            info_title: "",
            info_body: "",
            login_complete: false,
            go_home: false,
        };
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value } as any);
    }

    make_key() {
        const keyPair = ECPair.makeRandom({ network: networks.radium })
        const address = payments.p2pkh({ pubkey: keyPair.publicKey })

        return keyPair.toWIF()
    }

    login() {
        try {
            settings.set_priv_key(this.state.key)
            const { address } = payments.p2pkh({ pubkey: settings.private_keypair.publicKey, network: networks.radium })

            api.GetUser(address as string, (data: any) => {
                if (data.address == null) {
                    settings.clear_current_identity()
                    settings.current_identity.address = address as string
                }
                else {
                    settings.set_current_identity(data)
                    settings.set_priv_key(this.state.key)
                }

                if (data.address == null) { this.show_incomplete_user() } else { this.show_welcome_user(data.username) }
                this.setState({ login_complete: true })
            })
        }
        catch (ex) {
            this.setState({ show_error: true, info_title: "Error ", info_body: "Invalid Validity Key" })
        }
    }

    show_welcome_user(username: string) {
        this.setState({ show_welcome: true })
    }
    show_incomplete_user() {
        this.setState({ show_incomplete: true })
    }

    show_create_user() {
        this.setState({ show_create_user: true, show_incomplete: false })
    }

    hide_create_user() {
        this.setState({ show_create_user: false })
    }

    close_info() {
        this.setState({ show_error: false })
    }
    get_content() {
        if (this.state.go_home) {
            return <Redirect to={'/home'} />
        }

        if (this.state.show_error) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={this.close_info.bind(this)} />
        }

        if (this.state.show_welcome) {
            return <InfoPopup title={"Welcome Back, " + settings.current_identity.username} info={""} close_callback={this.go_home.bind(this)} />
        }

        if (this.state.show_incomplete) {
            return <InfoPopup title={"Incomplete Profile"} info={"You are logged in with an unknown profile. Please complete your profile!"} close_callback={this.show_create_user.bind(this)} />
        }

        return this.render_main()
    }
    go_home() {
        this.setState({ go_home: true })
    }

    close() {
        this.props.close_callback()
    }

    public render() {
        let content = this.get_content()
        return (content)
    }

    public render_main() {
        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.close() }} >
            <ModalHeader toggle={() => { this.close() }} >Login</ModalHeader>
            <ModalBody>
                <div >
                    <h2> Use Existing Account</h2>
                    <h4> Please enter the key associated with the account you wish to use.</h4>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Key*</span>
                        </div>
                        <p></p>
                        <input type="password" className="form-control" placeholder="Private key" aria-describedby="basic-addon1" required={true} name="key" value={this.state.key} onChange={e => this.setState({ key: e.target.value })}></input>
                    </div>
                    <div className="py-1 text-center">

                    </div>
                </div>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar " role="group" aria-label="...">

                    <button type="button" className="btn btn-success btn-default" onClick={this.login.bind(this)}>Login</button>
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.close() }}>Close</button>
                </div>
            </ModalFooter>
            {this.state.show_create_user
                ? < CreateUserPopup _key={this.state.key} close_callback={this.hide_create_user.bind(this)} />
                : null}
        </Modal>
        )
    }
}