import * as React from 'react';
import { ExploreLayout } from "../Explore/ExploreLayout"
import { Route } from 'react-router';
import { Progress } from 'reactstrap';
import { ExploreMenu } from './ExploreMenu'
import logo from '../../../Assets/ValLogo305x300.png';
import { AllNFT } from './AllNFT';
import { SmartTxs } from './smarttxs';
import { AllNFTClasses } from './AllNFTClasses';
import { AllUsers } from './AllUsers';
import { AllRecords } from './AllRecords';
import { AllElections } from './AllElections';
import { RedirectExplore } from '../../Global/RedirectExplore';
import { ALL } from 'dns';
interface LoadingState {
}

export interface LoadingProps {
}
export class Explore extends React.Component<LoadingProps, LoadingState> {
    constructor(props: LoadingProps) {
        super(props);
        this.state = {
        };
        //used to bypas loading screen for gui dev
        //this.props.callback(true)
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    public render() {
        return (
            <ExploreLayout>
                <Route path='/explore'>
                    <RedirectExplore />
                </Route>
                <Route path='/explore/users'><AllUsers /></Route>
                <Route path='/explore/records'><AllRecords /></Route>
                <Route path='/explore/elections'><AllElections /></Route>
                <Route path='/explore/nfts'><AllNFT /></Route>
                <Route path='/explore/nftcollections'><AllNFTClasses /></Route>
                <Route path='/explore/smarttxs'><SmartTxs /></Route>
            </ExploreLayout>
        );
    }
}

interface setup_data {
    message: string;
    progress: number;
}