import { User } from '../_Interfaces/iUser'
import * as React from 'react';
export enum TxStatus {
    executed = 3,
    loading = 2,
    pending = 1,
    failed = 0,
}

export interface SmartTxStatus {
    status: TxStatus,
    message: string,
}

export const blank_SmartTxStatus: SmartTxStatus = {
    status: TxStatus.pending,
    message: "",
}

export interface iSmartTx {
    status: SmartTxStatus
    destroyed: boolean
    ContributedValue: number,
    BaseCost: number,
    SpecialCost: number,
    ChainHash: string,
    Txid: string,
    type: RecordType,
    creator: User,
}

export enum RecordType {
    user = 0,
    note = 1,
    setuserstatus = 2,
    filehash = 5,
    setversion = 6,
    contract = 7,
    election = 8,
    vote = 9,
    nft = 10,
    collection = 11,
    object_command = 13,
    bundle = 14,
    ipfs_link = 15,
    update_user = 16,
    market_offer = 17,
    authtoken = 255,

    candidate,
    all,
}