import * as React from 'react';

export interface Props {
    state: boolean;
}

export class TrueFalseIcon extends React.Component<Props> {
    props: Props;
    public render() {
        return this.props.state ? (
            <span className="fa fa-check text-success" />
        ) : (
            <span className="fa fa-ban text-danger" />
        );
    }
}