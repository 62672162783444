import * as React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as Settings from './settings'
interface Props {
    close_callback: any;
    title: string;
    info: string;
}
interface InfoPopupState {
}

export class InfoPopup extends React.Component<Props, InfoPopupState>{
    constructor(props: Props) {
        super(props);
    }
    //required for security, set pass to null

    render() {
        return <Modal isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }}>{this.props.title}</ModalHeader>
            <ModalBody className="infopopup">
                {this.props.info}
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => { this.props.close_callback() }}>{Settings.language.Continue}</button>
            </ModalFooter>
        </Modal>
    }
}