import * as React from 'react';
import ReactTable from 'react-table';
import { UnixToDate } from '../../Global/UnixToDate'
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { iSmartTx } from '../_Interfaces/iSmartTx'
import * as settings from '../../Global/settings'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as api from '../../Global/API'
import * as statics from '../../Global/statics'

interface UserTableState {
    Txs: iSmartTx[];
    rangestart: number;
    loading: boolean;
    SelectedUser: string;
    ShowUser: boolean;

    verified: string;
    username: string;
    description: string;
    address: string;
    intervaltick: any;
    sctop: string;
    modal: boolean;
    sc_top: string;
}
export interface Props {
    defaultPageSize: number;
    showPagination: boolean;

    mobile: boolean;
}

export class SmartTxTable extends React.Component<Props, UserTableState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            Txs: [], rangestart: 0, loading: true, SelectedUser: "", ShowUser: false,
            verified: "", username: "", description: "", address: "", intervaltick: 5000, sctop: "", modal: false,
            sc_top: "",
        };

        this.load_data();
    }
    load_data() {
        api.getrecentsmarttxs((data: any) => {
            this.setState({ Txs: data, loading: false })
        })
    }
    tick() {
        if (settings.top_block.sc_top != this.state.sc_top) {
            this.load_data()
            this.setState({ sc_top: settings.top_block.sc_top })
        }
    }

    componentDidMount() {
        var inttick = setInterval(() => this.tick(), 5000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervaltick: inttick });
    }
    componentWillUnmount() {
        clearInterval(this.state.intervaltick);
    }

    CloseViewUser() {
        this.setState({ ShowUser: false })
    }

    OpenViewUser(useraddress: string) {
        this.setState({ SelectedUser: useraddress, ShowUser: true })
    }

    public render() {
        let contents = this.state.loading
            ? <p><em>{settings.language.Loading}</em></p>
            : this.RenderUserTable();

        return contents

            ;
    }

    public RenderUserTable() {
        const data = this.state.Txs;

        const columns = [
            {
                id: "type",
                Header: "type",
                accessor: 'type',
                className: "sticky",
                headerClassName: "sticky"
            },
            {
                id: "status",
                Header: "status",
                accessor: 'status.status'
            },
            {
                id: "creator",
                Header: "creator",
                accessor: "creator.username"
            },
            {
                id: "cost",
                Header: "cost",
                accessor: "contributedvalue"
            },
            {
                id: "txid",
                Header: "txid",
                accessor: "txid"
            },
            {
                id: "pending",
                Header: "pending",
                accessor: "pending",
                Cell: (row: any) => (
                    <span>{row.value == null ? "false" : "true"} </span>
                )
            },
            {
                id: "unix_time",
                Header: 'date',
                accessor: 'unix_time',
                Cell: (row: any) => (
                    <UnixToDate unix={row.value} />
                )
            }

        ]

        return <div className="main-page-body table table-responsive">
            <div className="body-header">
                <h1> Recent Smart Tx's</h1>
            </div>
            <div className="body-content">
                {this.props.mobile ?
                    < ReactTable
                        data={data}
                        columns={columns}
                        showPagination={true}
                        defaultPageSize={-1}
                        minRows={5}
                        className="-highlight"
                        loading={this.state.loading}
                        defaultSorted={[{ id: "unix_time", desc: true }]}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onClick: () => {
                                    console.log("A Td Element was clicked!");
                                    this.OpenViewUser(rowInfo.row.address)
                                }
                            };
                        }}

                    />
                    :
                    < ReactTable
                        data={data}
                        columns={columns}
                        showPagination={true}
                        defaultPageSize={50}
                        minRows={5}
                        className="-highlight"
                        loading={this.state.loading}
                        defaultSorted={[{ id: "unix_time", desc: true }]}
                        getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                            return {
                                onDoubleClick: () => {
                                    console.log("A Td Element was clicked!");
                                    this.OpenViewUser(rowInfo.row.address)
                                }
                            };
                        }}

                    />
                }

            </div>
        </div>
    }
}