import * as React from 'react';
import { NFTClassTable } from './NFTCollectionTable';
import { CreateNFTCollectionPopup } from './CreateNFTCollectionPopup'
import { InfoPopup } from '../../Global/InfoPopup'
import * as settings from '../../Global/settings'
import { NFTCollectionMenu } from './NFTCollectionMenu';
interface State {
    mobile: boolean;
}

export class NFTCollections extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            mobile: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    public render() {
        return <span>
            <NFTCollectionMenu />

            <NFTClassTable mobile={this.state.mobile} title={"Your NFT Collections"} defaultPageSize={-1} showPagination={true} />

        </span>
    }
}