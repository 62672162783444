import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as api from '../Global/API'
import { SendResult, blank_send_result } from '../MainPages/_Interfaces/iResult'
import * as settings from '../Global/settings'

interface Props {
    cancel_callback: any;
    success_callback: any;
}
interface UploadFilePopupState {
    //control
    success: boolean,
    message: string,
    ipfs_hash: string,

    // identity
}

export class UploadFilePopup extends React.Component<Props, UploadFilePopupState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            success: false,
            message: "",
            ipfs_hash: "",
            //
        };
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value } as any)
    }

    cancel() {
        this.props.cancel_callback(true)
    }
    continue() {
        if (this.state.success) {
            this.props.success_callback(this.state.ipfs_hash)
            this.setState({})
        }
    }

    handlefilechosen(fi: FileList | null) {
        this.setState({ message: "Uploading...!" })
        api.UploadToIpfs(fi[0], (data: SendResult) => {
            if (data.success == false) {
                this.setState({ message: "Error! " + data.message })
                return;
            }
            this.setState({ message: "Upload success!", success: true, ipfs_hash: data.message })
        })
    }

    render() {
        return <Modal isOpen={true} onHide={() => { }}>
            <ModalHeader toggle={() => { this.props.cancel_callback() }} >
                Sign a File

            </ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <input type="file" className="form-control-file" id="customFile" onChange={evt => this.handlefilechosen(evt.target.files)} />

                </div>
                <h3>{this.state.message}</h3>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.cancel_callback() }}>Close</button>
                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.continue.bind(this)}>{settings.language.Continue}</button>

                </div>

            </ModalFooter>
        </Modal>
    }
}