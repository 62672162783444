import * as React from 'react';
import { User, blank_user, customfield, blank_customfield } from '../_Interfaces/iUser'
import * as api from '../../Global/API'
import { InfoPopup } from '../../Global/InfoPopup'
import * as Settings from '../../Global/settings'
import { CreateKeyPopup } from '../../MainPages/Login/CreateKeyPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { payments, networks, ECPair } from 'radiumjs-lib'
import { CreateUserPopupConfirmation } from '../Users/CreateUserPopupConfirmation'
import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
import { fail } from 'assert/strict';

interface Props {
    close_callback: any;

    _key?: string;
}
interface CreateUserPopupState {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean
    show_create_key: boolean
    is_key_set: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean

    //object
    user: User

    valid_username: boolean
}

export class CreateUserPopup extends React.Component<Props, CreateUserPopupState>{
    public static defaultProps: Partial<Props> = {
        _key: ""
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,
            show_create_key: false,
            is_key_set: false,
            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,

            //object
            user: blank_user,

            valid_username: true,
            //

            //
            show_result: false,
        };
    }

    componentDidMount() {
        if (this.props._key != "") {
            this.setState({ is_key_set: true })
            this.got_key(this.props._key)
        }
    }
    set_address(address: string): User {
        var temp: User = this.state.user;
        temp.address = address;
        return temp;
    }

    close() {
        this.props.close_callback(true)
    }

    username_change(name: string) {
        api.GetUserFromName(name, (data: User) => {
            if (data.address == null)
                this.setState({ valid_username: true })
            else
                this.setState({ valid_username: false })
        })
        this.setState({ user: { ...this.state.user, username: name } });
    }

    setError = (errorMessage: string) => {
        this.setState({ info_title: "Data Error", info_body: errorMessage, show_info: true })
    }

    validate_custom_fields = (custom_fields: Array<customfield>) => {
        if (custom_fields.length) {
            const result = custom_fields.reduce((store: Istore, { key }) => {
                if (!(/^[\w]+$/.test(key))) {
                    store.invalidKeys.push(key)
                }
                store.keys.push(key)
                return store
            }, {
                keys: [],
                invalidKeys: []
            })
            if (result.invalidKeys.length) {
                this.setError("You must enter a valid key for all custom fields")
                return false
            }
            const uniqueKeys = new Set(result.keys)
            if (uniqueKeys.size !== result.keys.length) {
                this.setError("You must ensure all custom fields have unique keys")
                return false
            }
        }
        return true
    }

    validate() {
        if (this.state.user.address == "" || this.state.user.address == null) {
            this.setState({ info_title: "Data Error", info_body: "You must create a new address", show_info: true })
            return;
        }

        if (this.state.valid_username == false) {
            this.setState({ info_title: "Data Error", info_body: "The username you selected is allready in use", show_info: true })
            return;
        }

        if (this.state.user.username == "") {
            this.setState({ info_title: "Data Error", info_body: "You must enter a username!", show_info: true })
            return;
        }

        const { custom_fields } = this.state.user;
        if (!this.validate_custom_fields(custom_fields)) { return };

        this.setState({ show_confirmation: true })
    }
    add_custom() {
        var temp: User = this.state.user
        var custom: customfield = {
            key: "",
            value: "",
            index: 0,
        };
        custom.index = this.state.user.custom_fields.length

        temp.custom_fields.push(custom)

        this.setState({ user: temp });
    }
    remove_custom(index: number) {
        var temp: User = this.state.user
        var custom: customfield[] = temp.custom_fields;

        for (var _i = 0; _i < temp.custom_fields.length; _i++) {
            if (temp.custom_fields[_i].index = index) {
                custom.splice(_i, 1);
                break;
            }
        }

        temp.custom_fields = custom

        this.setState({ user: temp });
    }
    update_custom_key(index: number, value: string) {
        var temp: User = this.state.user
        var custom: customfield = temp.custom_fields[index];
        custom.key = value
        temp.custom_fields[index] = custom

        this.setState({ user: temp });
    }

    update_custom_value(index: number, value: string) {
        var temp: User = this.state.user
        var custom: customfield = temp.custom_fields[index];
        custom.value = value
        temp.custom_fields[index] = custom

        this.setState({ user: temp });
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    send_sucess() {
        this.setState({ show_result: false })
        this.props.close_callback();
    }
    send_fail() {
        this.setState({ show_confirmation: true, show_result: false })
    }
    got_key(key: string) {
        Settings.set_priv_key(key);
        const { address } = payments.p2pkh({ pubkey: Settings.private_keypair.publicKey, network: networks.radium })
        this.setState({ user: { ...this.state.user, address: address } });
        Settings.clear_current_identity();

        this.setState({ is_key_set: true, show_create_key: false });

        this.setState({
            show_info: true, info_title: "Caution!", info_body: "A small amout of VAL is required to perform " +
                "SmartChain operations. Please ensure that " + address + " holds a  small amount of VAL (0.1 or more). " +
                "The VAL is required to complete the registration process."
        });
    }

    render() {
        if (this.state.show_confirmation) {
            return <CreateUserPopupConfirmation user={this.state.user} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }

        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.send_sucess() }} fail_callback={() => { this.send_fail() }} />
        }

        return (<span>
            <Modal className={"modal-lg"} backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
                <ModalHeader toggle={() => { this.props.close_callback() }} >
                    <h2>Create New Identity</h2>
                    {this.state.is_key_set ?
                        <span>
                            <h3>Address:</h3>
                            <h4>{this.state.user.address}</h4>
                        </span>
                        :
                        null
                    }

                </ModalHeader>
                <ModalBody>

                    {this.state.is_key_set ? null

                        :

                        <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => { this.setState({ show_create_key: true }) }}>Generate new Address/Key</button>
                    }

                    <p />

                    {this.state.is_key_set ? <span>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">Username*:</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Username" aria-describedby="basic-addon1" required={true} name="username" value={this.state.user.username} onChange={e => this.username_change(e.target.value)} ></input>

                        </div>
                        {this.state.valid_username ? "" : <span className="label label-danger">Username allready in use!</span>}
                        <p />

                        <p />
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">Description:</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" name="description" value={this.state.user.description} onChange={e => { this.setState({ user: { ...this.state.user, description: e.target.value } }) }} ></input>
                        </div>
                        <p />
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">Street Address:</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Street Address" aria-describedby="basic-addon1" name="streetaddress" value={this.state.user.streetaddress} onChange={e => { this.setState({ user: { ...this.state.user, streetaddress: e.target.value } }) }} ></input>
                        </div>
                        <p />
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">Phone:</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Phone" aria-describedby="basic-addon1" name="phone" value={this.state.user.phone} onChange={e => { this.setState({ user: { ...this.state.user, phone: e.target.value } }) }} ></input>
                        </div>
                        <p />
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">Email:</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Email" aria-describedby="basic-addon1" name="email" value={this.state.user.email} onChange={e => { this.setState({ user: { ...this.state.user, email: e.target.value } }) }} ></input>
                        </div>
                        <p />
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">Website:</span>
                            </div>
                            <input type="text" className="form-control" placeholder="Website" aria-describedby="basic-addon1" name="website" value={this.state.user.website} onChange={e => { this.setState({ user: { ...this.state.user, website: e.target.value } }) }} ></input>
                        </div>
                        <p />

                        * Required
                        <p />
                        {this.state.user.custom_fields.map(custom =>
                            <span>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <input type="text" className="custom_input " placeholder="Custom Key" name="website" value={custom.key} onChange={e => { this.update_custom_key(custom.index, e.target.value) }} ></input>
                                        </span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Custom Value" aria-describedby="basic-addon1" name="website" value={custom.value} onChange={e => { this.update_custom_value(custom.index, e.target.value) }} ></input>
                                    <button type="button" className="btn btn-success custom_input" onClick={e => { this.remove_custom(custom.index) }}>X</button>
                                </div>
                                <p />
                            </span>

                        )}
                        <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.add_custom.bind(this)}>Add Custom Field</button>
                        <p />

                    </span> : null}

                </ModalBody>
                <ModalFooter>
                    <div className="btn-toolbar" role="group" aria-label="...">
                        <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Close</button>

                        <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Continue</button>
                    </div>
                </ModalFooter>

            </Modal>
            {this.state.show_info ? <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => { this.setState({ show_info: false }) }} /> : null}
            {this.state.show_create_key ? <CreateKeyPopup close_callback={this.close.bind(this)} return_key_callback={this.got_key.bind(this)} /> : null}

        </span>
        )
    }
}

interface result {
    hex: string
    cost: number
}

interface custom {
    index: number,
    key: string,
    value: string
}

interface Istore {
    keys: Array<string>,
    invalidKeys: Array<string>
}