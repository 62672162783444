import * as React from 'react';
import { NFT, blank_NFT } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import * as Settings from '../../Global/settings'
import { GetInputPopup } from '../../Global/GetInputPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { User, blank_user } from "../_Interfaces/iUser"
import { SelectUser } from "../../Global/SelectUser"
import * as settings from "../../Global/settings"
import { CreateNFTPopupConfirmation } from "./CreateNFTPopupConfirmation"
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'
import { UploadFilePopup } from '../../Global/UploadFilePopup'
interface Props {
    close_callback: any;

    address?: string;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean

    NFT: NFT
    show_select_user: boolean

    show_upload: boolean

    // stage 3 - try send SmartTx

    use_NFT_Collection: boolean

    issue_to_self: boolean
    issueto_display_name: string;
}

export class CreateNFTPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
        address: ""
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            show_upload: false,
            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            show_result: false,
            //
            NFT: blank_NFT,
            show_select_user: false,

            //
            use_NFT_Collection: false,
            issue_to_self: false,
            issueto_display_name: "",
        };
    }

    close() {
        this.props.close_callback(true)
    }

    set_user(u: User) {
        if (u == undefined) { return }
        this.setState({ issueto_display_name: "User:" + u.username + " Address:" + u.address })
        this.setState({ NFT: { ...this.state.NFT, owner: u } })
        this.setState({ show_select_user: false })
    }

    validate() {
        if (this.state.issue_to_self) {
            this.setState({ NFT: { ...this.state.NFT, owner: settings.current_identity } })
        }
        else if (this.state.NFT.owner.address == "") {
            this.setState({ show_info: true, info_title: "error", info_body: "NFT must be issued to an identity!" })
            return;
        }

        this.setState({ show_confirmation: true })
    }

    close_select_user() {
        this.setState({ show_select_user: false })
    }

    fail() {
        this.setState({ show_confirmation: false, show_result: false })
    }

    success() {
        this.props.close_callback()
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    results_close() {
        this.setState({ show_result: false })
    }
    toggle_upload_immage() {
        this.setState({ show_upload: !this.state.show_upload })
    }

    set_immage(hash: string) {
        this.setState({ NFT: { ...this.state.NFT, immage: hash }, show_upload: false })
    }

    popup_close() {
        this.props.close_callback();
    }

    render() {
        if (this.state.show_confirmation) {
            return <CreateNFTPopupConfirmation NFT={this.state.NFT} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }
        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Create New Single NFT

            </ModalHeader>
            <ModalBody>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">NFT Name*:</span>
                    <input type="text" className="form-control" placeholder="Name" aria-describedby="basic-addon1" required={true} name="username" value={this.state.NFT.name} onChange={e => { this.setState({ NFT: { ...this.state.NFT, name: e.target.value } }) }} ></input>

                </div>
                <p />
                <div className="input-group" >
                    <span className="input-group-text" id="basic-addon1" onClick={this.toggle_upload_immage.bind(this)} >NFT immage Hash: </span>
                    <input type="text" className="form-control" disabled={true} aria-describedby="basic-addon1" name="pic_hash" value={this.state.NFT.immage != null ? this.state.NFT.immage : ""}  ></input>

                </div>
                <p />
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">NFT Description:</span>
                    <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" required={true} name="username" value={this.state.NFT.description} onChange={e => { this.setState({ NFT: { ...this.state.NFT, description: e.target.value } }) }} ></input>

                </div>
                <p />
                {!this.state.issue_to_self ?
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">Issue To:</span>
                        <input type="text" className="form-control" placeholder="Click to edit" aria-describedby="basic-addon1" required={true} name="username" value={this.state.issueto_display_name} onClick={e => { this.setState({ show_select_user: true }) }} ></input>

                    </div>
                    :
                    null}

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.issue_to_self} onChange={e => { this.setState({ issue_to_self: !this.state.issue_to_self }) }} />
                        Issue NFT to yourself</label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.NFT.can_creator_destroy} onChange={e => { this.setState({ NFT: { ...this.state.NFT, can_creator_destroy: !this.state.NFT.can_creator_destroy } }) }} />
                        NFT can be destroyed by the creator</label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.NFT.can_owner_destroy} onChange={e => { this.setState({ NFT: { ...this.state.NFT, can_owner_destroy: !this.state.NFT.can_owner_destroy } }) }} />
                        NFT can be destroyed by the owner</label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.NFT.can_owner_transfer} onChange={e => { this.setState({ NFT: { ...this.state.NFT, can_owner_transfer: !this.state.NFT.can_owner_transfer } }) }} />
                        NFT can be transfered</label>
                </div>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Close</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Continue</button>
                </div>
            </ModalFooter>
            {this.state.show_select_user ?
                <SelectUser cancel_callback={this.close_select_user.bind(this)} return_callback={this.set_user.bind(this)} />
                : null
            }
            {this.state.show_upload ? <UploadFilePopup success_callback={this.set_immage.bind(this)} cancel_callback={() => { this.setState({ show_upload: false }) }} /> : null}

        </Modal>
        )
    }
}