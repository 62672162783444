import * as React from 'react';
import { ExploreMenu } from './ExploreMenu';

import { Container } from 'reactstrap';
export interface LayoutProps {
    children?: React.ReactNode;
}

interface LayoutState {
    loadcomplete: boolean;
    sync_complete: boolean;
    intervaltick: number;
    api_set: boolean;
}

export class ExploreLayout extends React.Component<LayoutProps, LayoutState> {
    constructor(props: LayoutProps) {
        super(props);
        this.state = { loadcomplete: false, sync_complete: false, intervaltick: 30000, api_set: true }
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
        });
    }

    componentDidMount() {
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervaltick: 30000 });
    }
    componentWillUnmount() {
        clearInterval(this.state.intervaltick);
    }

    render() {
        return <div>
            <ExploreMenu />

            {this.props.children}

        </div>
    }
}