import * as React from 'react';
import t from '../../Language/Language'
import { NFT, NFT_command, blank_NFT_command, NFT_command_type, NFTCollection } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import * as Settings from '../../Global/settings'
import { GetInputPopup } from '../../Global/GetInputPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { User, blank_user } from "../_Interfaces/iUser"
import * as settings from "../../Global/settings"
import { SmartTxSendResultComponent } from "../../Global/SmartTxSendResultComponent"
import { DestroyNFTCollectionPopupConfirmation } from './DestroyNFTCollectionPopupConfirmation';
import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
interface Props {
    collection: NFTCollection
    close_callback: any;
    success_callback: any;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean

    command: NFT_command

    confirm_name: string
    confirm_id: string
}

export class DestroyNFTCollectionPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            show_result: false,
            //
            command: {
                destination: undefined,
                amount: undefined,
                object_id: this.props.collection.txid,
                command_type: NFT_command_type.destroy,
            },
            confirm_name: "",
            confirm_id: "",

            //
        };
    }

    close() {
        this.props.close_callback(true)
    }

    validate() {
        if (this.props.collection.txid != this.state.confirm_id) {
            this.setState({ show_info: true, info_title: "Error", info_body: "Confirmation ID does not match" })
            return;
        }
        if (this.props.collection.collection_name != this.state.confirm_name) {
            this.setState({ show_info: true, info_title: "Error", info_body: "Confirmation name does not match" })
            return;
        }

        // Destroy command validation

        // if base permission is false, then below operators are false.

        var owner_destroy_ok: boolean = this.props.collection.collection_can_owner_destroy;

        // check current identity agains owner
        if (this.props.collection.owner == undefined) {
            //owner can't destory if owner is undefined
            owner_destroy_ok = false;
        }
        else {
            //assuming owner is defined
            if (this.props.collection.owner.address != settings.current_identity.address) {
                owner_destroy_ok = false;
            }
        }

        if (!owner_destroy_ok) {
            this.setState({ show_info: true, info_title: "Error", info_body: "You do not have the permissions to destroy this NFT!" })
            return;
        }

        this.setState({ show_confirmation: true })
    }

    fail() {
        this.setState({ show_confirmation: false, show_result: false })
    }

    success() {
        this.props.success_callback()
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    results_close() {
        this.setState({ show_result: false })
    }

    popup_close() {
        this.props.close_callback();
    }

    render() {
        if (this.state.show_confirmation) {
            return <DestroyNFTCollectionPopupConfirmation collection={this.props.collection} command={this.state.command} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }
        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Destroy NFT
                <dl className="dl-horizontal">
                    <dt>Name :</dt> <dd>{this.props.collection.collection_name}</dd>
                    <dt>ID :</dt> <dd>{this.props.collection.txid}</dd>
                    <dt>Owner :</dt> <dd>{this.props.collection.owner!.username}</dd>
                    <dt>ID :</dt> <dd>{this.props.collection.creator.username}</dd>
                </dl>

            </ModalHeader>
            <ModalBody>

                <span>Destroying an NFT is an irriviersable action.</span>
                <span>Please enter the NFT name and ID below to confirm</span>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">NFT Name*:</span>
                    <input type="text" className="form-control" placeholder="Name" aria-describedby="basic-addon1" required={true} name="username" value={this.state.confirm_name} onChange={e => { this.setState({ confirm_name: e.target.value }) }} ></input>

                </div>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">NFT ID:</span>
                    <input type="text" className="form-control" placeholder="ID" aria-describedby="basic-addon1" required={true} name="username" value={this.state.confirm_id} onChange={e => { this.setState({ confirm_id: e.target.value }) }} ></input>

                </div>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Cancel</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Destroy</button>
                </div>
            </ModalFooter>

        </Modal>
        )
    }
}

interface result {
    hex: string
    cost: number
}