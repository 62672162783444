import * as React from 'react';
import ReactTable from 'react-table';

import { Offer, blank_offer } from '../_Interfaces/NFT'
import { AcceptOfferPopup } from './AcceptOfferPopup'
interface State {
    show_accept_offer: boolean;
    accepted_offer: Offer;
}
export interface Props {
    Offers: Offer[];
}

export class OfferTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show_accept_offer: false,
            accepted_offer: blank_offer,
        };
    }

    show_accept_offer(offer: Offer) {
        this.setState({ show_accept_offer: true, accepted_offer: offer })
    }

    hide_accept_offer(offer: Offer) {
        this.setState({ show_accept_offer: false, accepted_offer: blank_offer })
    }

    public render() {
        const data = this.props.Offers;
        const columns = [
            {
                Header: "NFT",
                accessor: 'NFT.name',
            },
            {
                Header: "Buyer",
                accessor: 'Buyer.username',
            },
            {
                Header: "Amount",
                accessor: 'amount'
            },
            {
                Cell: (row: any) => (
                    <button type="button" className="btn  btn-success mr-3 " onClick={() => { this.show_accept_offer(row.original) }}>Accept Offer</button>

                )
            }

        ]

        return <div className=" table table-responsive">
            <div className="body-header">
                <h1>Pending Offers</h1>
            </div>
            <div className="body-content">
                < ReactTable
                    data={data}
                    columns={columns}
                    className="-highlight"
                    defaultPageSize={5} minRows={5}
                    defaultSorted={[{ id: "unix_time", desc: true }]}
                />
                {this.state.show_accept_offer ?
                    <AcceptOfferPopup offer={this.state.accepted_offer} close_callback={this.hide_accept_offer.bind(this)} />
                    : null
                }

            </div>
        </div>
    }
}