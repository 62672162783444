import * as React from 'react';
import { SignedFilesTable } from './SignedFilesTable';
import * as Settings from '../../Global/settings'
import { SignFilePopup } from './SignFilePopup'
import { VerifyFilePopup } from './VerifyFIlePopup'
import { SignedFilesMenu } from '../SignedFiles/SignedFilesMenu';
interface SignedFilesState {
    show_verify: boolean,
    show_sign: boolean,

    mobile: boolean;
}

export interface LoadingProps {
}
export class SignedFiles extends React.Component<LoadingProps, SignedFilesState> {
    constructor(props: LoadingProps) {
        super(props);
        this.state = {
            show_verify: false,
            show_sign: false,

            mobile: false,
        };
        //used to bypas loading screen for gui dev
        //this.props.callback(true)
    }

    resize() {
        this.setState({ mobile: window.innerWidth <= 760 })
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    show_verify() {
        this.setState({ show_verify: true })
    }

    show_sign() {
        this.setState({ show_sign: true })
    }

    close_verify() {
        this.setState({ show_verify: false })
    }

    close_sign() {
        this.setState({ show_sign: false })
    }

    public render() {
        return (<span>
            <SignedFilesMenu />

            <SignedFilesTable mobile={false} title={"Your Signed Files"} address={Settings.current_identity.address} defaultPageSize={-1} showPagination={true} />

        </span>
        );
    }
}

interface setup_data {
    message: string;
    progress: number;
}