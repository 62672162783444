import * as React from 'react';
import t from '../../Language/Language'
import { NFT, blank_NFTCollection, NFTCollection } from '../_Interfaces/NFT'
import { InfoPopup } from '../../Global/InfoPopup'
import * as Settings from '../../Global/settings'
import { GetInputPopup } from '../../Global/GetInputPopup'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { User, blank_user } from "../_Interfaces/iUser"
import { SelectUser } from "../../Global/SelectUser"
import * as settings from "../../Global/settings"
import { CreateNFTCollectionPopupConfirmation } from "./CreateNFTCollectionPopupConfirmation"
import { SmartTxSendResultComponent } from '../../Global/SmartTxSendResultComponent'

import { encoding_result, SendResult, blank_encoding_result, blank_send_result } from '../_Interfaces/iResult'
interface Props {
    close_callback: any;

    address?: string;
}
interface State {
    //info popup
    info_title: string
    info_body: string
    show_info: boolean

    // confirmation
    show_confirmation: boolean
    encoding_result: encoding_result
    show_result: boolean

    // stage 1 - gather info
    collection: NFTCollection
    show_select_user: boolean

    // stage 2 - confirm info

    use_custom_collectionwide_name: boolean

    issue_to_self: boolean
    issueto_display_name: string;
}

export class CreateNFTCollectionPopup extends React.Component<Props, State>{
    public static defaultProps: Partial<Props> = {
        address: ""
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            //info popup
            info_title: "",
            info_body: "",
            show_info: false,

            // confirmation
            show_confirmation: false,
            encoding_result: blank_encoding_result,
            show_result: false,
            //
            collection: blank_NFTCollection,
            show_select_user: false,

            //
            //
            use_custom_collectionwide_name: false,
            issue_to_self: false,
            issueto_display_name: "",
        };
    }

    close() {
        this.props.close_callback(true)
    }

    validate() {
        this.setState({ show_confirmation: true })
    }

    close_select_user() {
        this.setState({ show_select_user: false })
    }

    fail() {
        this.setState({ show_confirmation: false, show_result: false })
    }

    success() {
        this.props.close_callback()
    }

    send(res: encoding_result) {
        this.setState({ show_confirmation: false, encoding_result: res }, () => {
            this.setState({ show_result: true })
        })
    }
    cancel_confirmation() {
        this.setState({ show_confirmation: false })
    }
    results_close() {
        this.setState({ show_result: false })
    }

    popup_close() {
        this.props.close_callback();
    }
    render() {
        if (this.state.show_confirmation) {
            return <CreateNFTCollectionPopupConfirmation collection={this.state.collection} cancel_callback={this.cancel_confirmation.bind(this)} continue_callback={this.send.bind(this)} />
        }
        if (this.state.show_result) {
            return <SmartTxSendResultComponent show={true} encoding_result={this.state.encoding_result} success_callback={() => { this.popup_close() }} fail_callback={() => { this.results_close() }} />
        }
        if (this.state.show_info) {
            return <InfoPopup title={this.state.info_title} info={this.state.info_body} close_callback={() => this.setState({ show_info: false })} />
        }

        return (<Modal backdrop={"static"} isOpen={true} toggle={() => { this.props.close_callback() }}>
            <ModalHeader toggle={() => { this.props.close_callback() }} >
                Create New NFT Collection

            </ModalHeader>
            <ModalBody>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">Class Name*:</span>
                    <input type="text" className="form-control" placeholder="Name" aria-describedby="basic-addon1" required={true} name="collection_name" value={this.state.collection.collection_name} onChange={e => { this.setState({ collection: { ...this.state.collection, collection_name: e.target.value } }) }} ></input>

                </div>
                <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">Class Description:</span>
                    <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" required={true} name="collection_description" value={this.state.collection.collection_description} onChange={e => { this.setState({ collection: { ...this.state.collection, collection_description: e.target.value } }) }} ></input>

                </div>
                {!this.state.use_custom_collectionwide_name && this.state.collection.nft_inherit_name ? <span>
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">NFT Name*:</span>
                        <input type="text" className="form-control" placeholder="Name" aria-describedby="basic-addon1" required={true} name="collection_name" value={this.state.collection.nft_name} onChange={e => { this.setState({ collection: { ...this.state.collection, nft_name: e.target.value } }) }} ></input>

                    </div>
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">NFT Description:</span>
                        <input type="text" className="form-control" placeholder="Description" aria-describedby="basic-addon1" required={true} name="collection_description" value={this.state.collection.nft_description} onChange={e => { this.setState({ collection: { ...this.state.collection, nft_description: e.target.value } }) }} ></input>

                    </div> </span>
                    : null}

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.collection.collection_transferable} onChange={e => { this.setState({ collection: { ...this.state.collection, collection_transferable: !this.state.collection.collection_transferable } }) }} />
                        Class owner can transfer class controll</label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.collection.collection_can_owner_destroy} onChange={e => { this.setState({ collection: { ...this.state.collection, collection_can_owner_destroy: !this.state.collection.collection_can_owner_destroy } }) }} />
                        Class owner can destroy class and all associated NFTs</label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.collection.nft_inherit_name} onChange={e => { this.setState({ collection: { ...this.state.collection, nft_inherit_name: !this.state.collection.nft_inherit_name }, use_custom_collectionwide_name: !this.state.collection.nft_inherit_name }) }} />
                        All NFTs have identical names</label>
                </div>
                {this.state.collection.nft_inherit_name ?
                    <div className="checkbox check-margin10">
                        <label>
                            <input type="checkbox" checked={this.state.use_custom_collectionwide_name} onChange={e => { this.setState({ use_custom_collectionwide_name: !this.state.use_custom_collectionwide_name }) }} />
                            NFTs inherit name from class</label>
                    </div>
                    : null}

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.collection.nft_can_creator_destroy} onChange={e => { this.setState({ collection: { ...this.state.collection, nft_can_creator_destroy: !this.state.collection.nft_can_creator_destroy } }) }} />
                        NFT Creators can destroy own NFTs of this class</label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.collection.nft_can_owner_destroy} onChange={e => { this.setState({ collection: { ...this.state.collection, nft_can_owner_destroy: !this.state.collection.nft_can_owner_destroy } }) }} />
                        NFT Owner can destroy own NFTs of this class</label>
                </div>

                <div className="checkbox">
                    <label>
                        <input type="checkbox" checked={this.state.collection.nft_can_owner_transfer} onChange={e => { this.setState({ collection: { ...this.state.collection, nft_can_owner_transfer: !this.state.collection.nft_can_owner_transfer } }) }} />
                        NFT Owner can transfer own NFTs of this class</label>
                </div>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.close_callback() }}>Close</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={this.validate.bind(this)}>Continue</button>
                </div>
            </ModalFooter>

        </Modal>
        )
    }
}

interface result {
    hex: string
    cost: number
}