import * as React from 'react';
import { TrueFalseIcon } from '../../Global/TrueFalseIcon'
import { User } from '../_Interfaces/iUser'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { InfoPopup } from '../../Global/InfoPopup'
import { encoding_result, blank_encoding_result } from '../_Interfaces/iResult'
import * as api from '../../Global/API'
import * as statics from '../../Global/statics'

import * as settings from '../../Global/settings'
interface Props {
    user: User

    cancel_callback: any;
    continue_callback: any;
}
interface CreateUserPopupConfirmationState {
    encoding_result: encoding_result;
}
export class EditUserPopupConfirmation extends React.Component<Props, CreateUserPopupConfirmationState>{
    constructor(props: Props) {
        super(props);
        this.state = {
            encoding_result: blank_encoding_result
        };
        const body = JSON.stringify({
            user: this.props.user,
        })

        api.EncodeEditUser(body, (data: any) => {
            this.setState({ encoding_result: data });
        })
    }
    //required for security, set pass to null

    Should_show(st: string): boolean {
        if (typeof (st) == 'string' && st.length > 0)
            return true;

        return false
    }

    render() {
        return (<Modal isOpen={true} onHide={() => { }}>
            <ModalHeader closeButton>

                <dl className="dl-horizontal">
                    <dt>{settings.language.Username} :</dt> <dd>{this.props.user.username}</dd>
                    <dt>{settings.language.Address} :</dt><dd>{this.props.user.address}</dd>
                </dl>
                Please ensure the following new values are correct!
            </ModalHeader>
            <ModalBody>

                <dl className="dl-horizontal">
                    {this.Should_show(this.props.user.profile_immage) ? <span><dt>Profile Immage Hash :</dt> <dd>{this.props.user.profile_immage}</dd></span> : null}

                    {this.Should_show(this.props.user.description) ? <span><dt>{settings.language.Description} :</dt> <dd>{this.props.user.description}</dd></span> : null}
                    {this.Should_show(this.props.user.company) ? <span><dt>{settings.language.Company} :</dt> <dd>{this.props.user.company}</dd></span> : null}
                    {this.Should_show(this.props.user.streetaddress) ? <span><dt>{settings.language.StreetAddress} :</dt> <dd>{this.props.user.streetaddress}</dd></span> : null}
                    {this.Should_show(this.props.user.phone) ? <span><dt>{settings.language.Phone} :</dt> <dd>{this.props.user.phone}</dd></span> : null}
                    {this.Should_show(this.props.user.email) ? <span><dt>{settings.language.Email} :</dt> <dd>{this.props.user.email}</dd></span> : null}
                    {this.Should_show(this.props.user.website) ? <span><dt>{settings.language.Website} :</dt> <dd>{this.props.user.website}</dd></span> : null}
                </dl>
                {this.props.user.custom_fields.map(custom =>
                    <span><dt>{custom.key} :</dt> <dd>{custom.value}</dd></span>

                )}

                <h4>The cost for this operation is {this.state.encoding_result.cost} Radium. Are you sure?</h4>

            </ModalBody>
            <ModalFooter>
                <div className="btn-toolbar" role="group" aria-label="...">
                    <button type="button" className="btn btn-success mr-3 btn-danger" onClick={() => { this.props.cancel_callback() }}>Close</button>

                    <button type="button" className="btn btn-success mr-3 btn-success" onClick={() => { this.props.continue_callback(this.state.encoding_result) }}>Create</button>
                </div>

            </ModalFooter>
        </Modal>

        );
    }
}